export enum ReferralProgramStatus {
    PENDING = "pending",
    ACTIVE = "active",
    SUSPENDED = "suspended",
}

class ReferralProgram {
    readonly uuid: string;
    readonly title: string;
    readonly description: string;
    readonly dt_end: string;
    readonly my_referrer_uuid: string;
    readonly status: ReferralProgramStatus;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.title = data.title;
        this.description = data.description;
        this.dt_end = data.dt_end;
        this.my_referrer_uuid = data.my_referrer_uuid;
        this.status = data.status;
    }
}

export default ReferralProgram;
