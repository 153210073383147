import React, { useContext } from "react";
import "./i18n/config";
import { Navigate, Route, Routes } from "react-router-dom";
import SearchPage from "./pages/SearchPage/SearchPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import PageContainer from "./components/PageContainer/PageContainer";
import NotificationsPage from "./pages/NotificationsPage/NotificationsPage";
import Modal from "./components/Modal/Modal";
import StartPetitionPage from "./pages/StartPetitionPage/StartPetitionPage";
import ProfileSettingsPage from "./pages/SettingsPage/pages/ProfileSettingsPage/ProfileSettingsPage";
import AccountSettingsPage from "./pages/SettingsPage/pages/AccountSettingsPage/AccountSettingsPage";
import PaymentMethodsSettingsPage from "./pages/SettingsPage/pages/PaymentMethodsSettingsPage/PaymentMethodsSettingsPage";
import PayoutSettingsPage from "./pages/SettingsPage/pages/PayoutSettingsPage/PayoutSettingsPage";
import NotificationsSettingsPage from "./pages/SettingsPage/pages/NotificationsSettingsPage/NotificationsSettingsPage";
import PetitionPage from "./pages/PetitionPage/PetitionPage";
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import AuthPage from "./pages/AuthPage/AuthPage";
import AuthContext from "./contexts/auth-context";
import VerifyEmailPage from "./pages/VerifyEmailPage/VerifyEmailPage";
import { ProfilePagePetitionsTab } from "./pages/ProfilePage/ProfilePagePetitions/ProfilePagePetitions";
import { ProfilePageFollowsTab } from "./pages/ProfilePage/ProfilePageFollows/ProfilePageFollows";
import Header from "./components/Header/Header";
import BillingSettingsPage from "./pages/SettingsPage/pages/BillingSettingsPage/BillingSettingsPage";
import TermsPage from "./pages/TermsPage/TermsPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import CookiesPage from "./pages/CookiesPage/CookiesPage";
import InviteeWelcomePage from "./pages/InviteeWelcomePage/InviteeWelcomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import FaqPage from "./pages/FaqPage/FaqPage";
import HelpPage, { HelpPageTab } from "./pages/HelpPage/HelpPage";
import HowToSetUpCreatorAccountPage from "./pages/HelpPage/pages/HowToSetUpCreatorAccountPage/HowToSetUpCreatorAccountPage";
import Blog from "./pages/Blog/Blog";
import PetitionGroupPage from "./pages/PetitionGroupPage/PetitionGroupPage";
import ReferralProgramDetailsPage from "./pages/ReferralProgramPage/pages/ReferralProgramDetailsPage/ReferralProgramDetailsPage";
import ReferralsPage from "./pages/ReferralProgramPage/pages/ReferralsPage/ReferralsPage";
import ReferralProgramPage from "./pages/ReferralProgramPage/ReferralProgramPage";
import ReferrerPage from "./pages/ReferralProgramPage/pages/ReferrerPage/ReferrerPage";

function App() {
    const rb = new URLSearchParams(window.location.search).get("rb"); // referred by
    if (rb) {
        localStorage.removeItem("invitedBy");
        localStorage.setItem(
            "referredByAndTime",
            JSON.stringify({
                rb: rb,
                rt: Math.floor(new Date().getTime() / 1000),
            })
        );
    }
    const authCtx = useContext(AuthContext);

    let content = (
        <PageContainer>
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/login" element={<AuthPage />} />
                <Route path="/signup" element={<AuthPage />} />
                <Route path="/join-as-creator" element={<AuthPage />} />
                <Route path="/forgot-password" element={<AuthPage />} />
                <Route path="/reset-password" element={<AuthPage />} />
                <Route path="/verify-email" element={<AuthPage />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/cookies" element={<CookiesPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/petitions/:uuid" element={<PetitionPage />} />
                <Route
                    path="/team/:username"
                    element={<InviteeWelcomePage />}
                />
                <Route path="/help" element={<HelpPage />}>
                    <Route
                        path={HelpPageTab.HOW_TO_SETUP_CREATOR_ACCOUNT}
                        element={<HowToSetUpCreatorAccountPage />}
                    />
                </Route>
                <Route
                    path="/referral-program"
                    element={<ReferralProgramPage />}
                />
                <Route path="/blog" element={<Blog />} />
                <Route path="/users/:username" element={<ProfilePage />}>
                    <Route
                        path={ProfilePagePetitionsTab.SUPPORTED}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePagePetitionsTab.RECEIVED}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePagePetitionsTab.STARTED}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePagePetitionsTab.GROUPS}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePageFollowsTab.FOLLOWERS}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePageFollowsTab.FOLLOWING}
                        element={<ProfilePage />}
                    />
                </Route>
                <Route
                    path="/search"
                    element={<Navigate to="/" replace={true} />}
                />
                <Route
                    path="/start"
                    element={<Navigate to="/" replace={true} />}
                />
                <Route
                    path="/notifications"
                    element={<Navigate to="/" replace={true} />}
                />
                <Route path="/:username" element={<ProfilePage />}>
                    <Route
                        path={ProfilePagePetitionsTab.SUPPORTED}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePagePetitionsTab.RECEIVED}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePagePetitionsTab.STARTED}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePagePetitionsTab.GROUPS}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePageFollowsTab.FOLLOWERS}
                        element={<ProfilePage />}
                    />
                    <Route
                        path={ProfilePageFollowsTab.FOLLOWING}
                        element={<ProfilePage />}
                    />
                </Route>
                <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
        </PageContainer>
    );

    if (authCtx.isLoggedIn) {
        content = (
            <PageContainer>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/start" element={<StartPetitionPage />} />
                    <Route path="/settings" element={<SettingsPage />}>
                        <Route
                            path="profile"
                            element={<ProfileSettingsPage />}
                        />
                        <Route
                            path="account"
                            element={<AccountSettingsPage />}
                        />
                        <Route
                            path="payment-methods"
                            element={<PaymentMethodsSettingsPage />}
                        />
                        <Route
                            path="billing"
                            element={<BillingSettingsPage />}
                        />
                        <Route path="payout" element={<PayoutSettingsPage />} />
                        <Route
                            path="notifications"
                            element={<NotificationsSettingsPage />}
                        />
                    </Route>
                    <Route
                        path="/notifications"
                        element={<NotificationsPage />}
                    />
                    <Route path="/users/:username" element={<ProfilePage />}>
                        <Route
                            path={ProfilePagePetitionsTab.SUPPORTED}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePagePetitionsTab.RECEIVED}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePagePetitionsTab.STARTED}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePagePetitionsTab.GROUPS}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePageFollowsTab.FOLLOWERS}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePageFollowsTab.FOLLOWING}
                            element={<ProfilePage />}
                        />
                    </Route>
                    <Route path="/petitions/:uuid" element={<PetitionPage />} />
                    <Route
                        path="/petitions/groups/:uuid"
                        element={<PetitionGroupPage />}
                    />
                    <Route path="/verify-email" element={<VerifyEmailPage />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/cookies" element={<CookiesPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route path="/login" element={<Navigate to="/" />} />
                    <Route path="/signup" element={<Navigate to="/" />} />
                    <Route
                        path="/forgot-password"
                        element={<Navigate to="/" />}
                    />
                    <Route
                        path="/reset-password"
                        element={<Navigate to="/" />}
                    />
                    <Route
                        path="/team/:username"
                        element={<InviteeWelcomePage />}
                    />
                    <Route path="/help" element={<HelpPage />}>
                        <Route
                            path={HelpPageTab.HOW_TO_SETUP_CREATOR_ACCOUNT}
                            element={<HowToSetUpCreatorAccountPage />}
                        />
                    </Route>
                    <Route path="/blog" element={<Blog />} />
                    <Route
                        path="/referral-program"
                        element={<ReferralProgramPage />}
                    >
                        <Route
                            path=""
                            element={<ReferralProgramDetailsPage />}
                        />
                        <Route path="referrer" element={<ReferrerPage />} />
                        <Route path="referrals" element={<ReferralsPage />} />
                    </Route>
                    <Route path="/:username" element={<ProfilePage />}>
                        <Route
                            path={ProfilePagePetitionsTab.SUPPORTED}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePagePetitionsTab.RECEIVED}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePagePetitionsTab.STARTED}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePagePetitionsTab.GROUPS}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePageFollowsTab.FOLLOWERS}
                            element={<ProfilePage />}
                        />
                        <Route
                            path={ProfilePageFollowsTab.FOLLOWING}
                            element={<ProfilePage />}
                        />
                    </Route>
                    <Route path="*" element={<p>Path not resolved</p>} />
                </Routes>
            </PageContainer>
        );
    }

    return (
        <>
            <Modal />
            <Header />
            {content}
        </>
    );
}

export default App;
