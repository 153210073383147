import User from "./User";
import PetitionEndResult from "./PetitionEndResult";

export enum PetitionGroupStatus {
    PENDING = "pending",
    ONGOING = "ongoing",
    CANCELED = "canceled",
    EXPIRED = "expired",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
    FULFILLED = "fulfilled",
    NOT_FULFILLED = "not_fulfilled",
}

class PetitionGroup {
    readonly uuid: string;
    readonly title: string;
    readonly description: string;
    readonly status: PetitionGroupStatus;
    readonly owner: User;
    readonly petitions_count: number;
    readonly total_supporters_count: number;
    readonly total_pledged_amount_by_currency: {
        currency: string;
        total_amount: number;
    }[];
    readonly dt_next_status_update: string;
    readonly average_rate: number;
    readonly end_result?: PetitionEndResult;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.title = data.title;
        this.description = data.description;
        this.status = data.status;
        this.owner = new User(data.owner);
        this.petitions_count = data.petitions_count;
        this.total_supporters_count = data.total_supporters_count;
        this.total_pledged_amount_by_currency =
            data.total_pledged_amount_by_currency;
        this.dt_next_status_update = data.dt_next_status_update;
        this.average_rate = data.average_rate;
        this.end_result = data.end_result
            ? new PetitionEndResult(data.end_result)
            : undefined;
    }
}

export default PetitionGroup;
