import User from "../../../models/User";
import ProfilePagePetitionsNavbar from "./ProfilePagePetitionsNavbar/ProfilePagePetitionsNavbar";
import PetitionsList from "../../../components/PetitionsList/PetitionsList";
import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";
import Petition from "../../../models/Petition";
import {
    listPetitions as api_listPetitions,
    SuccessResponse as ListPetitionsSuccessResponse,
} from "../../../api/petitions/listPetitions";
import {
    listPetitionGroups as api_listPetitionGroups,
    SuccessResponse as ListPetitionGroupsSuccessResponse,
} from "../../../api/petitionGroups/listPetitionGroups";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { parseCursor } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import PetitionGroup from "../../../models/PetitionGroup";
import petitionGroup from "../../../models/PetitionGroup";
import PetitionGroupsList from "../../../components/PetitionGroupsList/PetitionGroupsList";

interface Props extends HTMLAttributes<any> {
    user: User;
    activeTab: ProfilePagePetitionsTab;
}

export enum ProfilePagePetitionsTab {
    SUPPORTED = "supported",
    RECEIVED = "received",
    STARTED = "started",
    GROUPS = "groups",
}

const ProfilePagePetitions = ({ id, user, activeTab }: Props) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [petitionGroups, setPetitionGroups] = useState<PetitionGroup[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitions = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (activeTab === ProfilePagePetitionsTab.GROUPS) return;

            if (!hasMore) return;
            setIsLoading(true);
            try {
                let response: ListPetitionsSuccessResponse;
                switch (activeTab) {
                    case ProfilePagePetitionsTab.SUPPORTED:
                        response = await api_listPetitions(
                            undefined,
                            undefined,
                            user.uuid,
                            undefined,
                            undefined,
                            cursor
                        );
                        break;
                    case ProfilePagePetitionsTab.RECEIVED:
                        response = await api_listPetitions(
                            undefined,
                            [user.uuid],
                            undefined,
                            undefined,
                            undefined,
                            cursor
                        );
                        break;
                    case ProfilePagePetitionsTab.STARTED:
                        response = await api_listPetitions(
                            user.uuid,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            cursor
                        );
                        break;
                }
                if (cursor === undefined) setPetitions(response.results);
                else
                    setPetitions((prev: Petition[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [user.uuid, activeTab]
    );

    const getPetitionGroups = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (activeTab !== ProfilePagePetitionsTab.GROUPS) return;

            if (!hasMore) return;
            setIsLoading(true);
            try {
                let response: ListPetitionGroupsSuccessResponse;
                response = await api_listPetitionGroups(undefined, cursor);
                if (cursor === undefined) setPetitionGroups(response.results);
                else
                    setPetitionGroups((prev: PetitionGroup[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [user.uuid, activeTab]
    );

    useEffect(() => {
        setPetitions([]);
        setPetitionGroups([]);
        setHasMore(true);
        setCursor(undefined);
        if (activeTab === ProfilePagePetitionsTab.GROUPS)
            getPetitionGroups(undefined, true);
        else getPetitions(undefined, true);
    }, [getPetitions, getPetitionGroups]);

    let noItemsFound;
    if (activeTab === ProfilePagePetitionsTab.SUPPORTED)
        noItemsFound = !petitions.length
            ? t("profilePage.noSupportedPetitions")
            : undefined;
    else if (activeTab === ProfilePagePetitionsTab.RECEIVED)
        noItemsFound = !petitions.length
            ? t("profilePage.noReceivedPetitions")
            : undefined;
    else if (activeTab === ProfilePagePetitionsTab.STARTED)
        noItemsFound = !petitions.length
            ? t("profilePage.noStartedPetitions")
            : undefined;
    else
        noItemsFound = !petitionGroups.length
            ? t("profilePage.noPetitionGroups")
            : undefined;

    let items = undefined;
    if (activeTab === ProfilePagePetitionsTab.GROUPS) {
        if (!!petitionGroup.length)
            items = (
                <PetitionGroupsList
                    id="a99013c1-508d-4ab0-a9bc-121bcf35e600"
                    petitionGroups={petitionGroups}
                    getPetitionGroups={() => getPetitionGroups(cursor, hasMore)}
                    isLoading={isLoading}
                />
            );
    } else {
        if (!!petitions.length)
            items = (
                <PetitionsList
                    id="543c85b0-c66b-4fd9-a9c6-b3b7bba775bb"
                    petitions={petitions}
                    getPetitions={() => getPetitions(cursor, hasMore)}
                    isLoading={isLoading}
                />
            );
    }

    return (
        <div id={id}>
            <ProfilePagePetitionsNavbar
                id="437c4779-272c-450e-83f5-bffc0c7ecc80"
                activeTab={activeTab}
                user={user}
            />
            <div id="9cd857e4-15f7-43be-ac3d-a4bb014da517">
                {items}
                {isLoading && (
                    <Spinner
                        id="34064f3f-3b97-42c9-bb56-a02e7ca7b878"
                        className="mt-3"
                    />
                )}
                {!isLoading && noItemsFound && (
                    <div
                        id="7da8a18b-6c4d-4c28-8374-6a57a4089fa8"
                        className="d-flex justify-content-center form-text"
                    >
                        {noItemsFound}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePagePetitions;
