import User from "./User";
import PetitionAddressee from "./PetitionAddressee";
import PetitionSupporter from "./PetitionSupporter";
import PetitionEndResult from "./PetitionEndResult";

export enum PetitionStatus {
    ONGOING = "ongoing",
    CANCELED = "canceled",
    EXPIRED = "expired",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
    FULFILLED = "fulfilled",
    NOT_FULFILLED = "not_fulfilled",
}

class Petition {
    readonly uuid: string;
    readonly title: string;
    readonly description: string;
    readonly status: PetitionStatus;
    readonly author: User;
    readonly addressees: PetitionAddressee[];
    readonly supporters_count: number;
    readonly total_pledged_amount_by_currency: {
        currency: string;
        total_amount: number;
    }[];
    readonly dt_created: string;
    readonly am_i_author: boolean;
    readonly am_i_addressee: boolean;
    readonly am_i_supporter: boolean;
    readonly dt_last_status_update: string;
    readonly dt_next_status_update: string;
    readonly end_result?: PetitionEndResult;
    readonly accepts_pledges: boolean;
    readonly my_petition_supporter_uuid?: string;
    readonly petition_group_uuid: string;
    readonly can_i_rate: boolean;
    readonly average_rate: number;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.title = data.title;
        this.description = data.description;
        this.status = data.status;
        this.author = new User(data.author);
        this.addressees = data.addressees.map(
            (item: PetitionAddressee) => new PetitionAddressee(item)
        );
        this.supporters_count = data.supporters_count;
        this.total_pledged_amount_by_currency =
            data.total_pledged_amount_by_currency;
        this.dt_created = data.dt_created;
        this.am_i_author = data.am_i_author;
        this.am_i_addressee = data.am_i_addressee;
        this.am_i_supporter = data.am_i_supporter;
        this.dt_last_status_update = data.dt_last_status_update;
        this.dt_next_status_update = data.dt_next_status_update;
        this.end_result = data.end_result
            ? new PetitionEndResult(data.end_result)
            : undefined;
        this.accepts_pledges = data.accepts_pledges;
        this.my_petition_supporter_uuid = data.my_petition_supporter_uuid;
        this.petition_group_uuid = data.petition_group_uuid;
        this.can_i_rate = data.can_i_rate;
        this.average_rate = data.average_rate;
    }
}

export default Petition;
