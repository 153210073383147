import React, {
    ComponentProps,
    ForwardedRef,
    HTMLAttributes,
    Ref,
} from "react";
import UserBasicInfo from "../../UserBasicInfo/UserBasicInfo";
import PetitionSupporter from "../../../models/PetitionSupporter";
import classes from "../PetitionSupportersList.module.scss";
import { Link } from "react-router-dom";

interface Props extends HTMLAttributes<any> {
    supporter: PetitionSupporter;
    showPledgeAmount: boolean;
}

const PetitionSupportersListItem = React.forwardRef(
    (
        { supporter, showPledgeAmount = false, id }: Props,
        ref: ForwardedRef<HTMLAnchorElement>
    ) => {
        return (
            <Link
                // id={`petition-supporters-list-to-users-${supporter.user.username}`}
                id={id}
                to={`/${supporter.user.username}`}
                className={`${classes.supporter} d-block text-decoration-none text-dark rounded`}
                ref={ref}
            >
                <div
                    id={id}
                    className="d-flex align-items-center justify-content-between px-2 py-3 border rounded"
                >
                    <UserBasicInfo
                        id="2c1646ca-dd0d-4c63-828e-2b0ff69677f7"
                        user={supporter.user}
                    />
                    {showPledgeAmount && (
                        <span
                            id="f78e1761-630a-456b-8018-2e585ccd75ce"
                            className="form-text text-dark"
                        >
                            {supporter.currency.toUpperCase()}{" "}
                            {(supporter.pledge_amount / 100).toFixed(2)}
                        </span>
                    )}
                </div>
            </Link>
        );
    }
);

export default PetitionSupportersListItem;
