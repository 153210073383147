import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../../contexts/auth-context";
import { getReferrals as api_getReferrals } from "../../../../api/referralProgram/getReferrals";
import Referral from "../../../../models/Referral";
import { parseCursor } from "../../../../utils/helpers";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import ReferralsList from "./ReferralsList/ReferralsList";
import { useTranslation } from "react-i18next";

const ReferralsPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [referrals, setReferrals] = useState<Referral[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getReferrals = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_getReferrals(cursor);
                if (cursor === undefined) setReferrals(response.results);
                else
                    setReferrals((prev: Referral[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {}
            setIsLoading(false);
        },
        []
    );

    useEffect(() => {
        setReferrals([]);
        setCursor(undefined);
        setHasMore(true);
        getReferrals(undefined, true);
    }, [getReferrals]);

    return (
        <div id="b6215375-a557-4e5d-8d07-8c99f4b7c067">
            <h6 id="d00eddc9-86fb-42ac-adf4-187e79f6cf78" className="mb-3">
                {t("referralProgramPage.referralsPage.referrals")}
            </h6>
            <hr id="b9ebee0b-70c7-4f40-bc35-0137f30a54e1" />
            {!isLoading && referrals.length === 0 && (
                <div id="e50f1936-413d-4406-ba4b-e33aefac6d17">
                    {t("referralProgramPage.referralsPage.noReferralsYet")}
                </div>
            )}
            {!!referrals && (
                <ReferralsList
                    id="db9a5821-0753-4876-ab11-66daf5f30957"
                    referrals={referrals}
                    getReferrals={() => getReferrals(cursor, hasMore)}
                    isLoading={isLoading}
                />
            )}
            {isLoading && (
                <Spinner
                    id="ab5a94ff-dfc7-459a-a63a-2cb677c66ddd"
                    className="mt-3"
                />
            )}
        </div>
    );
};

export default ReferralsPage;
