import React, {
    ComponentProps,
    HTMLAttributes,
    useCallback,
    useEffect,
    useState,
} from "react";
import { getRemainingTimeFormatted } from "../../../utils/helpers";

interface Props extends ComponentProps<any>, HTMLAttributes<any> {
    deadline: Date;
}

const Countdown = ({ id, deadline, className, style }: Props) => {
    const [timer, setTimer] = useState("");

    const startTimer = useCallback((e: Date) => {
        setTimer(getRemainingTimeFormatted(e));
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            startTimer(deadline);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [deadline, startTimer]);

    return (
        <span id={id} className={className} style={style}>
            {timer}
        </span>
    );
};

export default Countdown;
