import ReferralProgram from "./ReferralProgram";

export enum ReferrerStatus {
    PENDING = "pending",
    ACTIVE = "active",
    SUSPENDED = "suspended",
}

class Referrer {
    readonly uuid: string;
    readonly user: string;
    readonly referral_program: ReferralProgram;
    readonly current_referrals_count: number;
    readonly paid_amount: number;
    readonly max_referrals: number;
    readonly status: ReferrerStatus;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.user = data.user;
        this.referral_program = new ReferralProgram(data.referral_program);
        this.current_referrals_count = data.current_referrals_count;
        this.paid_amount = data.paid_amount;
        this.max_referrals = data.max_referrals;
        this.status = data.status;
    }
}

export default Referrer;
