import React, { ForwardedRef, HTMLAttributes, useState } from "react";
import Referral, { ReferralStatus } from "../../../../../../models/Referral";
import UserBasicInfo from "../../../../../../components/UserBasicInfo/UserBasicInfo";
import classes from "./ReferralsListItem.module.scss";
import { useNavigate } from "react-router-dom";

interface Props extends HTMLAttributes<any> {
    referral: Referral;
}

const ReferralsListItem = React.forwardRef(
    (
        { id, referral: initialReferral }: Props,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const navigate = useNavigate();
        const [referral, setReferral] = useState<Referral>(initialReferral);

        let statusBadge = undefined;
        switch (referral.status) {
            case ReferralStatus.PENDING: {
                statusBadge = (
                    <span
                        id="de224464-15c3-4a2e-8a91-a6d91c5157e4"
                        className="badge bg-warning-subtle text-warning-emphasis"
                    >
                        {referral.status}
                    </span>
                );
                break;
            }
            case ReferralStatus.ACTIVE:
                statusBadge = (
                    <span
                        id="b0d3ca0f-5f94-48a1-b948-e969210be3c8"
                        className="badge bg-success-subtle text-success-emphasis"
                    >
                        {referral.status}
                    </span>
                );
                break;
            case ReferralStatus.SUSPENDED:
                statusBadge = (
                    <span
                        id="d17156d0-9f6f-43d5-8307-1e3c24ee2de6"
                        className="badge bg-danger-subtle text-danger-emphasis"
                    >
                        {referral.status}
                    </span>
                );
                break;
        }

        return (
            <div
                id={id}
                className={`${classes.referral} d-flex justify-content-between align-items-center border rounded p-2`}
                ref={ref}
                onClick={() => navigate(`/${referral.referee.username}`)}
            >
                <UserBasicInfo
                    id="ba27e578-028e-44f9-be0d-721edef62540"
                    user={referral.referee}
                />
                {statusBadge}
                <div
                    id="ad1c0f15-7938-43c7-859c-f1066e40526d"
                    className="form-text"
                >
                    <div
                        id="f5118c93-0cbc-43ae-bb79-a4e9e95007fd"
                        className="d-flex justify-content-end gap-2"
                    >
                        <span
                            id="e7f67b24-b671-4f1c-9b9c-3349ee346ef8"
                            className="d-flex gap-1"
                        >
                            <span id="d9115da5-298b-4ca8-913e-8b7d00079906">
                                {referral.currency.toUpperCase()}
                            </span>
                            <span id="c60ca493-a83a-4912-a5df-7182316d94c6">
                                {(referral.referrer_paid_amount / 100).toFixed(
                                    2
                                )}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
);

export default ReferralsListItem;
