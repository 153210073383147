import ReferralProgramPageNavbar from "./ReferralProgramPageNavbar/ReferralProgramPageNavbar";
import { Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import ModalContext from "../../contexts/modal-context";
import AuthContext from "../../contexts/auth-context";
import Footer from "../../components/Footer/Footer";
import ReferralProgramDetailsPage from "./pages/ReferralProgramDetailsPage/ReferralProgramDetailsPage";

export enum ReferralProgramPageTab {
    PROGRAM = "",
    REFERRER = "referrer",
    REFERRALS = "referrals",
}

const ReferralProgramPage = () => {
    const { closeModal } = useContext(ModalContext);
    const { isLoggedIn } = useContext(AuthContext);
    useEffect(() => {
        closeModal();
    }, [closeModal]);

    let content = <></>;
    if (isLoggedIn) {
        content = (
            <div id="be5c5932-e346-44c6-941a-76f75bc855b2">
                <ReferralProgramPageNavbar id="bb619742-778d-4b69-a55e-6b68e0aa10f6" />
                <div id="c03f8c39-0cbe-4541-ae65-3587ac9f2591" className="py-4">
                    <Outlet />
                </div>
            </div>
        );
    } else {
        content = (
            <div
                id="d42cb626-c667-4288-ac0a-abf4e06a226e"
                className="d-flex flex-column gap-5"
            >
                <ReferralProgramDetailsPage />
                <Footer id="a3802cc7-b117-4e25-9830-c0f1d1d17320" />
            </div>
        );
    }

    return content;
};

export default ReferralProgramPage;
