import React, { useCallback, useContext, useEffect, useState } from "react";
import Referrer, { ReferrerStatus } from "../../../../models/Referrer";
import {
    FailureResponse,
    getReferrer as api_getReferrer,
} from "../../../../api/referralProgram/getReferrer";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import AlertOverlay from "../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../contexts/modal-context";
import ClipboardCopy from "../../../../components/UI/ClipboardCopy/ClipboardCopy";
import AuthContext from "../../../../contexts/auth-context";
import { useTranslation } from "react-i18next";
import referralProgram, {
    ReferralProgramStatus,
} from "../../../../models/ReferralProgram";

const ReferrerPage = () => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const [referrer, setReferrer] = useState<Referrer>();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const getReferrer = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await api_getReferrer();
            setReferrer(response);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;

            if (errors)
                if (status_code === 404) {
                    setErrorMsg(
                        t("referralProgramPage.referrerPage.notReferredYet")
                    );
                } else
                    showModal(
                        <AlertOverlay
                            id="e0f6a4a8-cb53-4303-b2f0-c0facded324a"
                            status="error"
                            message={
                                errors.error instanceof Array
                                    ? errors.error[0]
                                    : errors.error!
                            }
                        />
                    );
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getReferrer();
    }, [getReferrer]);

    const referralLink = `${process.env.REACT_APP_BASE_URL}?rb=${user?.username}`;

    let statusBadge = <></>;
    if (referrer)
        switch (referrer.status) {
            case ReferrerStatus.PENDING: {
                statusBadge = (
                    <span
                        id="fd0eddbf-37ab-47f2-8b02-5c0db9351a24"
                        className="badge bg-warning-subtle text-warning-emphasis"
                    >
                        {referrer.status}
                    </span>
                );
                break;
            }
            case ReferrerStatus.ACTIVE:
                statusBadge = (
                    <span
                        id="c5e23404-2ed9-4fd0-b7f8-86309e266fba"
                        className="badge bg-success-subtle text-success-emphasis"
                    >
                        {referrer.status}
                    </span>
                );
                break;
            case ReferrerStatus.SUSPENDED:
                statusBadge = (
                    <span
                        id="bac2b08b-139c-492d-aaa0-c3ad7fbb0d1d"
                        className="badge bg-danger-subtle text-danger-emphasis"
                    >
                        {referrer.status}
                    </span>
                );
                break;
        }

    let referralProgramStatusBadge = <></>;
    if (referrer)
        switch (referrer.referral_program.status) {
            case ReferralProgramStatus.PENDING: {
                referralProgramStatusBadge = (
                    <span
                        id="fb3d602b-32c8-49d3-a88b-c8021d30238b"
                        className="badge bg-warning-subtle text-warning-emphasis"
                    >
                        {referrer.referral_program.status}
                    </span>
                );
                break;
            }
            case ReferralProgramStatus.ACTIVE:
                referralProgramStatusBadge = (
                    <span
                        id="c9e52cdc-9abe-4ac5-b14b-9acf1c7a8225"
                        className="badge bg-success-subtle text-success-emphasis"
                    >
                        {referrer.referral_program.status}
                    </span>
                );
                break;
            case ReferralProgramStatus.SUSPENDED:
                referralProgramStatusBadge = (
                    <span
                        id="a7d7f24c-f9d3-4db9-b008-62bf8ed4f63b"
                        className="badge bg-danger-subtle text-danger-emphasis"
                    >
                        {referrer.referral_program.status}
                    </span>
                );
                break;
        }

    return (
        <div id="d647e769-3e27-4d46-ba6e-10d700dd6b64">
            <h6 id="e39f4afa-dac3-4476-9ea6-da925942be3b" className="mb-3">
                {t("referralProgramPage.referrerPage.referrerOverview")}
            </h6>
            <hr id="fb2880f4-297d-4099-9642-85f97ebd60f8" />
            {isLoading && <Spinner id="a0b14ba0-efb3-4b67-8143-91d41d3e274c" />}
            {!isLoading && !referrer && errorMsg && (
                <div id="a195fd12-53e0-4733-8d80-bf9cb45dd660">{errorMsg}</div>
            )}
            {!isLoading && referrer && (
                <div
                    id="e9cb9927-a964-49b4-a908-b363e2b2b00d"
                    className="d-flex flex-column gap-3"
                >
                    <div
                        id="bccdfda2-5998-4860-b72e-e2b605fb6403"
                        className="d-flex justify-content-between"
                    >
                        <span id="ba70cc48-ea96-49cc-bc20-8f4dff1abbc9">
                            {t(
                                "referralProgramPage.referrerPage.referralProgramStatus"
                            )}
                        </span>
                        {referralProgramStatusBadge}
                    </div>
                    <div
                        id="d9e64ed2-abf5-470d-966e-ee924407658d"
                        className="d-flex justify-content-between"
                    >
                        <span id="cfefc22c-36a2-4626-a159-c3a05d5c47d2">
                            {t(
                                "referralProgramPage.referrerPage.referrerStatus"
                            )}
                        </span>
                        {statusBadge}
                    </div>
                    <div
                        id="f88e460d-b7d7-4ff4-a05c-f75fa8955591"
                        className="d-flex flex-column gap-1"
                    >
                        <span id="a05d6d77-f54c-4eea-a153-b1c1d1bfe4f1">
                            {t("referralProgramPage.referrerPage.referralLink")}
                        </span>
                        <div
                            id="d25b51bc-890e-4a70-81f4-214aeb53e786"
                            className="d-flex align-items-center gap-2"
                        >
                            <ClipboardCopy
                                id="d454cbd6-2f9b-4657-b97c-3524c02a1939"
                                copyText={referralLink}
                                btnText={t("clipboardCopy.copyLink")}
                                showValue={true}
                                className="btn-dark"
                            />
                        </div>
                    </div>
                    <div
                        id="b6ece350-9a3b-44aa-81ec-7213f2fe1d95"
                        className="d-flex justify-content-between"
                    >
                        <span id="bb6aece5-0f39-4a1a-b777-c1bb514919fa">
                            {t(
                                "referralProgramPage.referrerPage.currentReferrals"
                            )}
                        </span>
                        {referrer.max_referrals ? (
                            <div
                                id="d7d7b0ed-1765-4f7b-90b7-69f8f1d1e4a1"
                                className="d-flex gap-1"
                            >
                                <span id="c7dce002-56c2-4eef-a2d5-39c775049a52">
                                    {referrer.current_referrals_count}
                                </span>
                                /
                                <span id="e6f2663b-c8e7-4923-a427-4212db22ae43">
                                    {referrer.max_referrals}
                                </span>
                            </div>
                        ) : (
                            <span id="f07b196f-482a-47c0-85b0-b8a735f08622">
                                {referrer.current_referrals_count}
                            </span>
                        )}
                    </div>
                    <div
                        id="e65159ce-f6fc-4bc9-9893-9b44c5c52c06"
                        className="d-flex justify-content-between"
                    >
                        <span id="a0db8227-9118-4d97-84f8-f7104a438eb7">
                            {t(
                                "referralProgramPage.referrerPage.totalPaidAmount"
                            )}
                        </span>
                        <div
                            id="f8df2c75-f704-423f-ab80-b2846a1e410d"
                            className="d-flex gap-2"
                        >
                            <span id="ef9a4bba-c2ef-4ff6-afa7-d07d0118e5b5">
                                USD
                            </span>
                            <span id="c5b5f0d1-cc8a-47c9-b6c6-e1c4b596ccba">
                                {(referrer.paid_amount / 100).toFixed(2)}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReferrerPage;
