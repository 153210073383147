import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import classes from "./PrivacyPage.module.scss";

const PrivacyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div
                id="aee23027-5192-4545-9a50-aa42698a8d93"
                className={`p-3 form-text text-dark container ${classes.content}`}
            >
                <h2 id="3e26e071-4f36-4b46-8af3-90aef9b1e0b3" className="mb-4">
                    Privacy Policy
                </h2>
                <p id="83cb93ad-f567-422b-8e3d-05e5b84232c3">
                    Last updated: March 12, 2025
                </p>
                <p id="86e300b4-c09e-4899-8e61-d48f06f2cc6d">
                    This Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                </p>
                <p id="7db01ad3-c80b-47e8-8dcd-08a372262147">
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy.
                </p>
                <h4
                    id="b544f321-96f6-43b5-af29-1a14548b0b39"
                    className="mt-4 mb-3"
                >
                    Interpretation and Definitions
                </h4>
                <h5 id="65b5a6cb-5a95-40a4-8024-99aa9568f7ea">
                    Interpretation
                </h5>
                <p id="2f3336f3-cbdb-42e6-82a6-cc14c4970d87">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                </p>
                <h5 id="f38f52e8-aa82-454a-be12-0ad739cde2cc">Definitions</h5>
                <p id="6b9a59d6-f182-476a-845b-862a40d5ff84">
                    For the purposes of this Privacy Policy:
                </p>
                <ul id="2fcb1be8-517d-414f-9974-3c5485a324d4">
                    <li id="acdca238-0f8e-46c7-a21a-2c2ec0af2c2e">
                        <p id="e62a1082-71c0-4cdd-ade9-8782df78050e">
                            <strong id="7bdda60a-f6df-4dce-9827-1c4350caa9c4">
                                Account
                            </strong>{" "}
                            means a unique account created for You to access our
                            Service or parts of our Service.
                        </p>
                    </li>
                    <li id="e68b9b5e-edaf-486c-9f0a-befde4332165">
                        <p id="15385276-773b-4c8a-ab4c-e2f823f62e81">
                            <strong id="34ed88d1-dc29-4a45-bb7d-f35d74e71342">
                                Affiliate
                            </strong>{" "}
                            means an entity that controls, is controlled by or
                            is under common control with a party, where
                            &quot;control&quot; means ownership of 50% or more
                            of the shares, equity interest or other securities
                            entitled to vote for election of directors or other
                            managing authority.
                        </p>
                    </li>
                    <li id="6572292f-248d-4093-8521-080f545a21fa">
                        <p id="93aa68bd-6e4c-44e2-a8f1-3c9370fb3cba">
                            <strong id="e6b77407-9344-4585-9a7a-b69a27dbe740">
                                Application
                            </strong>{" "}
                            refers to Ablebees, the software program provided by
                            the Company.
                        </p>
                    </li>
                    <li id="47203089-2bdf-4f84-bbb7-12224afb0ab1">
                        <p id="f8e883c5-9c70-42c1-8a2a-729553957efd">
                            <strong id="c4005a92-26be-49dc-8ce7-bed702c117fb">
                                Business
                            </strong>
                            , for the purpose of CCPA/CPRA, refers to the
                            Company as the legal entity that collects Consumers'
                            personal information and determines the purposes and
                            means of the processing of Consumers' personal
                            information, or on behalf of which such information
                            is collected and that alone, or jointly with others,
                            determines the purposes and means of the processing
                            of consumers' personal information, that does
                            business in the State of California.
                        </p>
                    </li>
                    <li id="1e9cb8d8-0799-4629-a272-93f5aba3dda1">
                        <p id="9ba15d04-e2e7-4c29-a38e-9f09d3de9e68">
                            <strong id="a62ba432-7b2f-45bf-ad4e-0c44703be8ae">
                                CCPA
                            </strong>{" "}
                            and/or{" "}
                            <strong id="1d35d348-7aa8-4ba0-ab27-182a8c2f9500">
                                CPRA
                            </strong>{" "}
                            refers to California Consumer Privacy Act (the
                            &quot;CCPA&quot;) as amended by the California
                            Privacy Rights Act of 2020 (the &quot;CPRA&quot;).
                        </p>
                    </li>
                    <li id="b18da83d-780a-4d13-9774-995931d33e3b">
                        <p id="65771037-91fa-4ecb-9796-64b1c38b6325">
                            <strong id="63cbf814-18ef-4fcf-8242-b4bb95690acb">
                                Company
                            </strong>{" "}
                            (referred to as either &quot;the Company&quot;,
                            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
                            this Agreement) refers to{" "}
                            <strong id="671daaf5-733f-41ef-8865-44c52e0a098f">
                                Ablebees Inc., 548 Market St, PMB 76869, San
                                Francisco, CA 94104-5401, US
                            </strong>
                            .
                        </p>
                        <p id="b385fbdf-c2aa-488b-8f5e-94f710970815">
                            For the purpose of the GDPR, the Company is the Data
                            Controller.
                        </p>
                    </li>
                    <li id="9adf90ea-557c-473a-9d51-969cccd2c892">
                        <p id="6f2d6a10-44be-4a8a-b4c2-66e36025c68d">
                            <strong id="63e7c4f8-39f1-4c2b-8ecb-d38fbcc72790">
                                Consumer
                            </strong>
                            , for the purpose of the CCPA/CPRA, means a natural
                            person who is a California resident. A resident, as
                            defined in the law, includes (1) every individual
                            who is in the USA for other than a temporary or
                            transitory purpose, and (2) every individual who is
                            domiciled in the USA who is outside the USA for a
                            temporary or transitory purpose.
                        </p>
                    </li>
                    <li id="0995702c-3274-4c28-be72-f38940ac17d0">
                        <p id="bfaaa01c-d8c6-46a0-b65e-b855e9993789">
                            <strong id="c2293cb2-a1d6-4b0e-b304-0030e86f34b2">
                                Cookies
                            </strong>{" "}
                            are small files that are placed on Your computer,
                            mobile device or any other device by a website,
                            containing the details of Your browsing history on
                            that website among its many uses.
                        </p>
                    </li>
                    <li id="a348f252-8637-4c4b-9d94-77c1cbc10137">
                        <p id="0158291a-5e6b-4a0a-9dde-b0808985b4e2">
                            <strong id="acacc7ff-dbaa-4fb2-85df-a1fa61819681">
                                Country
                            </strong>{" "}
                            refers to: United States
                        </p>
                    </li>
                    <li id="48a09267-2f53-4ab2-9fb2-458bc4af3773">
                        <p id="0aacd2f8-0e6b-4d0b-a2b9-69844de0adf9">
                            <strong id="1159d760-bcf6-4c7d-aa41-e1019409c3bb">
                                Data Controller
                            </strong>
                            , for the purposes of the GDPR (General Data
                            Protection Regulation), refers to the Company as the
                            legal person which alone or jointly with others
                            determines the purposes and means of the processing
                            of Personal Data.
                        </p>
                    </li>
                    <li id="3aed7f56-4ff6-45e2-bcbc-8aace04fd174">
                        <p id="c36c4fd9-dbcb-47fa-bf7b-f7871d4dc4e7">
                            <strong id="85d01f8b-60cc-4e36-9ead-abcb8d8340f0">
                                Device
                            </strong>{" "}
                            means any device that can access the Service such as
                            a computer, a cellphone or a digital tablet.
                        </p>
                    </li>
                    <li id="a8269270-04a4-4bcd-a1be-c6dfb13674a0">
                        <p id="d85533a5-0dc9-4892-963d-ab72280aabfd">
                            <strong id="c6844760-1c1b-4d2d-b662-02a07b434ba1">
                                Do Not Track
                            </strong>{" "}
                            (DNT) is a concept that has been promoted by US
                            regulatory authorities, in particular the U.S.
                            Federal Trade Commission (FTC), for the Internet
                            industry to develop and implement a mechanism for
                            allowing internet users to control the tracking of
                            their online activities across websites.
                        </p>
                    </li>
                    <li id="07e487ac-7dd8-47e5-9ba4-f36918eb8246">
                        <p id="85df2274-6dbd-44c1-a28f-221a0d807f1c">
                            <strong id="fc26e7f4-c1fc-4ec8-9c01-f7aeaa3ad583">
                                Facebook Fan Page
                            </strong>{" "}
                            is a public profile named Ablebees specifically
                            created by the Company on the Facebook social
                            network, accessible from{" "}
                            <a
                                id="d5de79df-181e-4fee-bf05-a2fe3ff94eae"
                                href="https://www.facebook.com/ablebees"
                                rel="external nofollow noopener"
                                target="_blank"
                            >
                                https://www.facebook.com/ablebees
                            </a>
                        </p>
                    </li>
                    <li id="977ee973-3a9d-42e4-be1f-0555c703da7c">
                        <p id="2e5cb5a6-677e-49d9-9d70-8f40ba9f2d05">
                            <strong id="39ab30cb-de93-48cc-8dc2-b0c4736deb8e">
                                GDPR
                            </strong>{" "}
                            refers to EU General Data Protection Regulation.
                        </p>
                    </li>
                    <li id="d8d64547-c893-440c-afd0-f39c9295cf17">
                        <p id="e4acc15f-95ab-4b2e-9f31-8d92bb238a56">
                            <strong id="79155969-a085-4904-b4d4-c997f7ef2587">
                                Personal Data
                            </strong>{" "}
                            is any information that relates to an identified or
                            identifiable individual.
                        </p>
                        <p id="5693bbfe-5b10-46ed-a393-a236e39b31c5">
                            For the purposes of GDPR, Personal Data means any
                            information relating to You such as a name, an
                            identification number, location data, online
                            identifier or to one or more factors specific to the
                            physical, physiological, genetic, mental, economic,
                            cultural or social identity.
                        </p>
                        <p id="f1323e7e-1958-471c-ac71-298759745190">
                            For the purposes of the CCPA/CPRA, Personal Data
                            means any information that identifies, relates to,
                            describes or is capable of being associated with, or
                            could reasonably be linked, directly or indirectly,
                            with You.
                        </p>
                    </li>
                    <li id="c42d38fb-0349-482a-8ab7-c401403e9218">
                        <p id="2162c621-faff-409d-ba4e-6f7993a6e547">
                            <strong id="982c645c-d2e2-40ec-bea3-082addadbaa3">
                                Service
                            </strong>{" "}
                            refers to the Application or the Website or both.
                        </p>
                    </li>
                    <li id="2a353d7b-9005-47b3-800f-b7f8219b404d">
                        <p id="2c2ccd91-881f-4532-b0b1-fd28630ec7a4">
                            <strong id="7c7c04c0-077d-4ad0-a927-651c5ddff852">
                                Service Provider
                            </strong>{" "}
                            means any natural or legal person who processes the
                            data on behalf of the Company. It refers to
                            third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the
                            Service on behalf of the Company, to perform
                            services related to the Service or to assist the
                            Company in analyzing how the Service is used. For
                            the purpose of the GDPR, Service Providers are
                            considered Data Processors.
                        </p>
                    </li>
                    <li id="fbcc5b9a-1487-44b4-9226-294f819fa1c7">
                        <p id="93e54085-094f-43d4-b684-f95af04feffc">
                            <strong id="69da06e9-9d0d-491c-94c3-cac669226f5d">
                                Third-party Social Media Service
                            </strong>{" "}
                            refers to any website or any social network website
                            through which a User can log in or create an account
                            to use the Service.
                        </p>
                    </li>
                    <li id="44eb88de-0878-4fb5-b596-2f21bc41e731">
                        <p id="12a3c872-c610-4322-8e2d-7629943a80f1">
                            <strong id="c04f0037-27d1-41bc-bba4-197464bcedfa">
                                Usage Data
                            </strong>{" "}
                            refers to data collected automatically, either
                            generated by the use of the Service or from the
                            Service infrastructure itself (for example, the
                            duration of a page visit).
                        </p>
                    </li>
                    <li id="b310dd91-675a-42fa-a15f-c35c6e2cdf7c">
                        <p id="13f3280c-1367-43fe-b1de-6f7c1de0c6cc">
                            <strong id="2f0c6a46-5e70-40de-8d9a-e4e4e11b5844">
                                Website
                            </strong>{" "}
                            refers to Ablebees, accessible from{" "}
                            <a
                                id="feda265f-cbb2-4542-857e-1bc956b257aa"
                                href="https://www.ablebees.com"
                                rel="external nofollow noopener"
                                target="_blank"
                            >
                                https://www.ablebees.com
                            </a>
                        </p>
                    </li>
                    <li id="dc6391d7-2865-4c98-b7e1-4d394c914d6a">
                        <p id="54a82551-02a6-4264-b202-7f0408872b04">
                            <strong id="7beecfe4-a9d7-492c-b044-6ba65f21de3d">
                                You
                            </strong>{" "}
                            means the individual accessing or using the Service,
                            or the company, or other legal entity on behalf of
                            which such individual is accessing or using the
                            Service, as applicable.
                        </p>
                        <p id="cd70f6b5-5947-4523-a175-faa239d7daab">
                            Under GDPR, You can be referred to as the Data
                            Subject or as the User as you are the individual
                            using the Service.
                        </p>
                    </li>
                </ul>
                <h4
                    id="5a487123-f858-4eb5-98a0-55ec3774b002"
                    className="mt-4 mb-3"
                >
                    Collecting and Using Your Personal Data
                </h4>
                <h5 id="d65e969e-39c5-4d51-afa0-8711a06645bd">
                    Types of Data Collected
                </h5>
                <h4
                    id="3399d60d-06b4-470c-aa01-fec794940b37"
                    className="mt-4 mb-3"
                >
                    Personal Data
                </h4>
                <p id="cce38e68-5bd6-428a-92b2-1a13832fc191">
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                </p>
                <ul id="87a1edc8-5575-498f-aa6f-e87cc0f68eb9">
                    <li id="75043e6f-fd4c-4910-a8dd-6f0b7a73444c">
                        <p id="1c6d77aa-6929-4ee1-bd72-4b2c1fd0712f">
                            Email address
                        </p>
                    </li>
                    <li id="5cca36a5-1dbe-4c0d-93a3-0489ed645d7a">
                        <p id="8894bc83-ef1e-4a0b-9d1d-7db01116917f">
                            First name and last name
                        </p>
                    </li>
                    <li id="62b58f18-d235-44b8-8492-bca2a857b80d">
                        <p id="60e70c15-f0bf-4173-a2da-7103f14374fa">
                            Phone number
                        </p>
                    </li>
                    <li id="dfdbdf5f-33b6-4b6b-a21c-7eb812c87fe7">
                        <p id="2033a6c9-9776-44d0-a35c-25a6b4d12f95">
                            Address, State, Province, ZIP/Postal code, City
                        </p>
                    </li>
                    <li id="5198165f-4aa6-484f-83f9-3c97768bc71f">
                        <p id="4267ba26-9e20-430a-b33c-f38eab88d51b">
                            Usage Data
                        </p>
                    </li>
                </ul>
                <h4
                    id="18793bd8-5433-4f2f-ae0a-cc99bdaf347d"
                    className="mt-4 mb-3"
                >
                    Usage Data
                </h4>
                <p id="58f83ebc-61ba-4ae1-b580-3ea2ee794feb">
                    Usage Data is collected automatically when using the
                    Service.
                </p>
                <p id="ba72c4af-9dae-4b8d-9f2f-4a2a3c931320">
                    Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                </p>
                <p id="e976d3a5-24a1-4f60-9e97-20f467901ed3">
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                </p>
                <p id="6a921ae6-89cb-4a69-9e22-4af94500d122">
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                </p>
                <h4
                    id="b10d3d65-fb85-4563-a389-b48067c23619"
                    className="mt-4 mb-3"
                >
                    Information from Third-Party Social Media Services
                </h4>
                <p id="4d65003b-e938-4d5e-ae23-bdd270476c69">
                    The Company allows You to create an account and log in to
                    use the Service through the following Third-party Social
                    Media Services:
                </p>
                <ul id="580e50b8-9034-4861-99ad-4441d630a29c">
                    <li id="0147e92d-bcf0-496c-bf44-9982f8e77406">Google</li>
                    <li id="527026cb-ba81-4328-9ebd-234897d2552f">Facebook</li>
                    <li id="2c960bd9-671a-4b09-b86c-f7e516540330">Twitter</li>
                    <li id="04c196d2-b805-4949-bedc-5762d7f8b725">LinkedIn</li>
                </ul>
                <p id="cdf91908-3e74-4686-aa65-cf7e53094503">
                    If You decide to register through or otherwise grant us
                    access to a Third-Party Social Media Service, We may collect
                    Personal data that is already associated with Your
                    Third-Party Social Media Service's account, such as Your
                    name, Your email address, Your activities or Your contact
                    list associated with that account.
                </p>
                <p id="02af35ba-3c50-4a5c-bc1b-8bdb03f7e977">
                    You may also have the option of sharing additional
                    information with the Company through Your Third-Party Social
                    Media Service's account. If You choose to provide such
                    information and Personal Data, during registration or
                    otherwise, You are giving the Company permission to use,
                    share, and store it in a manner consistent with this Privacy
                    Policy.
                </p>
                <h4
                    id="e94b4658-10be-47aa-9821-b85df25e0391"
                    className="mt-4 mb-3"
                >
                    Information Collected while Using the Application
                </h4>
                <p id="0c773ce4-8c21-427d-a7c4-989b92a40adf">
                    While using Our Application, in order to provide features of
                    Our Application, We may collect, with Your prior permission:
                </p>
                <ul id="0f4a8586-958c-41f5-bf2d-6dc70c644eb8">
                    <li id="c4c81381-5019-4358-8a91-ee52f46696e5">
                        Information regarding your location
                    </li>
                </ul>
                <p id="d9a6c86b-ccdd-4a9d-9583-a98aa788b3cf">
                    We use this information to provide features of Our Service,
                    to improve and customize Our Service. The information may be
                    uploaded to the Company's servers and/or a Service
                    Provider's server or it may be simply stored on Your device.
                </p>
                <p id="9357f226-a000-4013-994c-58a53cb83ad4">
                    You can enable or disable access to this information at any
                    time, through Your Device settings.
                </p>
                <h4
                    id="04d2c399-0283-48e8-9498-786670fffb8e"
                    className="mt-4 mb-3"
                >
                    Tracking Technologies and Cookies
                </h4>
                <p id="0e82fa69-b235-4bb2-8d42-742626ebc98b">
                    We use Cookies and similar tracking technologies to track
                    the activity on Our Service and store certain information.
                    Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our
                    Service. The technologies We use may include:
                </p>
                <ul id="ff6c0133-5efd-4f39-8b22-725e7efda5eb">
                    <li id="ba7ef25b-465b-4b97-95a4-9cd33412b795">
                        <strong id="aeb3985a-f1c6-44cb-b612-0f808f0e0211">
                            Cookies or Browser Cookies.
                        </strong>{" "}
                        A cookie is a small file placed on Your Device. You can
                        instruct Your browser to refuse all Cookies or to
                        indicate when a Cookie is being sent. However, if You do
                        not accept Cookies, You may not be able to use some
                        parts of our Service. Unless you have adjusted Your
                        browser setting so that it will refuse Cookies, our
                        Service may use Cookies.
                    </li>
                    <li id="dc584cb4-2012-4397-95fd-f7f63cf98f6d">
                        <strong id="a84d27a7-1a46-414c-8ff5-dabdbdbd0b9d">
                            Web Beacons.
                        </strong>{" "}
                        Certain sections of our Service and our emails may
                        contain small electronic files known as web beacons
                        (also referred to as clear gifs, pixel tags, and
                        single-pixel gifs) that permit the Company, for example,
                        to count users who have visited those pages or opened an
                        email and for other related website statistics (for
                        example, recording the popularity of a certain section
                        and verifying system and server integrity).
                    </li>
                </ul>
                <p id="35af96d2-45d6-4b2e-955c-8ec11a5c9683">
                    Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
                    Cookies. Persistent Cookies remain on Your personal computer
                    or mobile device when You go offline, while Session Cookies
                    are deleted as soon as You close Your web browser.
                </p>
                <p id="e9f4f9fd-c866-41cc-aad3-afe488f6096d">
                    We use both Session and Persistent Cookies for the purposes
                    set out below:
                </p>
                <ul id="d1ebfc94-91e8-4eb0-9063-39bd72ac763c">
                    <li id="34f57fc2-6beb-4252-a78a-ed975a436c10">
                        <p id="c668dddc-bb01-4f8f-9a9b-40ed8ae9e4c3">
                            <strong id="3c4088aa-e1a6-41b4-bd7e-a5862eff4c3a">
                                Necessary / Essential Cookies
                            </strong>
                        </p>
                        <p id="a616a46a-859f-4003-9aef-7ef3bd3b65bf">
                            Type: Session Cookies
                        </p>
                        <p id="5b92b4f6-07bf-46bf-bfe1-4eb12aeaca97">
                            Administered by: Us
                        </p>
                        <p id="25cdb699-23db-470a-b562-40b762d418f4">
                            Purpose: These Cookies are essential to provide You
                            with services available through the Website and to
                            enable You to use some of its features. They help to
                            authenticate users and prevent fraudulent use of
                            user accounts. Without these Cookies, the services
                            that You have asked for cannot be provided, and We
                            only use these Cookies to provide You with those
                            services.
                        </p>
                    </li>
                    <li id="f24b75ed-ac04-4856-8aa4-88072851e5b9">
                        <p id="5191abf2-99ea-4fba-89de-f8bea3f60793">
                            <strong id="4e52e1d4-af00-4931-bfdd-e1c96178c0b7">
                                Cookies Policy / Notice Acceptance Cookies
                            </strong>
                        </p>
                        <p id="32f01e6c-b14d-42e9-b251-841c1c381781">
                            Type: Persistent Cookies
                        </p>
                        <p id="b8c41049-7280-4f63-a844-bd54b7515da6">
                            Administered by: Us
                        </p>
                        <p id="244562cb-aa3e-4d4d-859a-230abf255d5a">
                            Purpose: These Cookies identify if users have
                            accepted the use of cookies on the Website.
                        </p>
                    </li>
                    <li id="001123d2-8971-4be9-a7b9-107a340a20e4">
                        <p id="d1808ad6-1c28-48d8-8d5e-2c78a3399089">
                            <strong id="f80cd9b9-19c4-4bfc-9841-21ca38d7a654">
                                Functionality Cookies
                            </strong>
                        </p>
                        <p id="4a2725a9-91d8-4e44-91d2-c1e6ec31ec09">
                            Type: Persistent Cookies
                        </p>
                        <p id="2e3d807a-c692-4cc8-bfff-cb014da015f5">
                            Administered by: Us
                        </p>
                        <p id="a969415e-2bbf-40b3-afbe-d3943bef811e">
                            Purpose: These Cookies allow us to remember choices
                            You make when You use the Website, such as
                            remembering your login details or language
                            preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to
                            avoid You having to re-enter your preferences every
                            time You use the Website.
                        </p>
                    </li>
                    <li id="1194ca5e-e27b-4429-b05d-aaf08cebf70a">
                        <p id="c7807e42-15a7-4b25-b641-7cbecd09b8e1">
                            <strong id="597d199f-a99d-4300-bc28-b9a93083054a">
                                Tracking and Performance Cookies
                            </strong>
                        </p>
                        <p id="bb32af41-95ec-4898-ae33-9c623c549d81">
                            Type: Persistent Cookies
                        </p>
                        <p id="801f148f-9978-442d-9b0e-a8d02c504550">
                            Administered by: Third-Parties
                        </p>
                        <p id="2a9122c6-c1c0-4d8b-afb7-317df66380d1">
                            Purpose: These Cookies are used to track information
                            about traffic to the Website and how users use the
                            Website. The information gathered via these Cookies
                            may directly or indirectly identify you as an
                            individual visitor. This is because the information
                            collected is typically linked to a pseudonymous
                            identifier associated with the device you use to
                            access the Website. We may also use these Cookies to
                            test new pages, features or new functionality of the
                            Website to see how our users react to them.
                        </p>
                    </li>
                </ul>
                <p id="7abe7db0-83a2-47cc-802a-d780298adcfa">
                    For more information about the cookies we use and your
                    choices regarding cookies, please visit our{" "}
                    <Link
                        id="5df498eb-4d1b-4788-8795-4fb31ff28eed"
                        // id="privacy-page-to-cookies"
                        to="/cookies"
                    >
                        Cookies Policy
                    </Link>{" "}
                    or the Cookies section of our Privacy Policy.
                </p>
                <h5 id="d885023f-847d-4c33-a422-c71a2cf90488">
                    Use of Your Personal Data
                </h5>
                <p id="4348039e-9612-4edc-a3d4-7ac62d3f88d4">
                    The Company may use Personal Data for the following
                    purposes:
                </p>
                <ul id="144f60e3-5941-41aa-8c14-22c88ee14bb8">
                    <li id="9c5c9e8b-4cca-46cb-ad72-548c59a329d7">
                        <p id="63f9c9d4-b23f-40c6-af0a-21c449b6fe12">
                            <strong id="ad08f653-64b9-4ae3-90bc-12b6ac8e0e78">
                                To provide and maintain our Service
                            </strong>
                            , including to monitor the usage of our Service.
                        </p>
                    </li>
                    <li id="e601218a-c662-4d3c-b304-dd557a16433c">
                        <p id="86225932-2d1f-4de5-af36-1a4b1edeacf6">
                            <strong id="a2e059f8-29df-4ca9-9527-84a91faf7fbf">
                                To manage Your Account:
                            </strong>{" "}
                            to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You
                            access to different functionalities of the Service
                            that are available to You as a registered user.
                        </p>
                    </li>
                    <li id="bacb7293-bf3c-4209-990a-df99d3153677">
                        <p id="b6a5c7c4-5ec5-42bc-ba98-ac3f2101abc4">
                            <strong id="059e2ed8-c9d4-4d9d-8284-0b02317d55c4">
                                For the performance of a contract:
                            </strong>{" "}
                            the development, compliance and undertaking of the
                            purchase contract for the products, items or
                            services You have purchased or of any other contract
                            with Us through the Service.
                        </p>
                    </li>
                    <li id="44f94a09-a349-47a6-8970-16e1730bb81c">
                        <p id="f7f34e2f-6c6c-43cb-b95b-03d14baaae1a">
                            <strong id="7ed858bb-3ce7-4ca3-a8f6-651cf8f8bac7">
                                To contact You:
                            </strong>{" "}
                            To contact You by email, telephone calls, SMS, or
                            other equivalent forms of electronic communication,
                            such as a mobile application's push notifications
                            regarding updates or informative communications
                            related to the functionalities, products or
                            contracted services, including the security updates,
                            when necessary or reasonable for their
                            implementation.
                        </p>
                    </li>
                    <li id="772924f5-34bd-4e8d-9fbf-f5a61541163e">
                        <p id="00a45596-1497-4c90-a64c-72fdc2435f02">
                            <strong id="16e1c1e3-aa4b-4f62-a4d7-314bc865f610">
                                To provide You
                            </strong>{" "}
                            with news, special offers and general information
                            about other goods, services and events which we
                            offer that are similar to those that you have
                            already purchased or enquired about unless You have
                            opted not to receive such information.
                        </p>
                    </li>
                    <li id="4073723b-364f-4d3f-b5be-307663b05730">
                        <p id="35b05050-1d87-41e9-ad7b-9920d5c86886">
                            <strong id="90463d63-239c-4d65-afb1-0e79c2dece6d">
                                To manage Your requests:
                            </strong>{" "}
                            To attend and manage Your requests to Us.
                        </p>
                    </li>
                    <li id="023fc710-79cf-4d16-b0a3-898ef7395f84">
                        <p id="fadafa55-eac9-4cc3-8125-921cc34b1343">
                            <strong id="17259abe-7616-4704-af25-0c4a1d560481">
                                For business transfers:
                            </strong>{" "}
                            We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or
                            all of Our assets, whether as a going concern or as
                            part of bankruptcy, liquidation, or similar
                            proceeding, in which Personal Data held by Us about
                            our Service users is among the assets transferred.
                        </p>
                    </li>
                    <li id="3bd9071a-a1cc-48c0-a95f-c74a057b2344">
                        <p id="f0877957-70b3-48cc-9cc2-0dda27f0a784">
                            <strong id="c2fd7bf2-f5c1-4edd-962e-d9dccc49aaa4">
                                For other purposes
                            </strong>
                            : We may use Your information for other purposes,
                            such as data analysis, identifying usage trends,
                            determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service,
                            products, services, marketing and your experience.
                        </p>
                    </li>
                </ul>
                <p id="13daa36b-2d1f-48f6-8962-3418665bd05f">
                    We may share Your personal information in the following
                    situations:
                </p>
                <ul id="12ab34a2-30c8-445d-8680-cd9dca807b4a">
                    <li id="f9ad96a6-ff61-4a0c-a0fb-2e8b77639865">
                        <strong id="5c95d9c1-1901-404d-a10e-f0b3a75da33f">
                            With Service Providers:
                        </strong>{" "}
                        We may share Your personal information with Service
                        Providers to monitor and analyze the use of our Service,
                        for payment processing, to contact You.
                    </li>
                    <li id="7277a6f0-dcbf-45fb-aa97-b529b16ea941">
                        <strong id="1e91cd32-62ec-464a-9049-bc4caff80f34">
                            For business transfers:
                        </strong>{" "}
                        We may share or transfer Your personal information in
                        connection with, or during negotiations of, any merger,
                        sale of Company assets, financing, or acquisition of all
                        or a portion of Our business to another company.
                    </li>
                    <li id="8c8978d3-cc7c-4090-80b8-069337db5570">
                        <strong id="d7993752-8a94-44e2-ba4e-5c97febaba5d">
                            With Affiliates:
                        </strong>{" "}
                        We may share Your information with Our affiliates, in
                        which case we will require those affiliates to honor
                        this Privacy Policy. Affiliates include Our parent
                        company and any other subsidiaries, joint venture
                        partners or other companies that We control or that are
                        under common control with Us.
                    </li>
                    <li id="de7f7549-42ce-4f0c-b94c-4b570457f83d">
                        <strong id="d1672aaf-05c8-48a2-94f0-21f1fb7fe82b">
                            With business partners:
                        </strong>{" "}
                        We may share Your information with Our business partners
                        to offer You certain products, services or promotions.
                    </li>
                    <li id="78d75b7f-1e9a-4997-acde-8404970d9fe5">
                        <strong id="ab4e74ff-18bf-4aec-8120-04e8d5b6d8cb">
                            With other users:
                        </strong>{" "}
                        when You share personal information or otherwise
                        interact in the public areas with other users, such
                        information may be viewed by all users and may be
                        publicly distributed outside. If You interact with other
                        users or register through a Third-Party Social Media
                        Service, Your contacts on the Third-Party Social Media
                        Service may see Your name, profile, pictures and
                        description of Your activity. Similarly, other users
                        will be able to view descriptions of Your activity,
                        communicate with You and view Your profile.
                    </li>
                    <li id="57a371a3-f453-4834-9d96-5dd384aa506c">
                        <strong id="0dfc35f0-596c-4e98-b372-df284e2becc6">
                            With Your consent
                        </strong>
                        : We may disclose Your personal information for any
                        other purpose with Your consent.
                    </li>
                </ul>
                <h5 id="4cd468e6-bf47-4578-a854-f3b0435b280a">
                    Retention of Your Personal Data
                </h5>
                <p id="5fe72688-40b1-47f1-afae-7f769cff150c">
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                </p>
                <p id="e3986a6f-343e-4a99-b3d7-4d54c27b5a27">
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                </p>
                <h5 id="7e1d63b2-b6ed-4372-a89e-9334c323e132">
                    Transfer of Your Personal Data
                </h5>
                <p id="5ac47194-be48-45ce-a180-38d16f9d753a">
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                </p>
                <p id="06c6acc0-3e85-48f8-a92e-9a08eb40d138">
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                </p>
                <p id="bcb3d829-40aa-4476-b200-e215ebea5e2b">
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                </p>
                <h5 id="73bda55a-8a7c-4879-9dc4-58817e66bd5e">
                    Delete Your Personal Data
                </h5>
                <p id="847a1596-3622-427a-84cd-8ed240e92125">
                    You have the right to delete or request that We assist in
                    deleting the Personal Data that We have collected about You.
                </p>
                <p id="7417f859-fae1-4186-b806-da8e8cde9643">
                    Our Service may give You the ability to delete certain
                    information about You from within the Service.
                </p>
                <p id="933e7a8a-e8d3-40ea-9405-d720f1d577da">
                    You may update, amend, or delete Your information at any
                    time by signing in to Your Account, if you have one, and
                    visiting the account settings section that allows you to
                    manage Your personal information. You may also contact Us to
                    request access to, correct, or delete any personal
                    information that You have provided to Us.
                </p>
                <p id="ddcb3ceb-720d-43bd-a31f-0022de415713">
                    Please note, however, that We may need to retain certain
                    information when we have a legal obligation or lawful basis
                    to do so.
                </p>
                <h5
                    id="834ec4dd-7642-4455-8a61-7408e75e1f09"
                    className="mt-4 mb-3"
                >
                    Disclosure of Your Personal Data
                </h5>
                <h5 id="e8b35d02-866e-4f40-8d48-dbffffbeed6f">
                    Business Transactions
                </h5>
                <p id="f0a83187-7be8-485b-bdb6-0fcfc28899c0">
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                </p>
                <h5 id="fd22b33d-d217-4cbd-8851-57c14ae56677">
                    Law enforcement
                </h5>
                <p id="09064575-f8fe-4b3d-952b-7d1f22734080">
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                </p>
                <h5 id="412c9bc8-157a-4a80-9c1d-57e91c6cbaeb">
                    Other legal requirements
                </h5>
                <p id="5ad77822-7aa9-4179-b3ab-cf24f29ba7cc">
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                </p>
                <ul id="38e1e010-6bde-4820-945a-a738498fb9ba">
                    <li id="c0dbd00d-24b0-4ed1-9216-5e29f431dc3b">
                        Comply with a legal obligation
                    </li>
                    <li id="2280e011-f313-4b0a-aa2f-7c4d9ca0dc01">
                        Protect and defend the rights or property of the Company
                    </li>
                    <li id="92d47e76-e6f0-47bb-a0f4-80a4e9ec0519">
                        Prevent or investigate possible wrongdoing in connection
                        with the Service
                    </li>
                    <li id="d62d93e0-bd1e-46f2-be87-d40437b396d5">
                        Protect the personal safety of Users of the Service or
                        the public
                    </li>
                    <li id="2c74a2a1-b2d5-4fc0-8b5f-e31cc912f832">
                        Protect against legal liability
                    </li>
                </ul>
                <h5 id="ed2fbb58-1f53-4f38-be31-9bb3f37e5763">
                    Security of Your Personal Data
                </h5>
                <p id="75cb184e-59a2-4f0d-9150-1af789740db7">
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security.
                </p>
                <h4
                    id="4ff47eb8-6f61-4e98-b0c2-03b88b1a7f89"
                    className="mt-4 mb-3"
                >
                    Detailed Information on the Processing of Your Personal Data
                </h4>
                <p id="7d6d8657-2dcc-4775-81b1-c1328bfa238a">
                    The Service Providers We use may have access to Your
                    Personal Data. These third-party vendors collect, store,
                    use, process and transfer information about Your activity on
                    Our Service in accordance with their Privacy Policies.
                </p>
                <h5 id="28dce129-f985-4973-81eb-b9d3ac1e36a0">Analytics</h5>
                <p id="e919a0e2-46b9-4795-8f7a-ec5a4f0069fe">
                    We may use third-party Service providers to monitor and
                    analyze the use of our Service.
                </p>
                <ul id="baa0b362-59aa-4777-9183-2fc547009e31">
                    <li id="bc6a7d40-3696-4dcb-b83a-f50fc370c087">
                        <p id="426e0b26-94d0-4eca-bbbd-02af63256f7c">
                            <strong id="bc87b000-202c-4e76-aede-26c362caad46">
                                Google Analytics
                            </strong>
                        </p>
                        <p id="f095bdd6-93bd-46b1-bb20-2b7d02cc098c">
                            Google Analytics is a web analytics service offered
                            by Google that tracks and reports website traffic.
                            Google uses the data collected to track and monitor
                            the use of our Service. This data is shared with
                            other Google services. Google may use the collected
                            data to contextualize and personalize the ads of its
                            own advertising network.
                        </p>
                        <p id="57f1fd04-74cc-4eaf-b57c-fd51c4eade83">
                            You can opt-out of having made your activity on the
                            Service available to Google Analytics by installing
                            the Google Analytics opt-out browser add-on. The
                            add-on prevents the Google Analytics JavaScript
                            (ga.js, analytics.js and dc.js) from sharing
                            information with Google Analytics about visits
                            activity.
                        </p>
                        <p id="744916f7-4e60-45a0-9d34-392f815cf9d0">
                            You may opt-out of certain Google Analytics features
                            through your mobile device settings, such as your
                            device advertising settings or by following the
                            instructions provided by Google in their Privacy
                            Policy:{" "}
                            <a
                                id="2b04bc4d-9c0e-494f-83ea-f7407b0098ab"
                                href="https://policies.google.com/privacy"
                                rel="external nofollow noopener"
                                target="_blank"
                            >
                                https://policies.google.com/privacy
                            </a>
                        </p>
                        <p id="18600ce9-83fd-469f-a2d7-c09d0912620c">
                            For more information on the privacy practices of
                            Google, please visit the Google Privacy &amp; Terms
                            web page:{" "}
                            <a
                                id="38f12fd0-faf4-4bf5-9507-8a216d6680a6"
                                href="https://policies.google.com/privacy"
                                rel="external nofollow noopener"
                                target="_blank"
                            >
                                https://policies.google.com/privacy
                            </a>
                        </p>
                    </li>
                </ul>
                <h5 id="a8b91a58-b6f5-4750-990a-736e273585d7">
                    Email Marketing
                </h5>
                <p id="1e7c94bf-ab2d-449f-bd84-0cc9052f67cc">
                    We may use Your Personal Data to contact You with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to You. You may opt-out
                    of receiving any, or all, of these communications from Us by
                    following the unsubscribe link or instructions provided in
                    any email We send or by contacting Us.
                </p>
                <p id="a36a915f-59b6-4ce1-943f-eddef909007a">
                    We may use Email Marketing Service Providers to manage and
                    send emails to You.
                </p>
                <ul id="a7d5cd21-7266-4376-be7e-de1393245e3c">
                    <li id="e0fdaa15-f73c-4512-9cb5-9324205f6e18">
                        <p id="b42f0cca-e86a-4f01-a0da-4e40b9968ffc">
                            <strong id="ca418c69-cddf-4a8b-a0e3-f0687e085f59">
                                SendGrid
                            </strong>
                        </p>
                        <p id="ab02d382-68f3-4e1b-91dd-41f2f18626ff">
                            Their Privacy Policy can be viewed at{" "}
                            <a
                                id="93ee1125-72bd-471d-9be4-66e3d254d89e"
                                href="https://www.twilio.com/legal/privacy"
                                rel="external nofollow noopener"
                                target="_blank"
                            >
                                https://www.twilio.com/legal/privacy
                            </a>
                        </p>
                    </li>
                </ul>
                <h5 id="167579b0-fe78-4d39-b54e-d043ecd57e71">Payments</h5>
                <p id="163e0d34-c028-4422-ba8d-baa095bfc718">
                    We may provide paid products and/or services within the
                    Service. In that case, we may use third-party services for
                    payment processing (e.g. payment processors).
                </p>
                <p id="9a131edd-397a-4e2d-ba79-6cf7f9684dd8">
                    We will not store or collect Your payment card details. That
                    information is provided directly to Our third-party payment
                    processors whose use of Your personal information is
                    governed by their Privacy Policy. These payment processors
                    adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of
                    brands like Visa, Mastercard, American Express and Discover.
                    PCI-DSS requirements help ensure the secure handling of
                    payment information.
                </p>
                <ul id="2d89343f-b7b3-4aba-a767-8db8f9fde6fc">
                    <li id="e6281986-df2a-40ca-900c-8802888e865f">
                        <p id="58e6d714-b5cf-4869-b2ad-066e8a4768de">
                            <strong id="280e18d1-cc5f-4ced-8cf0-1debe2ee9cf8">
                                Stripe
                            </strong>
                        </p>
                        <p id="25103f21-9f1b-426c-a529-e38cca408ffe">
                            Their Privacy Policy can be viewed at{" "}
                            <a
                                id="47f75694-268c-4629-a722-97f37154ecb4"
                                href="https://stripe.com/us/privacy"
                                rel="external nofollow noopener"
                                target="_blank"
                            >
                                https://stripe.com/us/privacy
                            </a>
                        </p>
                    </li>
                </ul>
                <h4
                    id="d96371a4-b177-4ab2-922f-bed4002876e6"
                    className="mt-4 mb-3"
                >
                    GDPR Privacy
                </h4>
                <h5 id="e628f702-265b-476e-8a8d-d3f37cb85d63">
                    Legal Basis for Processing Personal Data under GDPR
                </h5>
                <p id="687b1665-fbe3-40ac-9775-9001e7ad76b2">
                    We may process Personal Data under the following conditions:
                </p>
                <ul id="58a45ae3-cd0e-42f4-a78f-9bb98e26ecf7">
                    <li id="b0d9f7ae-f400-4192-8923-1f800d2f4cc8">
                        <strong id="6774f62b-78d1-48e8-8cb0-eb2e7179f128">
                            Consent:
                        </strong>{" "}
                        You have given Your consent for processing Personal Data
                        for one or more specific purposes.
                    </li>
                    <li id="c5fb53e4-beea-4f1a-9188-946d1af9ba26">
                        <strong id="53ca8c4d-3e49-4fe7-b253-d39d64d68f36">
                            Performance of a contract:
                        </strong>{" "}
                        Provision of Personal Data is necessary for the
                        performance of an agreement with You and/or for any
                        pre-contractual obligations thereof.
                    </li>
                    <li id="c487d4de-b249-466c-8b98-96f6f8bad881">
                        <strong id="611852e8-5bfd-48ca-937c-b04a9b898448">
                            Legal obligations:
                        </strong>{" "}
                        Processing Personal Data is necessary for compliance
                        with a legal obligation to which the Company is subject.
                    </li>
                    <li id="c95f68cc-ab74-49db-ae0b-6085e79d8eb7">
                        <strong id="e43ee098-d4bb-4dac-baef-23b4aaa96a23">
                            Vital interests:
                        </strong>{" "}
                        Processing Personal Data is necessary in order to
                        protect Your vital interests or of another natural
                        person.
                    </li>
                    <li id="3261ab62-9bcd-4850-8d0c-6d8b0de80c56">
                        <strong id="01901175-0f30-4356-9175-8fce61312b3c">
                            Public interests:
                        </strong>{" "}
                        Processing Personal Data is related to a task that is
                        carried out in the public interest or in the exercise of
                        official authority vested in the Company.
                    </li>
                    <li id="a03c8074-a4d6-4f48-8d54-cb85cb2a7c30">
                        <strong id="5307aeca-ffda-466f-8cfa-49406a3ec61c">
                            Legitimate interests:
                        </strong>{" "}
                        Processing Personal Data is necessary for the purposes
                        of the legitimate interests pursued by the Company.
                    </li>
                </ul>
                <p id="1d6ffe1a-989e-42eb-8fcb-1ecf2ad55070">
                    In any case, the Company will gladly help to clarify the
                    specific legal basis that applies to the processing, and in
                    particular whether the provision of Personal Data is a
                    statutory or contractual requirement, or a requirement
                    necessary to enter into a contract.
                </p>
                <h5 id="cc210b5b-10b5-48aa-bc34-3d893d4e4887">
                    Your Rights under the GDPR
                </h5>
                <p id="a1a90eb4-09d0-418c-adfc-ed8fb641eb6c">
                    The Company undertakes to respect the confidentiality of
                    Your Personal Data and to guarantee You can exercise Your
                    rights.
                </p>
                <p id="0f0efe11-d14c-4925-9e94-effa1ecb06eb">
                    You have the right under this Privacy Policy, and by law if
                    You are within the EU, to:
                </p>
                <ul id="44526ca7-caae-4118-95bb-53e7a968e899">
                    <li id="3553c68b-c0e5-4722-adf2-c65e35a2ab3b">
                        <strong id="35622f6b-4c08-4d51-b96d-336aa26482d5">
                            Request access to Your Personal Data.
                        </strong>{" "}
                        The right to access, update or delete the information We
                        have on You. Whenever made possible, you can access,
                        update or request deletion of Your Personal Data
                        directly within Your account settings section. If you
                        are unable to perform these actions yourself, please
                        contact Us to assist You. This also enables You to
                        receive a copy of the Personal Data We hold about You.
                    </li>
                    <li id="7a2a8050-13ab-4290-9056-31c133c52d91">
                        <strong id="14493450-1a5f-476b-a488-a5e22882c756">
                            Request correction of the Personal Data that We hold
                            about You.
                        </strong>{" "}
                        You have the right to have any incomplete or inaccurate
                        information We hold about You corrected.
                    </li>
                    <li id="f7c089d9-db63-4522-bb69-4da530f835e6">
                        <strong id="2f479e2c-c7df-4862-9e47-837af5b67986">
                            Object to processing of Your Personal Data.
                        </strong>{" "}
                        This right exists where We are relying on a legitimate
                        interest as the legal basis for Our processing and there
                        is something about Your particular situation, which
                        makes You want to object to our processing of Your
                        Personal Data on this ground. You also have the right to
                        object where We are processing Your Personal Data for
                        direct marketing purposes.
                    </li>
                    <li id="0ba57d05-49d3-4446-b4a6-133f66bbc4d9">
                        <strong id="97230e3f-df16-4f36-b58c-32f9e830a437">
                            Request erasure of Your Personal Data.
                        </strong>{" "}
                        You have the right to ask Us to delete or remove
                        Personal Data when there is no good reason for Us to
                        continue processing it.
                    </li>
                    <li id="3634ea95-b3e5-495c-adc1-cc1a15864163">
                        <strong id="e0970f9a-b9fa-43ae-becb-8aed4787a0fd">
                            Request the transfer of Your Personal Data.
                        </strong>{" "}
                        We will provide to You, or to a third-party You have
                        chosen, Your Personal Data in a structured, commonly
                        used, machine-readable format. Please note that this
                        right only applies to automated information which You
                        initially provided consent for Us to use or where We
                        used the information to perform a contract with You.
                    </li>
                    <li id="3384b760-6801-480d-bd62-9547ef620640">
                        <strong id="a3395ca6-0aba-40e7-8614-a0185a6d6908">
                            Withdraw Your consent.
                        </strong>{" "}
                        You have the right to withdraw Your consent on using
                        your Personal Data. If You withdraw Your consent, We may
                        not be able to provide You with access to certain
                        specific functionalities of the Service.
                    </li>
                </ul>
                <h5 id="6850ae08-3593-4f35-87db-0a78f0044a42">
                    Exercising of Your GDPR Data Protection Rights
                </h5>
                <p id="2672a9dc-b2bf-4775-907d-413fa2ad1925">
                    You may exercise Your rights of access, rectification,
                    cancellation and opposition by contacting Us. Please note
                    that we may ask You to verify Your identity before
                    responding to such requests. If You make a request, We will
                    try our best to respond to You as soon as possible.
                </p>
                <p id="ae975176-0678-4be9-805c-4012fe2588ec">
                    You have the right to complain to a Data Protection
                    Authority about Our collection and use of Your Personal
                    Data. For more information, if You are in the European
                    Economic Area (EEA), please contact Your local data
                    protection authority in the EEA.
                </p>
                <h4
                    id="fa82b201-0ede-4d8e-831a-ebdd0edbe8a5"
                    className="mt-4 mb-3"
                >
                    Facebook Fan Page
                </h4>
                <h5 id="35807f8f-bd8c-48f9-bf37-6f3c7a967e81">
                    Data Controller for the Facebook Fan Page
                </h5>
                <p id="0a77a189-5cda-4360-a0f4-6644dcecbf26">
                    The Company is the Data Controller of Your Personal Data
                    collected while using the Service. As operator of the
                    Facebook Fan Page{" "}
                    <a
                        id="d50db7d2-189b-4e0d-9102-71c20f7a03b7"
                        href="https://www.facebook.com/ablebees"
                        rel="external nofollow noopener"
                        target="_blank"
                    >
                        https://www.facebook.com/ablebees
                    </a>
                    , the Company and the operator of the social network
                    Facebook are Joint Controllers.
                </p>
                <p id="1582e08b-0637-4774-8378-776f5fe101d0">
                    The Company has entered into agreements with Facebook that
                    define the terms for use of the Facebook Fan Page, among
                    other things. These terms are mostly based on the Facebook
                    Terms of Service:{" "}
                    <a
                        id="74abe000-7a49-40b2-86a4-9bba16a3006c"
                        href="https://www.facebook.com/terms.php"
                        rel="external nofollow noopener"
                        target="_blank"
                    >
                        https://www.facebook.com/terms.php
                    </a>
                </p>
                <p id="b3a04657-715d-48c6-a450-9a23b62b043f">
                    Visit the Facebook Privacy Policy{" "}
                    <a
                        id="1627af0f-0e6f-4d45-9414-55b15c2c52d7"
                        href="https://www.facebook.com/policy.php"
                        rel="external nofollow noopener"
                        target="_blank"
                    >
                        https://www.facebook.com/policy.php
                    </a>{" "}
                    for more information about how Facebook manages Personal
                    data or contact Facebook online, or by mail: Facebook, Inc.
                    ATTN, Privacy Operations, 1601 Willow Road, Menlo Park, CA
                    94025, United States.
                </p>
                <h5 id="1e642370-8e2f-40d0-81d2-cf445e2ee983">
                    Facebook Insights
                </h5>
                <p id="9b035638-63b7-4dca-9276-32a1891c5a3d">
                    We use the Facebook Insights function in connection with the
                    operation of the Facebook Fan Page and on the basis of the
                    GDPR, in order to obtain anonymized statistical data about
                    Our users.
                </p>
                <p id="70d977cf-0c34-4165-a2de-4f90681bf070">
                    For this purpose, Facebook places a Cookie on the device of
                    the user visiting Our Facebook Fan Page. Each Cookie
                    contains a unique identifier code and remains active for a
                    period of two years, except when it is deleted before the
                    end of this period.
                </p>
                <p id="af75f78e-e9f0-425d-b81a-edaad0a5fcda">
                    Facebook receives, records and processes the information
                    stored in the Cookie, especially when the user visits the
                    Facebook services, services that are provided by other
                    members of the Facebook Fan Page and services by other
                    companies that use Facebook services.
                </p>
                <p id="dffdf265-6d0b-407a-ad89-be92eab327b9">
                    For more information on the privacy practices of Facebook,
                    please visit Facebook Privacy Policy here:{" "}
                    <a
                        id="88136443-524f-45a3-974e-8ccf7d12dae0"
                        href="https://www.facebook.com/privacy/explanation"
                        rel="external nofollow noopener"
                        target="_blank"
                    >
                        https://www.facebook.com/privacy/explanation
                    </a>
                </p>
                <h4
                    id="456bb939-ce5b-4960-974d-02bcc61ccad2"
                    className="mt-4 mb-3"
                >
                    CCPA/CPRA Privacy Notice
                </h4>
                <p id="9d8d05a7-576d-47c7-9174-281b10c97776">
                    This privacy notice section for California residents
                    supplements the information contained in Our Privacy Policy
                    and it applies solely to all visitors, users, and others who
                    reside in the State of California.
                </p>
                <h5 id="86dc1ffc-39eb-4294-ad61-a9fcbd4b9c42">
                    Categories of Personal Information Collected
                </h5>
                <p id="563c5f24-8528-4557-87b8-5d61b25e5d7e">
                    We collect information that identifies, relates to,
                    describes, references, is capable of being associated with,
                    or could reasonably be linked, directly or indirectly, with
                    a particular Consumer or Device. The following is a list of
                    categories of personal information which we may collect or
                    may have been collected from California residents within the
                    last twelve (12) months.
                </p>
                <p id="b7d4ac85-e62b-4f0f-820a-0b744053b810">
                    Please note that the categories and examples provided in the
                    list below are those defined in the CCPA/CPRA. This does not
                    mean that all examples of that category of personal
                    information were in fact collected by Us, but reflects our
                    good faith belief to the best of Our knowledge that some of
                    that information from the applicable category may be and may
                    have been collected. For example, certain categories of
                    personal information would only be collected if You provided
                    such personal information directly to Us.
                </p>
                <ul id="12df5c10-90dd-4f01-835d-6390b4552c42">
                    <li id="2ca1ea04-af4e-4f2a-a434-678a5bf97551">
                        <p id="9ec3bc05-3707-4339-9cb0-25e9fdeb9c40">
                            <strong id="6131074b-5f2a-4eea-9305-95556640ab88">
                                Category A: Identifiers.
                            </strong>
                        </p>
                        <p id="ef2468d2-2f29-4357-9093-6e0a23bd064d">
                            Examples: A real name, alias, postal address, unique
                            personal identifier, online identifier, Internet
                            Protocol address, email address, account name,
                            driver's license number, passport number, or other
                            similar identifiers.
                        </p>
                        <p id="5af48424-ff91-4628-80b6-7a22c418678c">
                            Collected: Yes.
                        </p>
                    </li>
                    <li id="8ffe71ff-97b9-40ee-aaaf-b106f3ae472b">
                        <p id="8143c0bf-48af-47e0-803a-f68014cef06f">
                            <strong id="9e691c32-8b60-45d4-9653-68da87688c17">
                                Category B: Personal information categories
                                listed in the California Customer Records
                                statute (Cal. Civ. Code § 1798.80(e)).
                            </strong>
                        </p>
                        <p id="3ba11110-2057-48bd-ba79-695cec8915e5">
                            Examples: A name, signature, Social Security number,
                            physical characteristics or description, address,
                            telephone number, passport number, driver's license
                            or state identification card number, insurance
                            policy number, education, employment, employment
                            history, bank account number, credit card number,
                            debit card number, or any other financial
                            information, medical information, or health
                            insurance information. Some personal information
                            included in this category may overlap with other
                            categories.
                        </p>
                        <p id="cb3a6a29-2d1e-4498-85d9-3e02786ff373">
                            Collected: Yes.
                        </p>
                    </li>
                    <li id="7fbaaa74-9721-4a32-9ffe-366b874b5532">
                        <p id="ae8e8ead-fc74-42d5-a46f-3372f7a942d4">
                            <strong id="8bc75a98-e45c-4736-b43d-0e1419950e4d">
                                Category C: Protected classification
                                characteristics under California or federal law.
                            </strong>
                        </p>
                        <p id="a15649ff-e63c-4f96-b514-ed686e67b890">
                            Examples: Age (40 years or older), race, color,
                            ancestry, national origin, citizenship, religion or
                            creed, marital status, medical condition, physical
                            or mental disability, sex (including gender, gender
                            identity, gender expression, pregnancy or childbirth
                            and related medical conditions), sexual orientation,
                            veteran or military status, genetic information
                            (including familial genetic information).
                        </p>
                        <p id="fb8fd4f2-e3f0-4012-8316-82bcdc26a557">
                            Collected: No.
                        </p>
                    </li>
                    <li id="49e5d53f-e73d-4f8d-a209-3f8dccabffed">
                        <p id="feca7ac3-0401-4aeb-abc6-7d0ec304b795">
                            <strong id="0e959f2f-f54a-46a7-b5e2-a2ba8e695c39">
                                Category D: Commercial information.
                            </strong>
                        </p>
                        <p id="e1669975-1599-4fb1-a52c-c405cdd8f990">
                            Examples: Records and history of products or
                            services purchased or considered.
                        </p>
                        <p id="627ffad4-4722-47e7-931d-5f0c5a45a059">
                            Collected: Yes.
                        </p>
                    </li>
                    <li id="d670b77d-6c06-425f-8507-cf1f85451b8e">
                        <p id="2e97db89-1de6-45c2-bf64-18629632971c">
                            <strong id="c2535416-9c65-44f4-b563-bf92c93a5ea2">
                                Category E: Biometric information.
                            </strong>
                        </p>
                        <p id="55e87eb7-54a7-4ba8-8d2d-033d97c6d6fe">
                            Examples: Genetic, physiological, behavioral, and
                            biological characteristics, or activity patterns
                            used to extract a template or other identifier or
                            identifying information, such as, fingerprints,
                            faceprints, and voiceprints, iris or retina scans,
                            keystroke, gait, or other physical patterns, and
                            sleep, health, or exercise data.
                        </p>
                        <p id="436c9cff-41f8-48e8-b291-b3f7072950ac">
                            Collected: No.
                        </p>
                    </li>
                    <li id="991e0acf-ec3e-41c2-9950-e3bdd2703486">
                        <p id="601f930d-b03d-4136-a93b-1e79f80f744a">
                            <strong id="5425012c-8f21-4727-9ba9-945d2b2e73e7">
                                Category F: Internet or other similar network
                                activity.
                            </strong>
                        </p>
                        <p id="c6d3d311-b4e0-494a-bcdc-c23b3a110828">
                            Examples: Interaction with our Service or
                            advertisement.
                        </p>
                        <p id="3616d80f-76e6-4beb-ab36-b919e5257d2c">
                            Collected: Yes.
                        </p>
                    </li>
                    <li id="e8534850-9f9e-4073-a057-85baa976261e">
                        <p id="018781b8-5166-4f61-ad4a-3e14b3e46f7c">
                            <strong id="738e6a3e-9e3a-42b9-acc6-00e959bcdac4">
                                Category G: Geolocation data.
                            </strong>
                        </p>
                        <p id="6dc36f6f-bcca-4eec-a7dd-37f193591fdb">
                            Examples: Approximate physical location.
                        </p>
                        <p id="3d54e221-2e56-4228-82bd-5b91050936f7">
                            Collected: Yes.
                        </p>
                    </li>
                    <li id="3c51e80b-da28-4ab2-8c6d-0238a86e125c">
                        <p id="98ad0808-00f0-49ae-9fbb-2cc38fce9fd0">
                            <strong id="8634fc7e-043e-41b4-a828-2d7e7763ce99">
                                Category H: Sensory data.
                            </strong>
                        </p>
                        <p id="01134bb4-1af5-4c1d-b95d-edfc22317aab">
                            Examples: Audio, electronic, visual, thermal,
                            olfactory, or similar information.
                        </p>
                        <p id="400ef452-1a94-4ced-978a-7d81c91ac0f8">
                            Collected: No.
                        </p>
                    </li>
                    <li id="87843540-e598-4a63-b931-c3e7e1305c92">
                        <p id="bd207cc3-9b8c-4a8f-9e01-dd16e9297a66">
                            <strong id="99b44de6-5560-446c-b0b3-b79c47de93f5">
                                Category I: Professional or employment-related
                                information.
                            </strong>
                        </p>
                        <p id="7033e51d-3eb3-4b9f-9a4c-b0b901387107">
                            Examples: Current or past job history or performance
                            evaluations.
                        </p>
                        <p id="f61f1486-2777-4019-9669-caea739e84fd">
                            Collected: No.
                        </p>
                    </li>
                    <li id="48afa843-8d46-48c3-bdca-b6174b4aceaa">
                        <p id="d3557202-a9ec-4a92-ac7c-b4f6004f2c51">
                            <strong id="72228610-971b-4112-b5d4-57e552e302f8">
                                Category J: Non-public education information
                                (per the Family Educational Rights and Privacy
                                Act (20 U.S.C. Section 1232g, 34 C.F.R. Part
                                99)).
                            </strong>
                        </p>
                        <p id="822e7a62-4d57-49f7-9d75-fbf15defce08">
                            Examples: Education records directly related to a
                            student maintained by an educational institution or
                            party acting on its behalf, such as grades,
                            transcripts, class lists, student schedules, student
                            identification codes, student financial information,
                            or student disciplinary records.
                        </p>
                        <p id="0b99093f-a08e-44b6-9d43-5f9e2150fd62">
                            Collected: No.
                        </p>
                    </li>
                    <li id="d175c374-e03c-4bbf-ba1f-538ef01a1f46">
                        <p id="d4a34009-99a0-4ee8-99ca-ec9984c25e6f">
                            <strong id="2e621402-9480-4c17-ac19-c87ce65dc55c">
                                Category K: Inferences drawn from other personal
                                information.
                            </strong>
                        </p>
                        <p id="254df2fd-1f4f-48a5-9d55-bc4bcfe1b042">
                            Examples: Profile reflecting a person's preferences,
                            characteristics, psychological trends,
                            predispositions, behavior, attitudes, intelligence,
                            abilities, and aptitudes.
                        </p>
                        <p id="a5d67600-c4d5-4a43-bbc9-cc1a156fbda8">
                            Collected: No.
                        </p>
                    </li>
                    <li id="55f73680-9c90-46ad-9da2-f0fd337ff1a7">
                        <p id="21ae3353-be4e-4f63-8208-9eb2fc70b433">
                            <strong id="ba05286b-b61d-4032-82c1-bde1796695ac">
                                Category L: Sensitive personal information.
                            </strong>
                        </p>
                        <p id="a94dc2f3-bb04-4cd7-a108-b1ab29cc5bb2">
                            Examples: Account login and password information,
                            geolocation data.
                        </p>
                        <p id="b4b5cba3-6ef7-425f-aad2-1457fbdafa80">
                            Collected: Yes.
                        </p>
                    </li>
                </ul>
                <p id="6933bcde-84b2-4f87-995e-255310b0e0b4">
                    Under CCPA/CPRA, personal information does not include:
                </p>
                <ul id="99e01868-1031-44d9-96c8-cb000d891b7e">
                    <li id="c77d215b-a889-406a-98ca-d39fc76a6d21">
                        Publicly available information from government records
                    </li>
                    <li id="5f40abf0-99e8-4b6b-9b79-21d997604009">
                        Deidentified or aggregated consumer information
                    </li>
                    <li id="31341b98-c27d-4b65-aa5c-15a3cb72d1ac">
                        Information excluded from the CCPA/CPRA's scope, such
                        as:
                        <ul id="8040d927-947e-435c-921e-57c096696ed7">
                            <li id="fbd28219-b67a-4f0f-97a4-8b46ced7c5cc">
                                Health or medical information covered by the
                                Health Insurance Portability and Accountability
                                Act of 1996 (HIPAA) and the California
                                Confidentiality of Medical Information Act
                                (CMIA) or clinical trial data
                            </li>
                            <li id="6537d67e-34a4-4fed-9d90-5368216caec4">
                                Personal Information covered by certain
                                sector-specific privacy laws, including the Fair
                                Credit Reporting Act (FRCA), the
                                Gramm-Leach-Bliley Act (GLBA) or California
                                Financial Information Privacy Act (FIPA), and
                                the Driver's Privacy Protection Act of 1994
                            </li>
                        </ul>
                    </li>
                </ul>
                <h5 id="9a891fee-23a3-4fb5-baeb-bba42eb242da">
                    Sources of Personal Information
                </h5>
                <p id="b077af21-0539-4e74-9a92-15b2ccb3d9f8">
                    We obtain the categories of personal information listed
                    above from the following categories of sources:
                </p>
                <ul id="0cdba655-d4f7-4b89-9bc8-6423737e4638">
                    <li id="46dbd4de-236d-4f66-9efe-5cb8b01d77e2">
                        <strong id="8fbff61b-ee28-4512-961d-d1ec5433a4a7">
                            Directly from You
                        </strong>
                        . For example, from the forms You complete on our
                        Service, preferences You express or provide through our
                        Service, or from Your purchases on our Service.
                    </li>
                    <li id="f06bb6c4-f0b3-4f00-8ea8-da5264677de3">
                        <strong id="34903e26-32b1-41af-aaf5-3db96c8b304b">
                            Indirectly from You
                        </strong>
                        . For example, from observing Your activity on our
                        Service.
                    </li>
                    <li id="8984bcf9-cb60-4130-afb8-ad31934efb28">
                        <strong id="1d9cdc63-7f4a-43e3-9d80-d154c0373c4f">
                            Automatically from You
                        </strong>
                        . For example, through cookies We or our Service
                        Providers set on Your Device as You navigate through our
                        Service.
                    </li>
                    <li id="1ed812b5-6f52-405b-8189-9c2a49de18be">
                        <strong id="ab89c8b0-154a-4568-b316-38a3ea0dc10b">
                            From Service Providers
                        </strong>
                        . For example, third-party vendors to monitor and
                        analyze the use of our Service, third-party vendors for
                        payment processing, or other third-party vendors that We
                        use to provide the Service to You.
                    </li>
                </ul>
                <h5 id="33a0f448-49ba-4b30-ac4e-9b83aee9d802">
                    Use of Personal Information
                </h5>
                <p id="118ab890-85f1-4542-9111-63d70f8c4edc">
                    We may use or disclose personal information We collect for
                    &quot;business purposes&quot; or &quot;commercial
                    purposes&quot; (as defined under the CCPA/CPRA), which may
                    include the following examples:
                </p>
                <ul id="acf6ca6f-8c3d-4ce1-b857-b7ad4911b2cf">
                    <li id="9a3f72b9-1624-4b10-9f37-0752590d0905">
                        To operate our Service and provide You with Our Service.
                    </li>
                    <li id="c44e0f62-9c55-4dae-bf3c-db252fb993c2">
                        To provide You with support and to respond to Your
                        inquiries, including to investigate and address Your
                        concerns and monitor and improve our Service.
                    </li>
                    <li id="81289b8d-34a6-429e-963b-d7c9a98d05f6">
                        To fulfill or meet the reason You provided the
                        information. For example, if You share Your contact
                        information to ask a question about our Service, We will
                        use that personal information to respond to Your
                        inquiry. If You provide Your personal information to
                        purchase a product or service, We will use that
                        information to process Your payment and facilitate
                        delivery.
                    </li>
                    <li id="2c1e8d88-277d-402d-babd-380f28e415e1">
                        To respond to law enforcement requests and as required
                        by applicable law, court order, or governmental
                        regulations.
                    </li>
                    <li id="eaf32141-79f6-457b-a0cb-b84d086a03d4">
                        As described to You when collecting Your personal
                        information or as otherwise set forth in the CCPA/CPRA.
                    </li>
                    <li id="b21540c8-d67e-428d-8e96-f191a41b0bac">
                        For internal administrative and auditing purposes.
                    </li>
                    <li id="8bc97312-d5ab-410f-bc34-45253c46bcb0">
                        To detect security incidents and protect against
                        malicious, deceptive, fraudulent or illegal activity,
                        including, when necessary, to prosecute those
                        responsible for such activities.
                    </li>
                    <li id="07f955e6-a33c-417b-9454-beea28842e61">
                        Other one-time uses.
                    </li>
                </ul>
                <p id="83b6337a-ba11-4388-9c9f-6e9bb9a3ff27">
                    Please note that the examples provided above are
                    illustrative and not intended to be exhaustive. For more
                    details on how we use this information, please refer to the
                    &quot;Use of Your Personal Data&quot; section.
                </p>
                <p id="e8fb74de-8fb2-4497-a153-947e5accac49">
                    If We decide to collect additional categories of personal
                    information or use the personal information We collected for
                    materially different, unrelated, or incompatible purposes We
                    will update this Privacy Policy.
                </p>
                <h5 id="4155eb75-569b-4e29-b383-1dda1616f522">
                    Disclosure of Personal Information
                </h5>
                <p id="6a6546c5-9ca8-4f2d-9c46-6ce40b1a4eb5">
                    We may use or disclose and may have used or disclosed in the
                    last twelve (12) months the following categories of personal
                    information for business or commercial purposes:
                </p>
                <ul id="c8e7b06e-d70e-4335-bd44-c19ac8376659">
                    <li id="feec8cec-0ef5-4556-9dd9-d7ef613d9e91">
                        Category A: Identifiers
                    </li>
                    <li id="c02ab302-f3dc-4451-899e-924454adc424">
                        Category B: Personal information categories listed in
                        the California Customer Records statute (Cal. Civ. Code
                        § 1798.80(e))
                    </li>
                    <li id="97896569-0e77-413b-a657-5053da61c014">
                        Category D: Commercial information
                    </li>
                    <li id="077964dd-ebe0-46ba-9f86-0ea8c027d42a">
                        Category F: Internet or other similar network activity
                    </li>
                    <li id="37b57d63-8208-4ed0-b94d-36ab8cb9fe37">
                        Category G: Geolocation data
                    </li>
                    <li id="aa66f164-ebc4-4253-81fd-b54b7d09f704">
                        Category L: Sensitive personal information
                    </li>
                </ul>
                <p id="dbae2d89-31ad-4e35-baeb-834fb0dde952">
                    Please note that the categories listed above are those
                    defined in the CCPA/CPRA. This does not mean that all
                    examples of that category of personal information were in
                    fact disclosed, but reflects our good faith belief to the
                    best of our knowledge that some of that information from the
                    applicable category may be and may have been disclosed.
                </p>
                <p id="f4e00ba8-b697-46b2-b874-fecc553f569b">
                    When We disclose personal information for a business purpose
                    or a commercial purpose, We enter a contract that describes
                    the purpose and requires the recipient to both keep that
                    personal information confidential and not use it for any
                    purpose except performing the contract.
                </p>
                <h5 id="b53c6a77-e821-40e5-9442-7d6f954262cb">
                    Share of Personal Information
                </h5>
                <p id="a3d2d495-60de-4f28-beb0-71c3cbbaebb7">
                    We may share, and have shared in the last twelve (12)
                    months, Your personal information identified in the above
                    categories with the following categories of third parties:
                </p>
                <ul id="1226a92e-9f58-4a8f-b0e2-0d3572022193">
                    <li id="9d5045cb-1394-414b-9f38-11849924264b">
                        Service Providers
                    </li>
                    <li id="99564b78-df7d-440a-a4d3-97bf14a7f7b9">
                        Payment processors
                    </li>
                    <li id="5ee1c258-c7eb-47f9-9b67-47e67dd4aa9c">
                        Our affiliates
                    </li>
                    <li id="b2442b73-1d70-474a-a52a-9a2fa3fe013c">
                        Our business partners
                    </li>
                    <li id="268b813b-4174-4e0d-9e1f-eb33325f5fb4">
                        Third party vendors to whom You or Your agents authorize
                        Us to disclose Your personal information in connection
                        with products or services We provide to You
                    </li>
                </ul>
                <h5 id="02334b84-57ca-4913-941b-3d614e42d8cc">
                    Sale of Personal Information
                </h5>
                <p id="2bba58ac-2496-44b4-9bb9-1c2335db3b24">
                    As defined in the CCPA/CPRA, &quot;sell&quot; and
                    &quot;sale&quot; mean selling, renting, releasing,
                    disclosing, disseminating, making available, transferring,
                    or otherwise communicating orally, in writing, or by
                    electronic or other means, a Consumer's personal information
                    by the Business to a third party for valuable consideration.
                    This means that We may have received some kind of benefit in
                    return for sharing personal information, but not necessarily
                    a monetary benefit.
                </p>
                <p id="3d2debb8-3ef8-4950-b0c7-49ef41e207b0">
                    We do not sell personal information as the term sell is
                    commonly understood. We do allow Service Providers to use
                    Your personal information for the business purposes
                    described in Our Privacy Policy, for activities such as
                    advertising, marketing, and analytics, and these may be
                    deemed a sale under CCPA/CPRA.
                </p>
                <p id="d25984d5-02ce-4574-b105-9831c4dacf3e">
                    We may sell and may have sold in the last twelve (12) months
                    the following categories of personal information:
                </p>
                <ul id="78350cb5-dc1c-48ab-928b-2a17ad3b8bb8">
                    <li id="1a8e0e56-1740-441e-ad64-c500839f4d10">
                        Category A: Identifiers
                    </li>
                    <li id="f5e79346-d713-49c0-a7f6-1ae8bafa4f8d">
                        Category B: Personal information categories listed in
                        the California Customer Records statute (Cal. Civ. Code
                        § 1798.80(e))
                    </li>
                    <li id="d7844c3f-0b3e-4979-94bd-8e309f13bbde">
                        Category D: Commercial information
                    </li>
                    <li id="2c48b54f-d3b9-4762-ba7f-bd01cdf88ad4">
                        Category F: Internet or other similar network activity
                    </li>
                    <li id="8867ad90-f0ed-4246-b24e-bbcc935ff62b">
                        Category G: Geolocation data
                    </li>
                    <li id="e1a338ff-a94e-4ff2-860b-ecc0c36723ae">
                        Category L: Sensitive personal information
                    </li>
                </ul>
                <p id="cf985ef0-9053-43c1-9bdd-ef6584070bed">
                    Please note that the categories listed above are those
                    defined in the CCPA/CPRA. This does not mean that all
                    examples of that category of personal information were in
                    fact sold, but reflects our good faith belief to the best of
                    Our knowledge that some of that information from the
                    applicable category may be and may have been shared for
                    value in return.
                </p>
                <h5 id="2dfbd3c7-c2d8-46a6-b317-d6efdfaf4d53">
                    Sale of Personal Information of Minors Under 16 Years of Age
                </h5>
                <p id="879ba2ba-ef4a-4bfb-a476-827312aeb238">
                    We do not knowingly collect personal information from minors
                    under the age of 16 through our Service, although certain
                    third party websites that we link to may do so. These
                    third-party websites have their own terms of use and privacy
                    policies and We encourage parents and legal guardians to
                    monitor their children's Internet usage and instruct their
                    children to never provide information on other websites
                    without their permission.
                </p>
                <p id="d1454411-31bc-47df-aa12-1bab4fc68bbd">
                    We do not sell the personal information of Consumers We
                    actually know are less than 16 years of age, unless We
                    receive affirmative authorization (the &quot;right to
                    opt-in&quot;) from either the Consumer who is between 13 and
                    16 years of age, or the parent or guardian of a Consumer
                    less than 13 years of age. Consumers who opt-in to the sale
                    of personal information may opt-out of future sales at any
                    time. To exercise the right to opt-out, You (or Your
                    authorized representative) may submit a request to Us by
                    contacting Us.
                </p>
                <p id="95d5f0b8-a9df-4dfb-8188-700303bc226e">
                    If You have reason to believe that a child under the age of
                    13 (or 16) has provided Us with personal information, please
                    contact Us with sufficient detail to enable Us to delete
                    that information.
                </p>
                <h5 id="55db8277-a4cb-4415-b467-42f9d8156dde">
                    Your Rights under the CCPA/CPRA
                </h5>
                <p id="4ed3124d-29d1-446a-8025-227a6c0f8f39">
                    The CCPA/CPRA provides California residents with specific
                    rights regarding their personal information. If You are a
                    resident of California, You have the following rights:
                </p>
                <ul id="176e0346-dcd4-42d6-83d5-92dbe8bbb48a">
                    <li id="51f392b1-a2a4-4078-8ef5-1b03c3ef4e33">
                        <strong id="de6bfd56-4dc9-4561-998c-00a6b710fc39">
                            The right to notice.
                        </strong>{" "}
                        You have the right to be notified which categories of
                        Personal Data are being collected and the purposes for
                        which the Personal Data is being used.
                    </li>
                    <li id="553d7616-71a1-4ecd-9e93-336e2f995cde">
                        <strong id="2cc62908-e232-4104-9407-1b82c0eb0a50">
                            The right to know/access.
                        </strong>{" "}
                        Under CCPA/CPRA, You have the right to request that We
                        disclose information to You about Our collection, use,
                        sale, disclosure for business purposes and share of
                        personal information. Once We receive and confirm Your
                        request, We will disclose to You:
                        <ul id="730805a1-361e-4944-af1b-e3c804ad2277">
                            <li id="8580502a-0abb-423e-b992-35c64eb2da99">
                                The categories of personal information We
                                collected about You
                            </li>
                            <li id="4361c760-5310-4136-8c85-4db4573e5fdb">
                                The categories of sources for the personal
                                information We collected about You
                            </li>
                            <li id="b204ed9d-124e-49e5-9c63-9d2e7e7919cc">
                                Our business or commercial purposes for
                                collecting or selling that personal information
                            </li>
                            <li id="43a96f38-b2a8-4191-84b2-cca6ffc86247">
                                The categories of third parties with whom We
                                share that personal information
                            </li>
                            <li id="35dabd52-ccda-4579-8237-5fa0acc72a32">
                                The specific pieces of personal information We
                                collected about You
                            </li>
                            <li id="922ec264-48b0-46fd-8f31-9d862c64e515">
                                If we sold Your personal information or
                                disclosed Your personal information for a
                                business purpose, We will disclose to You:
                                <ul id="eaff8fc8-924a-4e4b-ab72-eaa7d048251f">
                                    <li id="ba23d01f-0687-49e6-9120-33b15658a01e">
                                        The categories of personal information
                                        sold
                                    </li>
                                    <li id="4534ebad-7f93-4d21-9241-5eea2cf960ec">
                                        The categories of personal information
                                        disclosed
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li id="88f80e42-402c-4329-93b5-c39f3b5b1d9a">
                        <strong id="7fb0976b-52ca-48bb-8ace-396b6042f6e2">
                            The right to say no to the sale or sharing of
                            Personal Data (opt-out).
                        </strong>{" "}
                        You have the right to direct Us to not sell Your
                        personal information. To submit an opt-out request,
                        please see the &quot;Do Not Sell My Personal
                        Information&quot; section or contact Us.
                    </li>
                    <li id="892b4369-7bae-42f1-b1ed-4ef1e660751f">
                        <strong id="03ef431a-fde2-4442-bbfc-9061e4ae20ff">
                            The right to correct Personal Data.
                        </strong>{" "}
                        You have the right to correct or rectify any inaccurate
                        personal information about You that We collected. Once
                        We receive and confirm Your request, We will use
                        commercially reasonable efforts to correct (and direct
                        our Service Providers to correct) Your personal
                        information, unless an exception applies.
                    </li>
                    <li id="6ed76d74-53e9-42ce-b330-d81a93897fed">
                        <strong id="2654c55e-44c2-4b7e-91e1-b6d6091cf87c">
                            The right to limit use and disclosure of sensitive
                            Personal Data.
                        </strong>{" "}
                        You have the right to request to limit the use or
                        disclosure of certain sensitive personal information We
                        collected about You, unless an exception applies. To
                        submit, please see the &quot;Limit the Use or Disclosure
                        of My Sensitive Personal Information&quot; section or
                        contact Us.
                    </li>
                    <li id="eb45bd63-d843-4399-bd8e-7f8542b9ffbc">
                        <strong id="15ea5a34-36f1-4a49-ac2a-abb1a6e8d2d4">
                            The right to delete Personal Data.
                        </strong>{" "}
                        You have the right to request the deletion of Your
                        Personal Data under certain circumstances, subject to
                        certain exceptions. Once We receive and confirm Your
                        request, We will delete (and direct Our Service
                        Providers to delete) Your personal information from our
                        records, unless an exception applies. We may deny Your
                        deletion request if retaining the information is
                        necessary for Us or Our Service Providers to:
                        <ul id="e0019fbe-f89c-47d4-8701-545709e71c94">
                            <li id="f582a136-82a6-441e-a24d-1f1d25a6e200">
                                Complete the transaction for which We collected
                                the personal information, provide a good or
                                service that You requested, take actions
                                reasonably anticipated within the context of our
                                ongoing business relationship with You, or
                                otherwise perform our contract with You.
                            </li>
                            <li id="a71d4dd0-759b-401d-a8c7-05e598977e9c">
                                Detect security incidents, protect against
                                malicious, deceptive, fraudulent, or illegal
                                activity, or prosecute those responsible for
                                such activities.
                            </li>
                            <li id="9b820a56-c2c3-4387-a95f-281bde8a1675">
                                Debug products to identify and repair errors
                                that impair existing intended functionality.
                            </li>
                            <li id="d56740e9-325d-4ead-9d1c-67bfa899ec94">
                                Exercise free speech, ensure the right of
                                another consumer to exercise their free speech
                                rights, or exercise another right provided for
                                by law.
                            </li>
                            <li id="3b7ca0d3-4698-4565-9444-5d147d2fe5d2">
                                Comply with the California Electronic
                                Communications Privacy Act (Cal. Penal Code §
                                1546 et. seq.).
                            </li>
                            <li id="960da0cb-e03b-47d8-81cc-731315658b1b">
                                Engage in public or peer-reviewed scientific,
                                historical, or statistical research in the
                                public interest that adheres to all other
                                applicable ethics and privacy laws, when the
                                information's deletion may likely render
                                impossible or seriously impair the research's
                                achievement, if You previously provided informed
                                consent.
                            </li>
                            <li id="3692649d-12ad-49fb-b3a3-37512f525b70">
                                Enable solely internal uses that are reasonably
                                aligned with consumer expectations based on Your
                                relationship with Us.
                            </li>
                            <li id="46341949-752a-4f1c-9eb7-5fa97bc5b5ee">
                                Comply with a legal obligation.
                            </li>
                            <li id="6de12a15-7778-48b9-be58-f8d4d009819a">
                                Make other internal and lawful uses of that
                                information that are compatible with the context
                                in which You provided it.
                            </li>
                        </ul>
                    </li>
                    <li id="50f0e93f-dfd1-4e22-ac25-e0118864f55b">
                        <strong id="b0f40016-88cb-46ad-bbca-bd7bad7245e1">
                            The right not to be discriminated against.
                        </strong>{" "}
                        You have the right not to be discriminated against for
                        exercising any of Your consumer's rights, including by:
                        <ul id="0ac011b8-1b39-49ea-8fb2-b9125d4e429d">
                            <li id="6d1949c6-f76c-4cf4-b666-f58c61bca7c7">
                                Denying goods or services to You
                            </li>
                            <li id="ef0029f3-81a9-44e4-9a1f-4114e8700fba">
                                Charging different prices or rates for goods or
                                services, including the use of discounts or
                                other benefits or imposing penalties
                            </li>
                            <li id="158faeb6-b05f-4f11-97e6-1714352e2d5a">
                                Providing a different level or quality of goods
                                or services to You
                            </li>
                            <li id="a6c07dbb-cc89-4654-bede-72c0ea95bd33">
                                Suggesting that You will receive a different
                                price or rate for goods or services or a
                                different level or quality of goods or services
                            </li>
                        </ul>
                    </li>
                </ul>
                <h5 id="ae513b79-4274-4ba4-a4f0-656d1ccd5596">
                    Exercising Your CCPA/CPRA Data Protection Rights
                </h5>
                <p id="1c39cc2d-7a22-41ed-bd03-ced7a592e005">
                    Please see the &quot;Do Not Sell My Personal
                    Information&quot; section and &quot;Limit the Use or
                    Disclosure of My Sensitive Personal Information&quot;
                    section for more information on how to opt out and limit the
                    use of sensitive information collected.
                </p>
                <p id="c729eec9-8228-4275-b697-4ccf7eef74ff">
                    Additionally, in order to exercise any of Your rights under
                    the CCPA/CPRA, and if You are a California resident, You can
                    contact Us:
                </p>
                <ul id="de0cbd0a-6828-4eaa-a9f5-96208367bb46">
                    <li id="ce3cc5fa-55ac-47f3-b0b5-96dc51361dbf">
                        By email:{" "}
                        <a
                            id="426be72a-5d8d-4603-b1bb-3c35858670bd"
                            href="mailto: support@ablebees.com"
                        >
                            support@ablebees.com
                        </a>
                    </li>
                </ul>
                <p id="6a1893a6-5e35-49a1-8ea3-30d19423b488">
                    Only You, or a person registered with the California
                    Secretary of State that You authorize to act on Your behalf,
                    may make a verifiable request related to Your personal
                    information.
                </p>
                <p id="784af0c7-22ed-4070-837f-2cb8ac8efb1f">
                    Your request to Us must:
                </p>
                <ul id="5b136408-68bc-4b13-90c2-752e8f8b534a">
                    <li id="01d68872-2bbf-465f-9204-a04c8be522e4">
                        Provide sufficient information that allows Us to
                        reasonably verify You are the person about whom We
                        collected personal information or an authorized
                        representative
                    </li>
                    <li id="ad672ca4-dc61-496a-8982-be93889a1756">
                        Describe Your request with sufficient detail that allows
                        Us to properly understand, evaluate, and respond to it
                    </li>
                </ul>
                <p id="d46d5a89-b2b8-4fa6-b62f-d47bd3fc2c11">
                    We cannot respond to Your request or provide You with the
                    required information if We cannot:
                </p>
                <ul id="03d4a5cd-79c9-494d-9923-48edecb7f55a">
                    <li id="e8b547a8-3c68-4cae-b4a8-c7cc6786798d">
                        Verify Your identity or authority to make the request
                    </li>
                    <li id="773c6036-3fcd-4432-890e-953820ef7430">
                        And confirm that the personal information relates to You
                    </li>
                </ul>
                <p id="2eddfa12-c3fa-4dd1-9a07-e3e6b6d5e2a5">
                    We will disclose and deliver the required information free
                    of charge within 45 days of receiving Your verifiable
                    request. The time period to provide the required information
                    may be extended once by an additional 45 days when
                    reasonably necessary and with prior notice.
                </p>
                <p id="6fb4404e-ad58-4fd9-9017-ff294fb7c9c9">
                    Any disclosures We provide will only cover the 12-month
                    period preceding the verifiable request's receipt.
                </p>
                <p id="9055f595-9346-4b65-8771-774797809a9c">
                    For data portability requests, We will select a format to
                    provide Your personal information that is readily usable and
                    should allow You to transmit the information from one entity
                    to another entity without hindrance.
                </p>
                <h5 id="4c617b50-d32d-49fa-bc40-0dfab54e226b">
                    Do Not Sell My Personal Information
                </h5>
                <p id="f9ce06dc-94ec-4861-9143-9f4af703b864">
                    As defined in the CCPA/CPRA, &quot;sell&quot; and
                    &quot;sale&quot; mean selling, renting, releasing,
                    disclosing, disseminating, making available, transferring,
                    or otherwise communicating orally, in writing, or by
                    electronic or other means, a Consumer's personal information
                    by the Business to a third party for valuable consideration.
                    This means that We may have received some kind of benefit in
                    return for sharing personal information, but not necessarily
                    a monetary benefit.
                </p>
                <p id="f1cd0146-5f47-49ba-bb17-e269b90ae65f">
                    We do not sell personal information as the term sell is
                    commonly understood. We do allow Service Providers to use
                    Your personal information for the business purposes
                    described in Our Privacy Policy, for activities such as
                    advertising, marketing, and analytics, and these may be
                    deemed a sale under CCPA/CPRA.
                </p>
                <p id="7cf94400-101c-43ec-9189-682fdff786b7">
                    You have the right to opt-out of the sale of Your personal
                    information. Once We receive and confirm a verifiable
                    consumer request from You, we will stop selling Your
                    personal information. To exercise Your right to opt-out,
                    please contact Us.
                </p>
                <p id="e14a799e-8c34-4566-8982-1b3b1c326660">
                    The Service Providers we partner with (for example, our
                    analytics or advertising partners) may use technology on the
                    Service that sells personal information as defined by the
                    CCPA/CPRA law. If you wish to opt out of the use of Your
                    personal information for interest-based advertising purposes
                    and these potential sales as defined under CCPA/CPRA law,
                    you may do so by following the instructions below.
                </p>
                <p id="26276e4e-cf28-44ba-a20c-1a316e0aa0b5">
                    Please note that any opt out is specific to the browser You
                    use. You may need to opt out on every browser that You use.
                </p>
                <h4
                    id="df881cd1-8858-4b4b-99e6-53ec0bf11d52"
                    className="mt-4 mb-3"
                >
                    Website
                </h4>
                <p id="47137e8c-d957-41b8-88c7-0b579e25eab6">
                    If applicable, click &quot;Privacy Preferences&quot;,
                    &quot;Update Privacy Preferences&quot; or &quot;Do Not Sell
                    My Personal Information&quot; buttons listed on the Service
                    to review your privacy preferences and opt out of cookies
                    and other technologies that We may use. Please note that You
                    will need to opt out from each browser that You use to
                    access the Service.
                </p>
                <p id="fffd4ff8-899f-4701-a144-c855ee473b77">
                    Additionally, You can opt out of receiving ads that are
                    personalized as served by our Service Providers by following
                    our instructions presented on the Service:
                </p>
                <ul id="c63b4fe1-40f9-4d0a-aaef-d48ba3110089">
                    <li id="5082e02c-369c-45ff-adce-9a73d5598272">
                        The NAI's opt-out platform:{" "}
                        <a
                            id="5d35b797-bd10-4a08-90c7-ffb235db18da"
                            href="http://www.networkadvertising.org/choices/"
                            rel="external nofollow noopener"
                            target="_blank"
                        >
                            http://www.networkadvertising.org/choices/
                        </a>
                    </li>
                    <li id="24f32582-1abf-4605-b762-4d3ed540c98c">
                        The EDAA's opt-out platform{" "}
                        <a
                            id="575a6823-8a06-4a1b-a394-192fca8b66e6"
                            href="http://www.youronlinechoices.com/"
                            rel="external nofollow noopener"
                            target="_blank"
                        >
                            http://www.youronlinechoices.com/
                        </a>
                    </li>
                    <li id="cc641a2c-1a50-41a3-b948-45a80beb3ebe">
                        The DAA's opt-out platform:{" "}
                        <a
                            id="1db58141-056b-480c-b508-ee388c84d9ea"
                            href="http://optout.aboutads.info/?c=2&amp;lang=EN"
                            rel="external nofollow noopener"
                            target="_blank"
                        >
                            http://optout.aboutads.info/?c=2&amp;lang=EN
                        </a>
                    </li>
                </ul>
                <p id="3eefd6f6-cfd6-4337-acb5-e6c7245d0510">
                    The opt out will place a cookie on Your computer that is
                    unique to the browser You use to opt out. If you change
                    browsers or delete the cookies saved by your browser, You
                    will need to opt out again.
                </p>
                <h4
                    id="105aa44b-07d6-4be3-9fa6-7baa6e7d63c2"
                    className="mt-4 mb-3"
                >
                    Mobile Devices
                </h4>
                <p id="5d9ede87-d064-4ac6-aba8-11bbc53d46cd">
                    Your mobile device may give You the ability to opt out of
                    the use of information about the apps You use in order to
                    serve You ads that are targeted to Your interests:
                </p>
                <ul id="160a7185-7086-40a8-ac8d-6f24857d0f96">
                    <li id="1e4620f3-fe86-445c-8ad2-e03d7859672d">
                        &quot;Opt out of Interest-Based Ads&quot; or &quot;Opt
                        out of Ads Personalization&quot; on Android devices
                    </li>
                    <li id="8d3b5943-772f-4ca0-af6e-47ccaa0b18b1">
                        &quot;Limit Ad Tracking&quot; on iOS devices
                    </li>
                </ul>
                <p id="c592346f-2e3a-491c-8d93-a277b87565ce">
                    You can also stop the collection of location information
                    from Your mobile device by changing the preferences on Your
                    mobile device.
                </p>
                <h5 id="e3074aed-795a-4c6a-8926-9f88aee46f89">
                    Limit the Use or Disclosure of My Sensitive Personal
                    Information
                </h5>
                <p id="5eedae6e-cd8c-4330-b9c3-ea30e552cbd8">
                    If You are a California resident, You have the right to
                    limit the use and disclosure of Your sensitive personal
                    information to that use which is necessary to perform the
                    services or provide the goods reasonably expected by an
                    average Consumer who requests such services or goods.
                </p>
                <p id="cb761fb4-31cd-4b68-ab16-7c15e9492f2a">
                    We collect, use and disclose sensitive personal information
                    in ways that are necessary to provide the Service. For more
                    information on how We use Your personal information, please
                    see the &quot;Use of Your Personal Data&quot; section or
                    contact us.
                </p>
                <h4
                    id="f913fd07-81dd-46a6-bbb8-5ce62bfb4dde"
                    className="mt-4 mb-3"
                >
                    &quot;Do Not Track&quot; Policy as Required by California
                    Online Privacy Protection Act (CalOPPA)
                </h4>
                <p id="4356aa1a-0ea7-438e-a25b-adbec073ddcc">
                    Our Service does not respond to Do Not Track signals.
                </p>
                <p id="9a35f83d-654e-49a8-b9ae-63a40d61c2c2">
                    However, some third party websites do keep track of Your
                    browsing activities. If You are visiting such websites, You
                    can set Your preferences in Your web browser to inform
                    websites that You do not want to be tracked. You can enable
                    or disable DNT by visiting the preferences or settings page
                    of Your web browser.
                </p>
                <h4
                    id="6c1fff9c-bfc6-4b71-a3bb-162a184b8da5"
                    className="mt-4 mb-3"
                >
                    Your California Privacy Rights (California's Shine the Light
                    law)
                </h4>
                <p id="ddc5f474-2f0b-4a4e-9e8f-ecf6f3583a50">
                    Under California Civil Code Section 1798 (California's Shine
                    the Light law), California residents with an established
                    business relationship with us can request information once a
                    year about sharing their Personal Data with third parties
                    for the third parties' direct marketing purposes.
                </p>
                <p id="f4dc2760-aa0b-47ef-bcf7-25b994e970d2">
                    If you'd like to request more information under the
                    California Shine the Light law, and if You are a California
                    resident, You can contact Us using the contact information
                    provided below.
                </p>
                <h4
                    id="4fe1835d-f141-4728-93e1-3ebc3cbb0e14"
                    className="mt-4 mb-3"
                >
                    California Privacy Rights for Minor Users (California
                    Business and Professions Code Section 22581)
                </h4>
                <p id="244d25e4-e7d3-4692-bb76-4e737b184fd8">
                    California Business and Professions Code Section 22581
                    allows California residents under the age of 18 who are
                    registered users of online sites, services or applications
                    to request and obtain removal of content or information they
                    have publicly posted.
                </p>
                <p id="ee5bca92-2a09-4d78-89f8-271a2e13b42d">
                    To request removal of such data, and if You are a California
                    resident, You can contact Us using the contact information
                    provided below, and include the email address associated
                    with Your account.
                </p>
                <p id="fefa6f08-5eca-4b34-aa5c-84c96a95a6ce">
                    Be aware that Your request does not guarantee complete or
                    comprehensive removal of content or information posted
                    online and that the law may not permit or require removal in
                    certain circumstances.
                </p>
                <h4
                    id="1d592645-0e75-46c5-a5eb-d525e2672a8b"
                    className="mt-4 mb-3"
                >
                    Children's Privacy
                </h4>
                <p id="e742506f-2157-410a-b375-e1a73fb1e86c">
                    Our Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                </p>
                <p id="08eca826-7ec8-4343-abb6-301dce934757">
                    If We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent's consent
                    before We collect and use that information.
                </p>
                <h4
                    id="904a96e5-6584-4769-b3a5-6c8999cf93a5"
                    className="mt-4 mb-3"
                >
                    Links to Other Websites
                </h4>
                <p id="4e8dfe1d-3350-4fe6-8115-1d1ee09bb522">
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                </p>
                <p id="e2cd4c2a-7054-49e3-b0a8-3987fcc8420c">
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                </p>
                <h4
                    id="93851662-e171-4598-8509-c7cf99b8515d"
                    className="mt-4 mb-3"
                >
                    Changes to this Privacy Policy
                </h4>
                <p id="574af2ea-430c-4c04-b609-f5fcb8d8bae9">
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                </p>
                <p id="ffd77034-6f92-48c3-be32-1b9789f635bb">
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the &quot;Last updated&quot; date at the top of this
                    Privacy Policy.
                </p>
                <p id="3e100574-bfcc-4fa3-94a7-563c298efafb">
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                </p>
                <h4
                    id="95278eee-0555-4150-abc4-50bfb6a776cb"
                    className="mt-4 mb-3"
                >
                    Contact Us
                </h4>
                <p id="dd4afd5f-6147-4ff1-936b-141490e4e2e5">
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                </p>
                <ul id="9e8417b6-dea5-4afc-9b0e-0fad58b299f5">
                    <li id="c0effcf0-41c0-4b9e-a549-b7b7c0e87788">
                        By email:{" "}
                        <a
                            id="1a1486f5-e44c-4948-9e8f-cf3b34750632"
                            href="mailto: support@ablebees.com"
                        >
                            support@ablebees.com
                        </a>
                    </li>
                </ul>
            </div>
            <Footer id="af2ea8d8-7a9b-4b26-b219-bc4ea81b4514" />
        </>
    );
};

export default PrivacyPage;
