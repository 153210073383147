import { Link } from "react-router-dom";
import User from "../../models/User";
import UserBasicInfo from "../UserBasicInfo/UserBasicInfo";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<any> {
    user: User;
    className?: string;
}
const ProfileLink = ({ user, className, id }: Props) => {
    return (
        <Link
            // id={`profile-link-to-users-${user.username}`}
            id={id}
            to={`/${user.username}`}
            className={`text-decoration-none text-dark d-flex align-items-center ${className}`}
        >
            <UserBasicInfo
                id="3ad11443-d46e-436c-b8de-799c66dceee8"
                user={user}
            />
        </Link>
    );
};

export default ProfileLink;
