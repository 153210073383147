import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import Referrer from "../../models/Referrer";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export const getReferrer = async (): Promise<Referrer> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`/referral-program/referrers/self`, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
        });

        return new Referrer(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
