import { Link } from "react-router-dom";
import { ProfilePagePetitionsTab } from "../ProfilePagePetitions";
import { useTranslation } from "react-i18next";
import { HTMLAttributes } from "react";
import User from "../../../../models/User";

interface Props extends HTMLAttributes<any> {
    activeTab: ProfilePagePetitionsTab;
    user: User;
}

const ProfilePagePetitionsNavbar = ({ id, activeTab, user }: Props) => {
    const { t } = useTranslation();
    const supportedTab = (
        <li id="2bc4f86a-43a1-4449-b0d0-67f81e07cf8b" className="nav-item">
            <Link
                // id="profile-page-petitions-navbar-to-supported"
                id="4a43a007-770a-4c88-bcdf-14ddbfd3af69"
                className={`nav-link ${
                    activeTab === ProfilePagePetitionsTab.SUPPORTED && "active"
                }`}
                to={ProfilePagePetitionsTab.SUPPORTED}
            >
                {t("profilePage.header.supported")}
            </Link>
        </li>
    );

    const receivedTab = (
        <li id="7a55126c-03a7-4502-90f9-a7a962f2d764" className="nav-item">
            <Link
                // id="profile-page-petitions-navbar-to-received"
                id="8c99252b-7cc1-4795-b2c4-b6ad2c2390fa"
                className={`nav-link ${
                    activeTab === ProfilePagePetitionsTab.RECEIVED && "active"
                }`}
                to={ProfilePagePetitionsTab.RECEIVED}
            >
                {t("profilePage.header.received")}
            </Link>
        </li>
    );

    const startedTab = (
        <li id="5cc5c8f1-afef-46e3-89c7-507255da829e" className="nav-item">
            <Link
                // id="profile-page-petitions-navbar-to-started"
                id="2665736f-762a-48dc-8dc4-b5b521b7c942"
                className={`nav-link ${
                    activeTab === ProfilePagePetitionsTab.STARTED && "active"
                }`}
                to={ProfilePagePetitionsTab.STARTED}
            >
                {t("profilePage.header.started")}
            </Link>
        </li>
    );

    const groupsTab = (
        <li id="eb6b909a-582d-4237-8a53-c969f6467ee4" className="nav-item">
            <Link
                id="d670a519-3ea4-4b3e-987e-46b145f43a20"
                className={`nav-link ${
                    activeTab === ProfilePagePetitionsTab.GROUPS && "active"
                }`}
                to={ProfilePagePetitionsTab.GROUPS}
            >
                {t("profilePage.header.groups")}
            </Link>
        </li>
    );
    return (
        <ul id={id} className="nav nav-underline py-3">
            {user.is_creator && receivedTab}
            {supportedTab}
            {startedTab}
            {user.is_creator && user.is_myself && groupsTab}
        </ul>
    );
};

export default ProfilePagePetitionsNavbar;
