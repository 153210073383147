import { Link, useMatch } from "react-router-dom";
import { SettingsPageTab } from "../SettingsPage";
import { HTMLAttributes, useContext } from "react";
import authContext from "../../../contexts/auth-context";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {}

const SettingsPageNavbar = ({ id }: Props) => {
    const { t } = useTranslation();
    const { user } = useContext(authContext);
    const match = useMatch("/settings/:tab");
    const activeTab = match?.params.tab;
    return (
        <ul id={id} className="nav nav-underline">
            <li id="cb2806fa-5a84-47c4-955e-060c69be9ddb" className="nav-item">
                <Link
                    // id="settings-page-navbar-to-profile"
                    id="f2ff0987-7e9b-4d53-ae58-fd8df1cb58e6"
                    className={`nav-link ${
                        activeTab === SettingsPageTab.PROFILE && "active"
                    }`}
                    to={SettingsPageTab.PROFILE}
                >
                    {t("settingsPage.navbar.profile")}
                </Link>
            </li>
            <li id="69af1467-09ef-48ad-b51f-d6b0168c2550" className="nav-item">
                <Link
                    // id="settings-page-navbar-to-account"
                    id="772717e0-57ed-4a1a-9d9c-70d0c7af3e09"
                    className={`nav-link ${
                        activeTab === SettingsPageTab.ACCOUNT && "active"
                    }`}
                    to={SettingsPageTab.ACCOUNT}
                >
                    {t("settingsPage.navbar.account")}
                </Link>
            </li>
            <li
                id="3e1c6eb1-aae5-43c3-b4ec-3cf4f93610c5"
                className="nav-item d-none d-lg-block"
            >
                <Link
                    // id="settings-page-navbar-to-payment-methods"
                    id="57a94e03-84e7-419b-9ebe-086439f3da65"
                    className={`nav-link ${
                        activeTab === SettingsPageTab.PAYMENT_METHODS &&
                        "active"
                    }`}
                    to={SettingsPageTab.PAYMENT_METHODS}
                >
                    {t("settingsPage.navbar.paymentMethods")}
                </Link>
            </li>
            <li
                id="23b7df57-5a5d-475c-a94f-33162eec3419"
                className="nav-item d-none d-lg-block"
            >
                <Link
                    // id="settings-page-navbar-to-billing"
                    id="92394a25-742c-48e0-9362-cf49ad51c150"
                    className={`nav-link ${
                        activeTab === SettingsPageTab.BILLING && "active"
                    }`}
                    to={SettingsPageTab.BILLING}
                >
                    {t("settingsPage.navbar.billing")}
                </Link>
            </li>
            {(user?.is_creator || user?.can_join_referral_program) && (
                <li
                    id="9ed81a3b-7c86-4975-9204-53bc451b28cf"
                    className="nav-item d-none d-lg-block"
                >
                    <Link
                        // id="settings-page-navbar-to-payout"
                        id="6e125b23-9648-4d2b-b730-4474a2ba8554"
                        className={`nav-link ${
                            activeTab === SettingsPageTab.PAYOUT && "active"
                        }`}
                        to={SettingsPageTab.PAYOUT}
                    >
                        {t("settingsPage.navbar.payout")}
                    </Link>
                </li>
            )}
            {/*<li className="nav-item d-none d-lg-block">*/}
            {/*    <Link*/}
            {/*        id="settings-page-navbar-to-notifications"*/}
            {/*        className={`nav-link ${*/}
            {/*            activeTab === SettingsPageTab.NOTIFICATIONS && "active"*/}
            {/*        }`}*/}
            {/*        to={SettingsPageTab.NOTIFICATIONS}*/}
            {/*    >*/}
            {/*        Notifications*/}
            {/*    </Link>*/}
            {/*</li>*/}
            <li
                id="86a35fcd-d016-455f-9636-d42e0b03f017"
                className="nav-item dropdown d-lg-none"
            >
                <button
                    id="35b2592e-69db-42bd-9a4b-38f3090ed0ca"
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                >
                    {t("settingsPage.navbar.more")}
                </button>
                <ul
                    id="e4748d84-9c1d-4531-b82e-ade575497111"
                    className="dropdown-menu"
                >
                    <li
                        id="1e232ea3-cdbd-4f14-ab26-23eac77b6a24"
                        className="d-lg-none"
                    >
                        <Link
                            // id="settings-page-navbar-to-payment-methods"
                            id="2b666923-8c55-468e-9811-cc6c4b240ce2"
                            className="dropdown-item"
                            to={SettingsPageTab.PAYMENT_METHODS}
                        >
                            {t("settingsPage.navbar.paymentMethods")}
                        </Link>
                    </li>
                    <li
                        id="9e749a51-5657-4784-9fb3-a821022fa58a"
                        className="d-lg-none"
                    >
                        <Link
                            // id="settings-page-navbar-to-billing"
                            id="73e616e5-4923-4c71-9fd8-8538df274b45"
                            className="dropdown-item"
                            to={SettingsPageTab.BILLING}
                        >
                            {t("settingsPage.navbar.billing")}
                        </Link>
                    </li>
                    {(user?.is_creator || user?.can_join_referral_program) && (
                        <li
                            id="c05ae198-a61f-4dba-b08e-debc84f9af21"
                            className="d-lg-none"
                        >
                            <Link
                                // id="settings-page-navbar-to-payout"
                                id="fc262bbe-252f-493e-8075-9db413b0b6f9"
                                className="dropdown-item"
                                to={SettingsPageTab.PAYOUT}
                            >
                                {t("settingsPage.navbar.payout")}
                            </Link>
                        </li>
                    )}
                    {/*<li className="d-lg-none">*/}
                    {/*    <Link*/}
                    {/*        id="settings-page-navbar-to-notifications"*/}
                    {/*        className="dropdown-item"*/}
                    {/*        to={SettingsPageTab.NOTIFICATIONS}*/}
                    {/*    >*/}
                    {/*        Notifications*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
            </li>
        </ul>
    );
};

export default SettingsPageNavbar;
