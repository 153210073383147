import React, {
    ComponentProps,
    HTMLAttributes,
    Ref,
    useCallback,
    useRef,
    useState,
} from "react";
import PetitionGroup from "../../models/PetitionGroup";
import PetitionGroupsListItem from "./PetitionGroupsListItem/PetitionGroupsListItem";

interface Props extends ComponentProps<any>, HTMLAttributes<any> {
    petitionGroups: PetitionGroup[];
    getPetitionGroups: () => void;
    onSelectPetitionGroup?: (petitionGroup: PetitionGroup) => void;
    isLoading: boolean;
}

const PetitionGroupsList = ({
    petitionGroups,
    getPetitionGroups,
    onSelectPetitionGroup,
    isLoading,
    className,
    id,
}: Props) => {
    const [selectedPetitionGroupUUID, setSelectedPetitionGroupUUID] =
        useState<string>();
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getPetitionGroups();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getPetitionGroups]
    ) as Ref<HTMLDivElement>;

    const renderPetitionGroupsList = petitionGroups.map(
        (petitionGroup: PetitionGroup, index: number) => {
            return (
                <PetitionGroupsListItem
                    id={`petition-group-${petitionGroup.uuid}`}
                    key={petitionGroup.uuid}
                    petitionGroup={petitionGroup}
                    ref={
                        petitionGroups.length === index + 1
                            ? lastElementRef
                            : undefined
                    }
                    onSelectPetitionGroup={
                        onSelectPetitionGroup
                            ? () => {
                                  setSelectedPetitionGroupUUID(
                                      petitionGroup.uuid
                                  );
                                  onSelectPetitionGroup(petitionGroup);
                              }
                            : undefined
                    }
                    isSelected={
                        petitionGroup.uuid === selectedPetitionGroupUUID
                    }
                />
            );
        }
    );

    return (
        <div id={id} className={`${className} d-flex flex-column gap-3`}>
            {renderPetitionGroupsList}
        </div>
    );
};

export default PetitionGroupsList;
