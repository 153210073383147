import { MdClear, MdDone, MdDoneAll } from "react-icons/md";
import React, { HTMLAttributes, useContext } from "react";
import AuthContext from "../../../../contexts/auth-context";
import Button from "../../../UI/Button/Button";
import { GoLinkExternal } from "react-icons/go";
import { useTranslation } from "react-i18next";
import PetitionGroup, {
    PetitionGroupStatus,
} from "../../../../models/PetitionGroup";

interface Props extends HTMLAttributes<any> {
    petitionGroup: PetitionGroup;
    onAcceptButtonClick: () => void;
    onRejectButtonClick: () => void;
    onMarkAsFulfilledButtonClick: () => void;
    acceptLoading: boolean;
    rejectLoading: boolean;
    markAsFulfilledLoading: boolean;
}

const PetitionGroupActionButtons = ({
    id,
    petitionGroup,
    onAcceptButtonClick: acceptButtonClickHandler,
    onRejectButtonClick: rejectButtonClickHandler,
    onMarkAsFulfilledButtonClick: markAsFulfilledButtonClickHandler,
    acceptLoading,
    rejectLoading,
    markAsFulfilledLoading,
}: Props) => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    let cancelButton = <></>;
    let acceptButton = <></>;
    let rejectButton = <></>;
    let markAsFulfilledButton = <></>;
    let viewEndResultButton = <></>;

    switch (petitionGroup.status) {
        case PetitionGroupStatus.ONGOING:
            acceptButton = (
                <Button
                    id="b1ce2fae-5742-4624-85f2-fe5e4432312b"
                    className="btn btn-sm btn-link text-decoration-none text-success d-flex gap-1 align-items-center"
                    title={t("petition.buttons.accept")}
                    onClick={() => acceptButtonClickHandler()}
                    isLoading={acceptLoading}
                    disabled={acceptLoading || rejectLoading}
                >
                    <MdDone id="b1ce2fae-5742-4624-85f2-fe5e4432312b-icon" />
                    {t("petition.buttons.accept")}
                </Button>
            );
            rejectButton = (
                <Button
                    id="aa637943-1ee3-4e36-b414-5986125c2857"
                    className="btn btn-sm btn-link text-decoration-none text-danger d-flex gap-1 align-items-center"
                    title={t("petition.buttons.reject")}
                    onClick={() => rejectButtonClickHandler()}
                    isLoading={rejectLoading}
                    disabled={acceptLoading || rejectLoading}
                >
                    <MdClear id="aa637943-1ee3-4e36-b414-5986125c2857-icon" />
                    {t("petition.buttons.reject")}
                </Button>
            );
            break;
        case PetitionGroupStatus.REJECTED:
            rejectButton = (
                <Button
                    id="a31ad6bc-9f6d-4796-8629-befb704855ef"
                    className="btn btn-sm btn-link text-decoration-none text-danger d-flex gap-1 align-items-center"
                    title={t("petition.buttons.rejected")}
                    disabled
                >
                    <MdClear id="a31ad6bc-9f6d-4796-8629-befb704855ef-icon" />
                    {t("petition.buttons.rejected")}
                </Button>
            );
            break;
        case PetitionGroupStatus.ACCEPTED:
            markAsFulfilledButton = (
                <Button
                    id="f170cd0c-3ef7-43ad-8b06-b0b065a3033d"
                    className="btn btn-sm btn-link text-decoration-none text-success d-flex gap-1 align-items-center"
                    title={t("petition.buttons.markAsFulfilled")}
                    onClick={markAsFulfilledButtonClickHandler}
                    isLoading={markAsFulfilledLoading}
                >
                    <MdDoneAll id="f170cd0c-3ef7-43ad-8b06-b0b065a3033d-icon" />
                    {t("petition.buttons.markAsFulfilled")}
                </Button>
            );
            break;
    }

    if (petitionGroup.status === PetitionGroupStatus.FULFILLED) {
        if (petitionGroup.end_result) {
            viewEndResultButton = (
                <a
                    id="93f5db29-27a3-417f-886e-d54d8a5607c5"
                    className="btn btn-sm btn-link text-decoration-none text-dark"
                    href={petitionGroup.end_result.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    <GoLinkExternal
                        id="93f5db29-27a3-417f-886e-d54d8a5607c5-icon"
                        className="mb-1"
                    />{" "}
                    {t("petition.buttons.viewEndResult")}
                </a>
            );
        }
    }
    return (
        <div id={id} className="d-flex flex-fill justify-content-end gap-2">
            {cancelButton}
            {acceptButton}
            {rejectButton}
            {markAsFulfilledButton}
            {viewEndResultButton}
        </div>
    );
};

export default PetitionGroupActionButtons;
