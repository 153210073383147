import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useEffect,
    useState,
} from "react";
import ModalContext from "../../contexts/modal-context";
import FindUserOverlay from "../Modal/overlays/FindUserOverlay/FindUserOverlay";
import User from "../../models/User";
import { AiOutlineDelete } from "react-icons/ai";
import {
    createPetition as api_createPetition,
    FailureResponse as CreatePetitionFailureResponse,
} from "../../api/petitions/createPetition";
import { getUser as api_getUser } from "../../api/users/getUser";
import Petition from "../../models/Petition";
import Textarea from "../UI/Textarea/Textarea";
import InputGroup from "../UI/InputGroup/InputGroup";
import {
    ApiErrorCode,
    DEFAULT_PETITION_DURATION_IN_DAYS,
    MAX_PETITION_DESCRIPTION_LENGTH,
    MAX_PETITION_DURATION_IN_DAYS,
    MAX_PETITION_PLEDGE_AMOUNT,
    MAX_PETITION_TITLE_LENGTH,
    MIN_PETITION_DURATION_IN_DAYS,
    MIN_PETITION_PLEDGE_AMOUNT,
} from "../../constants";
import Button from "../UI/Button/Button";
import AlertOverlay from "../Modal/overlays/AlertOverlay/AlertOverlay";
import EmailNotVerifiedErrorOverlay from "../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AuthContext from "../../contexts/auth-context";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserBasicInfo from "../UserBasicInfo/UserBasicInfo";
import { useStripe } from "@stripe/react-stripe-js";
import ChargeBreakdown from "../ChargeBreakdown/ChargeBreakdown";
import PaymentContext from "../../contexts/payment-context";
import SelectPledgeSourceForm, {
    PledgeSourceType,
    PledgeSourceTypes,
} from "../SelectPledgeSourceForm/SelectPledgeSourceForm";
import DraftPetitionContext from "../../contexts/draft-petition-context";

interface Props extends HTMLAttributes<any> {
    onSelectAddressee: (addressees: User[]) => void;
    onCreatePetition: (petition: Petition) => void;
}

const StartPetitionForm = ({
    id,
    onSelectAddressee,
    onCreatePetition,
}: Props) => {
    const username = new URLSearchParams(window.location.search).get("a");
    const stripe = useStripe();
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const { user, refreshUser } = useContext(AuthContext);
    const { makePayment } = useContext(PaymentContext);
    const {
        draftPetition,
        getDraftPetition,
        saveDraftPetition,
        removeDraftPetition,
    } = useContext(DraftPetitionContext);
    const [addressee, setAddressee] = useState<User | null>(null);
    const [addresseeInputErrorMsg, setAddresseeInputErrorMsg] = useState("");
    const [title, setTitle] = useState<string>("");
    const [titleTextareaErrorMsg, setTitleTextareaErrorMsg] = useState("");
    const [description, setDescription] = useState<string>("");
    const [descriptionTextareaErrorMsg, setDescriptionTextareaErrorMsg] =
        useState("");
    const [pledgeAmount, setPledgeAmount] = useState<number>(
        MIN_PETITION_PLEDGE_AMOUNT
    );
    const [pledgeAmountInputErrorMsg, setPledgeAmountInputErrorMsg] =
        useState("");
    const [petitionDurationInDays, setPetitionDurationInDays] =
        useState<number>(DEFAULT_PETITION_DURATION_IN_DAYS);
    const [
        petitionDurationInDaysInputErrorMsg,
        setPetitionDurationInDaysInputErrorMsg,
    ] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [pledgeSource, setPledgeSource] = useState<PledgeSourceType>(
        PledgeSourceTypes.BALANCE
    );
    const creatorsOnly = true;

    useEffect(() => {
        if (username) getUser(username);
        refreshUser();
        getDraftPetition();
    }, []);

    useEffect(() => {
        if (draftPetition) {
            setTitle(draftPetition.title);
            setDescription(draftPetition.description);
            setPetitionDurationInDays(draftPetition.petitionDurationInDays);
            setPledgeAmount(draftPetition.pledgeAmount);
            setPledgeSource(draftPetition.pledgeSource);
            if (!addressee && draftPetition.addresseeUsername)
                getUser(draftPetition.addresseeUsername);
        }
    }, [draftPetition]);

    useEffect(() => {
        if (addressee) onSelectAddressee([addressee]);
        else onSelectAddressee([]);
    }, [addressee]);

    const getUser = async (username: string) => {
        try {
            const user = await api_getUser(username);
            if (user && user.is_creator) {
                setAddressee(user);
                saveDraftPetition(
                    draftPetition ? draftPetition.title : "",
                    draftPetition ? draftPetition.description : "",
                    user.username,
                    draftPetition
                        ? draftPetition.petitionDurationInDays
                        : DEFAULT_PETITION_DURATION_IN_DAYS,
                    draftPetition
                        ? draftPetition.pledgeAmount
                        : MIN_PETITION_PLEDGE_AMOUNT,
                    draftPetition
                        ? draftPetition.pledgeSource
                        : PledgeSourceTypes.BALANCE
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const resetForm = (event: React.FormEvent) => {
        event.preventDefault();
        removeAddresseeHandler();
        setTitle("");
        setTitleTextareaErrorMsg("");
        setDescription("");
        setDescriptionTextareaErrorMsg("");
        setPledgeAmount(MIN_PETITION_PLEDGE_AMOUNT);
        setPledgeAmountInputErrorMsg("");
        setPetitionDurationInDays(DEFAULT_PETITION_DURATION_IN_DAYS);
        setPetitionDurationInDaysInputErrorMsg("");
        setPledgeSource(PledgeSourceTypes.BALANCE);
        setErrorMsg("");
        removeDraftPetition();
    };

    const showPledgeAmountInput =
        addressee &&
        addressee.uuid !== user!.uuid &&
        addressee.account!.payment_settings.accepting_pledges_enabled;

    const validMinPledgeAmount = showPledgeAmountInput
        ? MIN_PETITION_PLEDGE_AMOUNT
        : 0;

    if (!showPledgeAmountInput) {
        if (pledgeAmountInputErrorMsg) setPledgeAmountInputErrorMsg("");

        if (pledgeAmount !== validMinPledgeAmount)
            setPledgeAmount(validMinPledgeAmount);
    } else {
        if (draftPetition && pledgeAmount !== draftPetition.pledgeAmount)
            setPledgeAmount(draftPetition.pledgeAmount);
    }

    const titleChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.currentTarget.value);
        setTitleTextareaErrorMsg("");
        saveDraftPetition(
            event.currentTarget.value,
            draftPetition ? draftPetition.description : "",
            draftPetition ? draftPetition.addresseeUsername : "",
            draftPetition
                ? draftPetition.petitionDurationInDays
                : DEFAULT_PETITION_DURATION_IN_DAYS,
            draftPetition
                ? draftPetition.pledgeAmount
                : MIN_PETITION_PLEDGE_AMOUNT,
            draftPetition
                ? draftPetition.pledgeSource
                : PledgeSourceTypes.BALANCE
        );
    };

    const descriptionChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescription(event.currentTarget.value);
        setDescriptionTextareaErrorMsg("");
        saveDraftPetition(
            draftPetition ? draftPetition.title : "",
            event.currentTarget.value,
            draftPetition ? draftPetition.addresseeUsername : "",
            draftPetition
                ? draftPetition.petitionDurationInDays
                : DEFAULT_PETITION_DURATION_IN_DAYS,
            draftPetition
                ? draftPetition.pledgeAmount
                : MIN_PETITION_PLEDGE_AMOUNT,
            draftPetition
                ? draftPetition.pledgeSource
                : PledgeSourceTypes.BALANCE
        );
    };

    const pledgeAmountChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setPledgeAmount(val);
        saveDraftPetition(
            draftPetition ? draftPetition.title : "",
            draftPetition ? draftPetition.description : "",
            draftPetition ? draftPetition.addresseeUsername : "",
            draftPetition
                ? draftPetition.petitionDurationInDays
                : DEFAULT_PETITION_DURATION_IN_DAYS,
            val,
            draftPetition
                ? draftPetition.pledgeSource
                : PledgeSourceTypes.BALANCE
        );
        if (
            isNaN(val) ||
            val < MIN_PETITION_PLEDGE_AMOUNT ||
            val > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
        } else {
            setPledgeAmountInputErrorMsg("");
        }
    };

    const pledgeSourceChangeHandler = (newPledgeSource: PledgeSourceType) => {
        setPledgeSource(newPledgeSource);
        saveDraftPetition(
            draftPetition ? draftPetition.title : "",
            draftPetition ? draftPetition.description : "",
            draftPetition ? draftPetition.addresseeUsername : "",
            draftPetition
                ? draftPetition.petitionDurationInDays
                : DEFAULT_PETITION_DURATION_IN_DAYS,
            draftPetition
                ? draftPetition.pledgeAmount
                : MIN_PETITION_PLEDGE_AMOUNT,
            newPledgeSource
        );
    };

    const petitionDurationInDaysChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const val = parseInt(event.currentTarget.value);
        setPetitionDurationInDays(val);
        saveDraftPetition(
            draftPetition ? draftPetition.title : "",
            draftPetition ? draftPetition.description : "",
            draftPetition ? draftPetition.addresseeUsername : "",
            val,
            draftPetition
                ? draftPetition.pledgeAmount
                : MIN_PETITION_PLEDGE_AMOUNT,
            draftPetition
                ? draftPetition.pledgeSource
                : PledgeSourceTypes.BALANCE
        );
        if (
            isNaN(val) ||
            val < MIN_PETITION_DURATION_IN_DAYS ||
            val > MAX_PETITION_DURATION_IN_DAYS
        ) {
            setPetitionDurationInDaysInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_DURATION_IN_DAYS,
                    max_value: MAX_PETITION_DURATION_IN_DAYS,
                })
            );
        } else {
            setPetitionDurationInDaysInputErrorMsg("");
        }
    };

    const isAddresseeValid = () => {
        if (!addressee) {
            setAddresseeInputErrorMsg(
                t("startPetitionPage.pleaseSelectAddressee")
            );
            return false;
        }
        return true;
    };

    const isTitleValid = () => {
        if (!title) {
            setTitleTextareaErrorMsg(t("startPetitionPage.pleaseEnterTitle"));
            return false;
        }
        return true;
    };

    const isDescriptionValid = () => {
        if (!description) {
            setDescriptionTextareaErrorMsg(
                t("startPetitionPage.pleaseEnterDescription")
            );
            return false;
        }
        return true;
    };

    const isPetitionDurationInDaysValid = () => {
        if (
            isNaN(petitionDurationInDays) ||
            petitionDurationInDays === undefined ||
            petitionDurationInDays < MIN_PETITION_DURATION_IN_DAYS ||
            petitionDurationInDays > MAX_PETITION_DURATION_IN_DAYS
        ) {
            setPetitionDurationInDaysInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_DURATION_IN_DAYS,
                    max_value: MAX_PETITION_DURATION_IN_DAYS,
                })
            );
            return false;
        }
        return true;
    };

    const isPledgeAmountValid = () => {
        if (
            isNaN(pledgeAmount) ||
            pledgeAmount === undefined ||
            pledgeAmount < MIN_PETITION_PLEDGE_AMOUNT ||
            pledgeAmount > MAX_PETITION_PLEDGE_AMOUNT
        ) {
            setPledgeAmountInputErrorMsg(
                t("startPetitionPage.pleaseEnterValueBetween", {
                    min_value: MIN_PETITION_PLEDGE_AMOUNT,
                    max_value: MAX_PETITION_PLEDGE_AMOUNT,
                })
            );
            return false;
        }
        return true;
    };

    const selectUserHandler = (user: User) => {
        closeModal();
        setAddressee(user);
        setAddresseeInputErrorMsg("");
        setErrorMsg("");
        saveDraftPetition(
            draftPetition ? draftPetition.title : "",
            draftPetition ? draftPetition.description : "",
            user.username,
            draftPetition
                ? draftPetition.petitionDurationInDays
                : DEFAULT_PETITION_DURATION_IN_DAYS,
            draftPetition
                ? draftPetition.pledgeAmount
                : MIN_PETITION_PLEDGE_AMOUNT,
            draftPetition
                ? draftPetition.pledgeSource
                : PledgeSourceTypes.BALANCE
        );
    };

    const removeAddresseeHandler = () => {
        setAddressee(null);
        setAddresseeInputErrorMsg("");
        saveDraftPetition(
            draftPetition ? draftPetition.title : "",
            draftPetition ? draftPetition.description : "",
            "",
            draftPetition
                ? draftPetition.petitionDurationInDays
                : DEFAULT_PETITION_DURATION_IN_DAYS,
            draftPetition
                ? draftPetition.pledgeAmount
                : MIN_PETITION_PLEDGE_AMOUNT,
            draftPetition
                ? draftPetition.pledgeSource
                : PledgeSourceTypes.BALANCE
        );
    };

    const addresseeInputFocusHandler = () => {
        showModal(
            <FindUserOverlay
                id="e3b29ea7-cb80-48a1-84c2-27859386e55c"
                selectUserHandler={selectUserHandler}
                creatorsOnly={creatorsOnly}
            />
        );
    };

    const placeholder = creatorsOnly
        ? t("searchUserForm.findCreator")
        : t("searchUserForm.findUser");

    const addresseeContent = addressee ? (
        <div
            id="47337719-7c2d-48e3-9701-ada85bc50ad3"
            className="d-flex justify-content-between align-items-center"
        >
            <UserBasicInfo
                id="7d7405b1-6382-4927-adc9-aa9a2f0a1a75"
                user={addressee}
            />
            <Button
                id="1c074ed4-161e-4e4c-95ac-580ef75b3db2"
                className="btn btn-outline-danger"
                onClick={removeAddresseeHandler}
                title={t("startPetitionPage.removeAddressee")}
            >
                <AiOutlineDelete
                    id="1c074ed4-161e-4e4c-95ac-580ef75b3db2-icon"
                    className="my-1"
                />
            </Button>
        </div>
    ) : (
        <InputGroup
            id="e75d8dfe-b22f-41d4-a909-8aea0d944bb8"
            className="input-group-sm"
            errorMsg={addresseeInputErrorMsg}
            type="text"
            placeholder={placeholder}
            onFocus={addresseeInputFocusHandler}
        />
    );

    const createPetitionSuccessHandler = (response: Petition) => {
        removeDraftPetition();
        onCreatePetition(response);
    };

    const startPetition = async (pledgeSource: PledgeSourceTypes) => {
        setIsLoading(true);
        const addressees: string[] = [];
        if (addressee) addressees.push(addressee.uuid);

        try {
            const response = await api_createPetition(
                title,
                description,
                addressees,
                petitionDurationInDays,
                pledgeAmount * 100,
                pledgeSource
            );
            if (pledgeSource === PledgeSourceTypes.PAYMENT_METHOD)
                await makePayment(
                    stripe,
                    pledgeAmount,
                    setIsLoading,
                    setPledgeAmountInputErrorMsg,
                    () => createPetitionSuccessHandler(response),
                    response.my_petition_supporter_uuid
                );
            else createPetitionSuccessHandler(response);
        } catch (error) {
            const { errors, status_code, code } =
                error as CreatePetitionFailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="c1eb7ace-0e90-48d6-9fec-e4c308c3bfbf"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                id="d2617baf-30cf-4edb-8f5a-8851d1636544"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else {
                    if (code === ApiErrorCode.NOT_ENOUGH_BALANCE) {
                        showModal(
                            <AlertOverlay
                                id="989ae09b-a7cb-49c4-b6a0-9f0c5863c8a6"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                                link={
                                    <Link
                                        // id="start-petition-form-alert-overlay-to-settings-account"
                                        id="d6f38488-a135-4287-aeed-bac177eda9af"
                                        to="/settings/account"
                                        className="btn btn-sm btn-primary"
                                    >
                                        {t(
                                            "settingsPage.accountSettings.balanceSettings.topUpBalance"
                                        )}
                                    </Link>
                                }
                            />
                        );
                    } else {
                        if (errors.title)
                            setTitleTextareaErrorMsg(errors.title[0]);
                        if (errors.description)
                            setDescriptionTextareaErrorMsg(
                                errors.description[0]
                            );
                        if (errors.petition_duration_in_days)
                            setPetitionDurationInDaysInputErrorMsg(
                                errors.petition_duration_in_days[0]
                            );
                        if (errors.author_pledge_amount)
                            setPledgeAmountInputErrorMsg(
                                errors.author_pledge_amount[0]
                            );
                        if (errors.addressees)
                            setAddresseeInputErrorMsg(errors.addressees[0]);
                        if (errors.non_field_errors)
                            setErrorMsg(errors.non_field_errors[0]);
                        if (errors.error) console.log(errors.error);
                    }
                }
            }
        }
        setIsLoading(false);
    };

    const showSelectPledgeSource =
        pledgeAmount * 100 <= user!.account!.payment_settings.balance;

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        if (
            isAddresseeValid() &&
            isTitleValid() &&
            isDescriptionValid() &&
            isPetitionDurationInDaysValid()
        ) {
            if (showPledgeAmountInput) {
                if (isPledgeAmountValid())
                    if (showSelectPledgeSource) {
                        await startPetition(pledgeSource);
                    } else {
                        await startPetition(PledgeSourceTypes.PAYMENT_METHOD);
                    }
            } else {
                await startPetition(PledgeSourceTypes.BALANCE);
            }
        }
    };

    const currency = user!.account!.payment_settings.currency;

    let content;
    if (
        addressee &&
        addressee.account!.payment_settings.accepting_pledges_enabled &&
        !currency
    ) {
        content = (
            <div
                id="0d3bf4b7-43e7-40f9-a67d-f28af0e03c3e"
                className="d-flex align-items-center flex-wrap gap-2 justify-content-between mt-4"
            >
                {t("startPetitionPage.haveToPledgeMoney")}
                <Link
                    // id="start-petition-form-to-settings-account_set-currency"
                    id="bca9cc6a-c449-44b2-a746-4931383aa013"
                    to="/settings/account"
                    className="btn btn-sm btn-primary"
                >
                    {t("startPetitionPage.setCurrency")}
                </Link>
            </div>
        );
    } else {
        content = (
            <>
                <div id="2277bdeb-88eb-4ff2-b6e1-686768444366" className="mb-3">
                    <Textarea
                        id="f83515eb-b20a-4edf-bd82-e5f850c68d61"
                        className="form-control-sm"
                        rows={2}
                        errorMsg={titleTextareaErrorMsg}
                        placeholder={t("startPetitionPage.title")}
                        onChange={titleChangeHandler}
                        value={title}
                        maxLength={MAX_PETITION_TITLE_LENGTH}
                    />
                    <div
                        id="763fdedc-d042-4663-ae58-ca27e1e277f1"
                        className="form-text d-flex justify-content-end"
                    >
                        {MAX_PETITION_TITLE_LENGTH - title.length}
                    </div>
                </div>
                <div id="bbd115d4-346d-433e-bd33-b759e9a919e3" className="mb-3">
                    <Textarea
                        id="916fcf18-ad45-4671-90d5-11eb8f9e3ff0"
                        className="form-control-sm"
                        rows={10}
                        errorMsg={descriptionTextareaErrorMsg}
                        placeholder={t("startPetitionPage.description")}
                        onChange={descriptionChangeHandler}
                        value={description}
                        maxLength={MAX_PETITION_DESCRIPTION_LENGTH}
                    />
                    <div
                        id="8ee9a403-c096-4f98-9746-e359c76bf076"
                        className="form-text d-flex justify-content-end"
                    >
                        {MAX_PETITION_DESCRIPTION_LENGTH - description.length}
                    </div>
                </div>
                <div
                    id="01343e14-991c-4ebc-89a5-26f662c00eec"
                    className="mb-3 row"
                >
                    <label
                        id="17265360-7876-4f8f-8a56-1331940b0042"
                        htmlFor="73fddf07-048d-4ba0-b7de-da03e1f4d54b"
                        className="col-sm-6 col-form-label"
                    >
                        {t("startPetitionPage.needContentIn")}:
                    </label>
                    <div
                        id="3d13bfc5-88d3-4f91-a28c-2aa32de637ea"
                        className="col-sm-6"
                    >
                        <InputGroup
                            // id="petitionDurationInDaysInput"
                            id="73fddf07-048d-4ba0-b7de-da03e1f4d54b"
                            className="input-group-sm"
                            errorMsg={petitionDurationInDaysInputErrorMsg}
                            trailingAddon={
                                <span
                                    id="e84c16a8-e34f-458a-943e-d39e6c474aeb"
                                    className="input-group-text"
                                >
                                    {t("startPetitionPage.days")}
                                </span>
                            }
                            type="number"
                            min={MIN_PETITION_DURATION_IN_DAYS}
                            max={MAX_PETITION_DURATION_IN_DAYS}
                            onChange={petitionDurationInDaysChangeHandler}
                            value={
                                petitionDurationInDays !== undefined
                                    ? petitionDurationInDays
                                    : ""
                            }
                        />
                    </div>
                </div>
                {showPledgeAmountInput && (
                    <>
                        <div
                            id="a6edb672-4201-4f82-8a79-941c1bf8ec1b"
                            className="mb-3 row"
                        >
                            <label
                                id="2eb5b417-897f-49d6-a3e4-ce8bd0aa0e6a"
                                htmlFor="fd0a87cf-20ed-43ce-a68e-00ea79a2a395"
                                className="col-sm-6 col-form-label"
                            >
                                {t("startPetitionPage.pledge")}:
                            </label>
                            <div
                                id="21f31e6c-6a51-4f42-b2ca-ce5748c8e630"
                                className="col-sm-6"
                            >
                                <InputGroup
                                    // id="pledgeAmountInput"
                                    id="fd0a87cf-20ed-43ce-a68e-00ea79a2a395"
                                    className="input-group-sm"
                                    errorMsg={pledgeAmountInputErrorMsg}
                                    leadingAddon={
                                        <span
                                            id="45caa396-3ef3-4929-b0af-87e3654b783e"
                                            className="input-group-text"
                                        >
                                            {currency.toUpperCase()}
                                        </span>
                                    }
                                    trailingAddon={
                                        <span
                                            id="6081e608-57f9-4329-a509-750c72ed6312"
                                            className="input-group-text"
                                        >
                                            .00
                                        </span>
                                    }
                                    type="number"
                                    min={MIN_PETITION_PLEDGE_AMOUNT}
                                    max={MAX_PETITION_PLEDGE_AMOUNT}
                                    onChange={pledgeAmountChangeHandler}
                                    value={
                                        pledgeAmount !== undefined
                                            ? pledgeAmount
                                            : ""
                                    }
                                />
                            </div>
                        </div>
                        <div
                            id="82f3f5d4-fb5e-46a0-92ac-63b5217f2a57"
                            className="mb-3 row"
                        >
                            <div
                                id="be627a1e-bc8d-4736-89ec-8c8eb8cbf6ac"
                                className="col"
                            >
                                {showSelectPledgeSource ? (
                                    <SelectPledgeSourceForm
                                        id="c96f6ac9-36b1-4ae7-abd7-c4abb0267f75"
                                        amount={pledgeAmount}
                                        source={pledgeSource}
                                        onSourceChange={
                                            pledgeSourceChangeHandler
                                        }
                                    />
                                ) : (
                                    <ChargeBreakdown
                                        id="8cebcd45-157b-4786-b902-57a325795e6e"
                                        amount={pledgeAmount}
                                        type="pledge"
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
                {errorMsg && (
                    <div
                        id="92618cf9-2bea-4a20-a1c9-efbb0314cf34"
                        className="form-text text-danger mt-0"
                    >
                        {errorMsg}
                    </div>
                )}
                <div
                    id="13de4bfe-ebb1-4e54-853d-11cb6f8caebd"
                    className="d-flex justify-content-end gap-2"
                >
                    <Button
                        id="dc7141f9-f184-4e06-89bc-34b8200afa86"
                        className="btn btn-sm btn-dark"
                        onClick={resetForm}
                    >
                        {t("startPetitionPage.resetForm")}
                    </Button>
                    <Button
                        id="13157b26-563c-4f00-bfdd-806fd9b1698c"
                        className="btn btn-sm btn-primary"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                    >
                        {t("startPetitionPage.start")}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <form id={id} onSubmit={submitHandler}>
            <div id="6f2f197a-a2d5-4166-8e09-036fbc2dc91b" className="mb-3">
                {addresseeContent}
            </div>
            {content}
        </form>
    );
};

export default StartPetitionForm;
