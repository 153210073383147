import User from "./User";
import Referrer from "./Referrer";

export enum ReferralStatus {
    PENDING = "pending",
    ACTIVE = "active",
    SUSPENDED = "suspended",
}

class Referral {
    readonly uuid: string;
    readonly referrer: Referrer;
    readonly referee: User;
    readonly status: ReferralStatus;
    readonly currency: string;
    readonly referrer_paid_amount: number;

    constructor(data: any) {
        this.uuid = data.uuid;
        this.referrer = new Referrer(data.referrer);
        this.referee = new User(data.referee);
        this.status = data.status;
        this.currency = data.currency;
        this.referrer_paid_amount = data.referrer_paid_amount;
    }
}

export default Referral;
