import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../contexts/auth-context";
import Button from "../../../../components/UI/Button/Button";
import {
    FailureResponse as GetReferralProgramFailureResponse,
    getReferralProgram as api_getReferralProgram,
} from "../../../../api/referralProgram/getReferralProgram";
import {
    FailureResponse as JoinReferralProgramFailureResponse,
    joinReferralProgram as api_joinReferralProgram,
} from "../../../../api/referralProgram/joinReferralProgram";
import ReferralProgram, {
    ReferralProgramStatus,
} from "../../../../models/ReferralProgram";
import { ApiErrorCode } from "../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../contexts/modal-context";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Referrer from "../../../../models/Referrer";
import Countdown from "../../../../components/UI/Countdown/Countdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ReferralProgramDetailsPage = () => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);
    const [joinProgramLoading, setJoinProgramLoading] = useState(false);
    const [referralProgram, setReferralProgram] = useState<ReferralProgram>();
    const [referrer, setReferrer] = useState<Referrer>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const getReferralProgram = async () => {
        setIsLoading(true);
        try {
            const response = await api_getReferralProgram();
            setReferralProgram(response);
        } catch (error) {
            const { errors, status_code, code } =
                error as GetReferralProgramFailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="e09ff575-b007-4436-a541-718bc585dc4e"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                id="f5d42d81-b439-4bf3-9aeb-2e0c62cf5eab"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else {
                    if (errors.error)
                        setErrorMsg(
                            errors.error instanceof Array
                                ? errors.error[0]
                                : errors.error!
                        );
                }
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getReferralProgram();
    }, []);

    const joinReferralProgram = async () => {
        setJoinProgramLoading(true);
        try {
            const response = await api_joinReferralProgram();
            setReferrer(response);
            showModal(
                <AlertOverlay
                    id="d3a1ead7-da64-4f86-a13e-0be20f9c0451"
                    status="success"
                    message={t(
                        "referralProgramPage.referralProgramDetailsPage.joinedReferralProgramSuccessfully"
                    )}
                    link={
                        <Link
                            id="d04c4509-07a8-4f61-9b3b-4547806c1292"
                            to="/referral-program/referrer"
                            className="btn btn-sm btn-primary"
                        >
                            {t(
                                "referralProgramPage.referralProgramDetailsPage.viewReferralLink"
                            )}
                        </Link>
                    }
                />
            );
        } catch (error) {
            const { errors, status_code, code } =
                error as JoinReferralProgramFailureResponse;

            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                id="b210f890-7a37-417d-9a56-2026c3fd9f15"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                id="dd4c66ee-ab07-4b6c-ac4d-5fa87f2944a7"
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else {
                    if (errors.error) console.log(errors.error);
                }
            }
        }
        setJoinProgramLoading(false);
    };

    let joinProgramButton = undefined;
    if (
        user &&
        referralProgram &&
        ((!referralProgram.my_referrer_uuid &&
            user.can_join_referral_program) ||
            (referrer &&
                referrer.referral_program.uuid === referralProgram.uuid))
    ) {
        joinProgramButton = (
            <div id="e4f90593-d17c-48ec-a70e-2e30e76e3ec2">
                <Button
                    id="b032efa7-57b7-4c5b-94c6-7b8d19e1e251"
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                        showModal(
                            <AlertOverlay
                                id="e5bfcb7b-79e7-497b-b87e-e10e045332f2"
                                status="warning"
                                message={t(
                                    "referralProgramPage.referralProgramDetailsPage.sureWantToJoinReferralProgram"
                                )}
                                onConfirm={async () =>
                                    await joinReferralProgram()
                                }
                            />
                        );
                    }}
                    isLoading={joinProgramLoading}
                >
                    {t(
                        "referralProgramPage.referralProgramDetailsPage.joinReferralProgram"
                    )}
                </Button>
            </div>
        );
    }

    return (
        <div
            id="e1dbfb0c-3f25-4502-b65e-99b649fca6b2"
            className={`${!user && `container mt-3`}`}
        >
            {isLoading && <Spinner id="ae34b530-fe79-46c8-b5c5-963765cd895e" />}
            {!isLoading && !referralProgram && (
                <div id="d0cedaee-ebf5-4d86-8f5b-18cb043760a2">{errorMsg}</div>
            )}
            {!isLoading && referralProgram && (
                <div
                    id="ddeac5ee-277a-4ea2-8de1-3d55afc65f15"
                    className="d-flex flex-column gap-3"
                >
                    <h2 id="e0dc668b-d826-4642-a67a-3f03eb6ab484">
                        {referralProgram?.title}
                    </h2>
                    <div
                        id="b27e6f20-7ca8-4ec3-af71-0791cbab2d77"
                        className="d-flex flex-column gap-3"
                        dangerouslySetInnerHTML={{
                            __html: referralProgram!.description,
                        }}
                    />
                    {referralProgram.status === ReferralProgramStatus.ACTIVE &&
                        referralProgram.dt_end && (
                            <div
                                id="dff2f646-f5a2-4423-a0ce-c5ab07c8ec05"
                                className="d-flex gap-2"
                            >
                                <span id="c8397203-54cf-459b-b220-d0af2c8f8644">
                                    {t(
                                        "referralProgramPage.referralProgramDetailsPage.referralProgramEndsIn"
                                    )}
                                    :
                                </span>
                                <Countdown
                                    id="c051167c-4671-4052-b6bc-5ba6b5c51976"
                                    deadline={new Date(referralProgram.dt_end)}
                                    className="text-primary"
                                    style={{ width: "140px" }}
                                />
                            </div>
                        )}
                    {joinProgramButton}
                </div>
            )}
        </div>
    );
};

export default ReferralProgramDetailsPage;
