import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import ReferralProgram from "../../models/ReferralProgram";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export const getReferralProgram = async (): Promise<ReferralProgram> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get("/referral-program/", {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
        });
        return new ReferralProgram(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
