import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import Referral from "../../models/Referral";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export type SuccessResponse = {
    results: Referral[];
    next: string;
    previous: string;
};

export const getReferrals = async (
    cursor?: string
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get("/referral-program/referrals", {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
            params: {
                cursor,
            },
        });
        response.data.results = response.data.results.map(
            (item: Referral) => new Referral(item)
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
