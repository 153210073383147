import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import {
    FailureResponse,
    getPetitionGroup as api_getPetitionGroup,
} from "../../api/petitionGroups/getPetitionGroup";
import { listPetitions as api_listPetitions } from "../../api/petitions/listPetitions";
import PetitionGroup from "../../models/PetitionGroup";
import Petition from "../../models/Petition";
import { parseCursor } from "../../utils/helpers";
import PetitionsList from "../../components/PetitionsList/PetitionsList";
import Spinner from "../../components/UI/Spinner/Spinner";
import PetitionGroupForm from "../../components/PetitionGroupForm/PetitionGroupForm";
import PetitionGroupsListItem from "../../components/PetitionGroupsList/PetitionGroupsListItem/PetitionGroupsListItem";

const PetitionGroupPage = () => {
    const { t } = useTranslation();
    const match = useMatch("/petitions/groups/:uuid");
    const petition_group_uuid = match?.params.uuid!;

    const [isEditMode, setIsEditMode] = useState(false);
    const [petitionGroup, setPetitionGroup] = useState<PetitionGroup>();
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPetitions, setIsLoadingPetitions] = useState(true);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitionGroup = useCallback(async () => {
        setErrorMsg("");
        try {
            const response = await api_getPetitionGroup(petition_group_uuid);
            setPetitionGroup(response);
            setIsLoading(false);
            await getPetitions(undefined, true);
        } catch (error) {
            const { errors, status_code } = error as FailureResponse;
            console.log(errors);
            if (status_code === 404) {
                setErrorMsg(t("petitionGroupPage.petitionGroupNotFound"));
            }
        }
        setIsLoading(false);
    }, [petition_group_uuid]);

    const getPetitions = async (
        cursor: string | undefined,
        hasMore: boolean
    ) => {
        if (!hasMore) return;
        setIsLoadingPetitions(true);
        try {
            const response = await api_listPetitions(
                undefined,
                undefined,
                undefined,
                undefined,
                petition_group_uuid,
                cursor
            );
            if (cursor === undefined) setPetitions(response.results);
            else
                setPetitions((prev: Petition[]) => [
                    ...prev,
                    ...response.results,
                ]);
            if (response.next) {
                setCursor(parseCursor(response.next));
                setHasMore(true);
            } else {
                setCursor(undefined);
                setHasMore(false);
            }
        } catch (error) {}
        setIsLoadingPetitions(false);
    };

    useEffect(() => {
        getPetitionGroup();
    }, [getPetitionGroup]);

    let content = <></>;
    if (petitionGroup) {
        content = (
            <PetitionGroupsListItem
                id="de319f80-f7c7-4aec-8bb3-8accc60db3ee"
                petitionGroup={petitionGroup}
                onEditPetitionGroup={() => setIsEditMode(true)}
                onPetitionGroupUpdate={() => {
                    setPetitions([]);
                    getPetitions(undefined, true);
                }}
            />
        );

        if (isEditMode) {
            content = (
                <PetitionGroupForm
                    id="bc4864c3-d93d-4865-a698-362d96acf867"
                    petitionGroup={petitionGroup}
                    onUpdatePetitionGroup={(petitionGroup: PetitionGroup) => {
                        setPetitionGroup(petitionGroup);
                        setIsEditMode(false);
                    }}
                    onCancelEdit={() => setIsEditMode(false)}
                />
            );
        }
    }

    return (
        <>
            {!isLoading && !petitionGroup && (
                <p id="c5e0d21f-733a-4c8a-83bf-29758d8fa189">{errorMsg}</p>
            )}
            {!isLoading && petitionGroup && (
                <div id="e674a194-3ac8-49e5-8f98-8f7c8a94602c">
                    {content}
                    <hr id="e3732239-b17b-4267-b895-88221687b81f" />
                    <div id="e0fe9bfd-82b3-4570-877f-861768c3f92b">
                        {petitions.length > 0 && (
                            <PetitionsList
                                id="cde79157-4be2-4777-8239-e9cfaddd10da"
                                petitions={petitions}
                                getPetitions={() =>
                                    getPetitions(cursor, hasMore)
                                }
                                isLoading={isLoadingPetitions}
                                onRemovePetitionFromGroup={() => {
                                    setPetitionGroup(undefined);
                                    setPetitions([]);
                                    getPetitionGroup();
                                }}
                            />
                        )}
                        {isLoadingPetitions && (
                            <Spinner
                                id="a33d1abf-5d95-4e95-a0ea-6a078fe99b41"
                                className="mt-3"
                            />
                        )}
                        {!isLoadingPetitions && !petitions.length && (
                            <div
                                id="e6ce2062-88da-4c80-9ac3-d3be9df7e146"
                                className="d-flex justify-content-center form-text"
                            >
                                {t("petitionGroupPage.noPetitionsInGroup")}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PetitionGroupPage;
