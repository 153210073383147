import React, {
    ComponentProps,
    HTMLAttributes,
    Ref,
    useCallback,
    useRef,
} from "react";
import Referral from "../../../../../models/Referral";
import ReferralsListItem from "./ReferralsListItem/ReferralsListItem";

interface Props extends ComponentProps<any>, HTMLAttributes<any> {
    referrals: Referral[];
    getReferrals: () => void;
    isLoading: boolean;
}

const ReferralsList = ({
    referrals,
    getReferrals,
    isLoading,
    className,
    id,
}: Props) => {
    const observer = useRef<IntersectionObserver>();
    const lastElementRef = useCallback(
        (node: Element) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries: IntersectionObserverEntry[]) => {
                    if (entries[0].isIntersecting) {
                        getReferrals();
                    }
                }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, getReferrals]
    ) as Ref<HTMLDivElement>;

    const renderedReferralsList = referrals.map(
        (referral: Referral, index: number) => {
            return (
                <ReferralsListItem
                    id={`referral-${referral.uuid}`}
                    key={referral.uuid}
                    referral={referral}
                    ref={
                        referrals.length === index + 1
                            ? lastElementRef
                            : undefined
                    }
                />
            );
        }
    );

    return (
        <div id={id} className={`${className} d-flex flex-column gap-2`}>
            {renderedReferralsList}
        </div>
    );
};

export default ReferralsList;
