import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import PetitionGroup, { PetitionGroupStatus } from "../../models/PetitionGroup";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        title?: string[];
        description?: string[];
        status?: string[];
        rejection_reason?: string[];
        non_field_errors?: string[];
        error?: string | string[];
    };
};

export const updatePetitionGroup = async (
    petition_group_uuid: string,
    title?: string,
    description?: string,
    status?: PetitionGroupStatus,
    rejection_reason?: string
): Promise<PetitionGroup> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.patch(
            `/petitions/groups/${petition_group_uuid}`,
            {
                title,
                description,
                status,
                rejection_reason,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new PetitionGroup(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
