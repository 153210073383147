import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import Petition from "../../../../models/Petition";
import React, {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { listPetitionEndResultRaters as api_listPetitionEndResultRaters } from "../../../../api/petitions/listPetitionEndResultRaters";
import { listPetitionGroupEndResultRaters as api_listPetitionGroupEndResultRaters } from "../../../../api/petitionGroups/listPetitionGroupEndResultRaters";
import Spinner from "../../../UI/Spinner/Spinner";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import PetitionEndResultRater from "../../../../models/PetitionEndResultRater";
import PetitionEndResultRatersList from "./PetitionEndResultRatersList/PetitionEndResultRatersList";
import { parseCursor } from "../../../../utils/helpers";
import { useTranslation } from "react-i18next";
import PetitionGroup from "../../../../models/PetitionGroup";

interface Props extends HTMLAttributes<any> {
    petitionOrPetitionGroup: Petition | PetitionGroup;
}

const PetitionEndResultRatersListOverlay = ({
    id,
    petitionOrPetitionGroup,
}: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(true);
    const [raters, setRaters] = useState<PetitionEndResultRater[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitionEndResultRaters = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response =
                    petitionOrPetitionGroup instanceof Petition
                        ? await api_listPetitionEndResultRaters(
                              petitionOrPetitionGroup.uuid,
                              cursor
                          )
                        : await api_listPetitionGroupEndResultRaters(
                              petitionOrPetitionGroup.uuid,
                              cursor
                          );
                if (cursor === undefined) setRaters(response.results);
                else
                    setRaters((prev: PetitionEndResultRater[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [petitionOrPetitionGroup.uuid]
    );

    useEffect(() => {
        setRaters([]);
        setCursor(undefined);
        setHasMore(true);
        getPetitionEndResultRaters(undefined, true);
    }, [getPetitionEndResultRaters]);

    return (
        <ModalOverlay id={id}>
            <h6 id="beb05f58-def0-4d62-b2f9-5e6b762a11de">
                {t("modal.overlays.petitionEndResultRatersList.raters")}
            </h6>
            <hr id="29f7cf20-dd43-4677-a763-41d10263b00d" />
            <div
                id="a214466a-10a7-4a66-8363-16f877bf99fe"
                style={{
                    maxHeight: "65vh",
                    overflow: "auto",
                    marginRight: "-1rem",
                    paddingRight: "1rem",
                }}
            >
                {!!raters && (
                    <PetitionEndResultRatersList
                        id="872c5eff-ee1c-4014-950c-31e07fcc4b56"
                        raters={raters}
                        getRaters={() =>
                            getPetitionEndResultRaters(cursor, hasMore)
                        }
                        isLoading={isLoading}
                    />
                )}
                {isLoading && (
                    <Spinner
                        id="fc32ff41-76c2-4b48-82c8-99a6a65e891f"
                        className="mt-3"
                    />
                )}
            </div>
            <hr id="3dec6004-6972-438b-b3ed-19f632b1f25e" />
            <div
                id="0fb41b4a-eb78-4521-912e-dfcb29d12357"
                className="d-flex justify-content-end"
            >
                <Button
                    id="dcdc22e8-42ff-417e-bd2f-ed33a872ef35"
                    className="btn btn-sm btn-dark"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default PetitionEndResultRatersListOverlay;
