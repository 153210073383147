import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import Petition from "../../../../models/Petition";
import React, {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { listPetitions as api_listPetitions } from "../../../../api/petitions/listPetitions";
import Spinner from "../../../UI/Spinner/Spinner";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import { parseCursor } from "../../../../utils/helpers";
import { useTranslation } from "react-i18next";
import PetitionGroup from "../../../../models/PetitionGroup";
import PetitionsList from "../../../PetitionsList/PetitionsList";

interface Props extends HTMLAttributes<any> {
    petitionGroup?: PetitionGroup;
    title?: string;
}

const PetitionsListOverlay = ({ id, petitionGroup, title }: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(true);
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);

    const getPetitions = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (!hasMore) return;
            setIsLoading(true);
            try {
                const response = await api_listPetitions(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    petitionGroup ? petitionGroup.uuid : undefined,
                    cursor
                );
                if (cursor === undefined) setPetitions(response.results);
                else
                    setPetitions((prev: Petition[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        []
    );

    useEffect(() => {
        setPetitions([]);
        setCursor(undefined);
        setHasMore(true);
        getPetitions(undefined, true);
    }, [getPetitions]);

    let localTitle = title || t("modal.overlays.petitionsList.petitions");

    return (
        <ModalOverlay id={id}>
            <h6 id="a0136148-3072-45cf-b8ce-8bb0d5badee9">{localTitle}</h6>
            <hr id="c7311733-261b-41df-95a0-bf0af11020c8" />
            <div
                id="dc196dd1-42e1-4644-94c1-0ed43b815c1f"
                style={{
                    maxHeight: "65vh",
                    overflow: "auto",
                    marginRight: "-1rem",
                    paddingRight: "1rem",
                }}
            >
                {!!petitions && (
                    <PetitionsList
                        id="f4a51cd0-2fb7-43ac-b6f7-c8a6ad9ac2d0"
                        petitions={petitions}
                        getPetitions={() => getPetitions(cursor, hasMore)}
                        isLoading={isLoading}
                    />
                )}
                {isLoading && (
                    <Spinner
                        id="a643d265-1c42-4f08-aae1-a1af2b1bc317"
                        className="mt-3"
                    />
                )}
            </div>
            <hr id="fe5d2dea-d80a-459c-8f64-f3e75b5ad951" />
            <div
                id="a1386565-13bb-4a51-8457-6550c6ca5387"
                className="d-flex justify-content-end"
            >
                <Button
                    id="b2c9dc6b-1695-418f-89ed-6031d98ed1b6"
                    className="btn btn-sm btn-dark"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default PetitionsListOverlay;
