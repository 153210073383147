import axios from "../axiosConfig";
import PetitionSupporter from "../../models/PetitionSupporter";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export type SuccessResponse = {
    results: PetitionSupporter[];
    next: string;
    previous: string;
};

export const listPetitionGroupSupporters = async (
    petition_group_uuid: string,
    cursor?: string
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(
            `/petitions/groups/${petition_group_uuid}/supporters`,
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
                params: {
                    cursor,
                },
            }
        );
        response.data.results = response.data.results.map(
            (item: PetitionSupporter) => new PetitionSupporter(item)
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
