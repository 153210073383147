import axios from "../axiosConfig";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import Petition from "../../models/Petition";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export const addPetitionToPetitionGroup = async (
    petition_group_uuid: string,
    petition_uuid: string
): Promise<Petition> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.post(
            `/petitions/groups/${petition_group_uuid}/petitions/${petition_uuid}`,
            {},
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new Petition(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
