import { Link, useMatch } from "react-router-dom";
import { ReferralProgramPageTab } from "../ReferralProgramPage";
import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<any> {}

const ReferralProgramPageNavbar = ({ id }: Props) => {
    const { t } = useTranslation();
    const match = useMatch("/referral-program/:tab");
    const activeTab = match?.params.tab;

    return (
        <ul id={id} className="nav nav-underline">
            <li id="a38aadd4-059a-4591-b5a6-755bec54b65f" className="nav-item">
                <Link
                    id="f5186e3c-99bd-409f-85d3-19d9ece25491"
                    className={`nav-link ${
                        (!activeTab ||
                            activeTab === ReferralProgramPageTab.PROGRAM) &&
                        "active"
                    }`}
                    to={ReferralProgramPageTab.PROGRAM}
                >
                    {t("referralProgramPage.referralProgramPageNavbar.program")}
                </Link>
            </li>
            <li id="fdaacd40-84ea-4831-994a-c0d938df8739" className="nav-item">
                <Link
                    id="eb9a9d50-ca76-49d4-8af3-6418af7cfa76"
                    className={`nav-link ${
                        activeTab === ReferralProgramPageTab.REFERRER &&
                        "active"
                    }`}
                    to={ReferralProgramPageTab.REFERRER}
                >
                    {t(
                        "referralProgramPage.referralProgramPageNavbar.referrer"
                    )}
                </Link>
            </li>
            <li id="ee60efff-393a-4de5-b96e-f18158ccc1ca" className="nav-item">
                <Link
                    id="c8b69f75-e024-4ac5-abbd-cf969d1e66a7"
                    className={`nav-link ${
                        activeTab === ReferralProgramPageTab.REFERRALS &&
                        "active"
                    }`}
                    to={ReferralProgramPageTab.REFERRALS}
                >
                    {t(
                        "referralProgramPage.referralProgramPageNavbar.referrals"
                    )}
                </Link>
            </li>
        </ul>
    );
};

export default ReferralProgramPageNavbar;
