import { NavLink } from "react-router-dom";
import { GoHome, GoSearch } from "react-icons/go";
import { BsBell, BsPerson, BsPlusSquare } from "react-icons/bs";
import classes from "./Navbar.module.scss";
import { HTMLAttributes, useContext, useEffect } from "react";
import authContext from "../../contexts/auth-context";
import NotificationContext from "../../contexts/notification-context";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import DraftPetitionContext from "../../contexts/draft-petition-context";

interface Props extends HTMLAttributes<any> {}

const Navbar = ({ id }: Props) => {
    const { t } = useTranslation();
    const { user } = useContext(authContext);
    const { newNotificationsCount, getNewNotificationsCount } =
        useContext(NotificationContext);
    const { draftPetition } = useContext(DraftPetitionContext);

    useEffect(() => {
        getNewNotificationsCount();
    }, []);

    return (
        <div id={id}>
            <div
                id="1c5dd5a2-714a-4254-825e-ad74775179a1"
                className={`${classes.navbar} p-3`}
            >
                <nav
                    id="80c7a25a-57c5-4199-aa5b-634c8cb51cd3"
                    className="nav flex-column gap-2 nav-underline"
                >
                    <NavLink
                        // id="navbar-to-home"
                        id="cf7913a0-4f12-43f3-bc51-af85233ad6e7"
                        to="/"
                        className="nav-link"
                    >
                        <GoHome
                            id="cf7913a0-4f12-43f3-bc51-af85233ad6e7-icon"
                            className="mb-1 me-2"
                        />{" "}
                        {t("navbar.home")}
                    </NavLink>
                    <NavLink
                        // id="navbar-to-search"
                        id="d256e6ed-35d2-40ec-8cd5-c346b8788cb5"
                        to="/search"
                        className="nav-link"
                    >
                        <GoSearch
                            id="d256e6ed-35d2-40ec-8cd5-c346b8788cb5-icon"
                            className="mb-1 me-2"
                        />{" "}
                        {t("navbar.search")}
                    </NavLink>
                    <NavLink
                        // id="navbar-to-start"
                        id="bab95686-3a87-4e2d-88fa-7cb86db94c19"
                        to="/start"
                        className="nav-link position-relative d-flex justify-content-between align-items-center"
                    >
                        <span id="bab95686-3a87-4e2d-88fa-7cb86db94c19-span">
                            <BsPlusSquare
                                id="bab95686-3a87-4e2d-88fa-7cb86db94c19-icon"
                                className="mb-1 me-2"
                            />{" "}
                            {t("navbar.startPetition")}
                        </span>
                        {draftPetition && (
                            <span
                                id="bab95686-3a87-4e2d-88fa-7cb86db94c19-draft-span"
                                className="badge rounded-pill bg-warning-subtle text-warning-emphasis"
                            >
                                <FaPen id="bab95686-3a87-4e2d-88fa-7cb86db94c19-draft-icon" />
                            </span>
                        )}
                    </NavLink>
                    <NavLink
                        // id="navbar-to-notifications"
                        id="2bb716cb-44f3-404b-92fc-50095b74f936"
                        to="/notifications"
                        className="nav-link position-relative d-flex justify-content-between align-items-center"
                    >
                        <span id="2bb716cb-44f3-404b-92fc-50095b74f936-span">
                            <BsBell
                                id="2bb716cb-44f3-404b-92fc-50095b74f936-icon"
                                className="mb-1 me-2"
                            />{" "}
                            {t("navbar.notifications")}
                        </span>
                        {newNotificationsCount > 0 && (
                            <span
                                id="2bb716cb-44f3-404b-92fc-50095b74f936-count-span"
                                className="badge rounded-pill bg-danger-subtle text-danger-emphasis"
                            >
                                {newNotificationsCount <= 9
                                    ? newNotificationsCount
                                    : "9+"}
                            </span>
                        )}
                    </NavLink>
                    <NavLink
                        // id={`navbar-to-users-${user?.username}`}
                        id="8c7955e0-0c45-45b9-9ebc-a6c9dcd18c70"
                        to={`/${user?.username}`}
                        className="nav-link"
                    >
                        <BsPerson
                            id="8c7955e0-0c45-45b9-9ebc-a6c9dcd18c70-icon"
                            className="mb-1 me-2"
                        />{" "}
                        {t("navbar.profile")}
                    </NavLink>
                </nav>
            </div>
            <div
                id="cfca49d9-9fdc-44bf-ba50-586c2727e863"
                className={`${classes["mobile-navbar"]} border-top`}
            >
                <nav
                    id="2a8082bf-bac2-4287-b718-982e21ddaa9e"
                    className="nav flex-row justify-content-sm-center justify-content-between nav-underline"
                >
                    <NavLink
                        // id="navbar-to-home"
                        id="cf7913a0-4f12-43f3-bc51-af85233ad6e7-mobile"
                        to="/"
                        className="nav-link"
                    >
                        <GoHome id="cf7913a0-4f12-43f3-bc51-af85233ad6e7-icon-mobile" />
                    </NavLink>
                    <NavLink
                        // id="navbar-to-search"
                        id="d256e6ed-35d2-40ec-8cd5-c346b8788cb5-mobile"
                        to="/search"
                        className="nav-link"
                    >
                        <GoSearch id="d256e6ed-35d2-40ec-8cd5-c346b8788cb5-icon-mobile" />
                    </NavLink>
                    <NavLink
                        // id="navbar-to-start"
                        id="bab95686-3a87-4e2d-88fa-7cb86db94c19-mobile"
                        to="/start"
                        className="nav-link position-relative"
                    >
                        <BsPlusSquare id="bab95686-3a87-4e2d-88fa-7cb86db94c19-icon-mobile" />
                        {draftPetition && (
                            <span
                                id="bab95686-3a87-4e2d-88fa-7cb86db94c19-draft-span-mobile"
                                className="position-absolute ms-1 p-1 bg-warning border border-light rounded-circle"
                            />
                        )}
                    </NavLink>
                    <NavLink
                        // id="navbar-to-notifications"
                        id="2bb716cb-44f3-404b-92fc-50095b74f936-mobile"
                        to="/notifications"
                        className="nav-link position-relative"
                    >
                        <BsBell id="2bb716cb-44f3-404b-92fc-50095b74f936-icon-mobile" />
                        {newNotificationsCount > 0 && (
                            <span
                                id="2bb716cb-44f3-404b-92fc-50095b74f936-span-mobile"
                                className="position-absolute p-1 bg-danger border border-light rounded-circle"
                            />
                        )}
                    </NavLink>
                    <NavLink
                        // id={`navbar-to-users-${user?.username}`}
                        id="8c7955e0-0c45-45b9-9ebc-a6c9dcd18c70-mobile"
                        to={`/${user?.username}`}
                        className="nav-link"
                    >
                        <BsPerson id="8c7955e0-0c45-45b9-9ebc-a6c9dcd18c70-icon-mobile" />
                    </NavLink>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;
