import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { getUser as api_getUser } from "../../api/users/getUser";
import User from "../../models/User";
import Spinner from "../../components/UI/Spinner/Spinner";
import blank_profile_image from "../../images/blank-profile-picture.svg";
import { MdOutlineVerified } from "react-icons/md";
import SignupForm from "../AuthPage/SignupForm/SignupForm";
import Footer from "../../components/Footer/Footer";
import classes from "./InviteeWelcomePage.module.scss";
import { Trans } from "react-i18next";
import { parseProfileImageSrc } from "../../utils/helpers";
import authContext from "../../contexts/auth-context";

const InviteeWelcomePage = () => {
    const navigate = useNavigate();
    const match = useMatch("/team/:username");
    const username: string = match?.params.username as string;

    const { isLoggedIn } = useContext(authContext);
    if (isLoggedIn) navigate(`/${username}?af=1`);

    localStorage.removeItem("referredByAndTime");
    localStorage.setItem("invitedBy", username);
    const [invitor, setInvitor] = useState<User | null>();
    const [isLoading, setLoading] = useState(true);

    const getUser = useCallback(async () => {
        setLoading(true);
        setInvitor(null);
        try {
            const response = await api_getUser(username!);
            if (!response.is_creator) navigate("/signup");
            setInvitor(response);
        } catch (error) {
            navigate("/signup");
        }
        setLoading(false);
    }, [username]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    let image_src: string = blank_profile_image;
    if (invitor && invitor.profile_image)
        image_src = parseProfileImageSrc(invitor.profile_image);

    return (
        <>
            <div
                id="11f57a20-2566-42e9-96e5-0f9f7549578f"
                className={classes.content}
            >
                {isLoading && (
                    <Spinner id="b53ee543-bfe2-4aaa-a535-8662cb4e1ecf" />
                )}
                {!isLoading && (
                    <div
                        id="5de1f320-70ef-4d80-8a26-c376c5b39fe2"
                        className="container"
                    >
                        <div
                            id="aa38e87c-0769-488d-a369-54f6d92ee98c"
                            className="row p-3 px-0"
                        >
                            <div
                                id="fc64e505-bcff-4d70-96dc-c8d9c0197af4"
                                className="col-12 col-md-6 p-3 d-flex flex-column justify-content-center align-items-center gap-3"
                            >
                                {invitor && (
                                    <>
                                        <div
                                            id="f4c56d07-1b38-45bd-b508-07d9255c9df9"
                                            className="d-flex align-items-center gap-3"
                                        >
                                            <div id="8fe2f68d-3933-41c3-a1f2-8752efd59e7f">
                                                <img
                                                    id="e7dc5027-7dab-4b9e-847b-7c1f4337a699"
                                                    src={image_src}
                                                    alt="profile"
                                                    width="100"
                                                    height="100"
                                                    className="rounded-circle border border-1"
                                                />
                                            </div>
                                            <div
                                                id="ad18f6da-4254-45a6-82ed-7a5497422fd9"
                                                className="d-flex gap-1 flex-column"
                                            >
                                                <div
                                                    id="026b52e8-59f9-47ac-8554-595f6573df83"
                                                    className="d-flex align-items-center gap-1"
                                                >
                                                    <div
                                                        id="06a4ff26-16d9-457e-8928-d11910e2a8f0"
                                                        className="d-flex flex-wrap gap-1 fs-5 fw-bold"
                                                    >
                                                        <span id="ae1f9765-014a-4465-bb74-ce9013261ea6">
                                                            {invitor.name}
                                                        </span>
                                                    </div>
                                                    {invitor.is_account_verified && (
                                                        <MdOutlineVerified
                                                            id="bc6217b7-5835-4c4a-b998-ccbee0461924"
                                                            className="text-primary"
                                                        />
                                                    )}
                                                </div>
                                                <div id="9e3ca2eb-bd1f-4a24-9145-faa3254b086d">
                                                    @{invitor.username}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="1fff983f-28b7-472e-ace6-d9ced98a2c88"
                                            className="d-flex flex-column align-items-center gap-1"
                                        >
                                            <span
                                                id="46c07399-2783-4858-9fc5-436ebec17b8e"
                                                className="text-center"
                                            >
                                                <Trans
                                                    id="882dff89-4b33-402c-add9-9deed365495f"
                                                    i18nKey="auth.thanksForAcceptingMyInvitation"
                                                    components={[
                                                        <span
                                                            id="c2f354c6-a3e5-405d-b371-c602f999b6ce"
                                                            className="text-primary"
                                                        />,
                                                    ]}
                                                />
                                            </span>
                                        </div>
                                    </>
                                )}
                                {!invitor && (
                                    <div
                                        id="656056fc-c154-441b-84fc-1b78758c6e62"
                                        className="d-flex flex-column align-items-center gap-1"
                                    >
                                        <span
                                            id="f3ee82c9-5b98-45e7-b627-9f464d7ddf00"
                                            className="text-center"
                                        >
                                            <Trans
                                                id="25c27df2-c2a4-47e2-b6b1-4d02e2133dde"
                                                i18nKey="auth.couldNotRecognizeTheInvitationLink"
                                                components={[
                                                    <span
                                                        id="7b06f23d-8e2d-4e22-bce7-d102bc0d1664"
                                                        className="text-primary"
                                                    />,
                                                ]}
                                            />
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div
                                id="fdc4726e-4083-4948-88bd-c6b1363d883f"
                                className="col-12 col-md-6 p-3"
                            >
                                <SignupForm id="83b1682d-4e27-40d4-839f-f0d1d7f1d1e2" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer id="fc610caa-4a78-4390-9728-3cc5a491e4d3" />
        </>
    );
};

export default InviteeWelcomePage;
