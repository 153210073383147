import StartPetitionForm from "../../components/StartPetitionForm/StartPetitionForm";
import PetitionsList from "../../components/PetitionsList/PetitionsList";
import { listPetitions as api_listPetitions } from "../../api/petitions/listPetitions";
import React, { useCallback, useEffect, useState } from "react";
import Petition, { PetitionStatus } from "../../models/Petition";
import User from "../../models/User";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { parseCursor } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

import { StripeElementLocale } from "@stripe/stripe-js/types/stripe-js/elements-group";
import { loadStripe } from "@stripe/stripe-js";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import { Elements } from "@stripe/react-stripe-js";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK!, {
    locale: (localStorage.getItem(LOCALE) ||
        FALLBACK_LOCALE) as StripeElementLocale,
});

const StartPetitionPage = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [petitions, setPetitions] = useState<Petition[]>([]);
    const [cursor, setCursor] = useState<string | undefined>(undefined);
    const [hasMore, setHasMore] = useState(true);
    const [addressees, setAddressees] = useState<User[]>([]);
    const navigate = useNavigate();

    const getPetitions = useCallback(
        async (cursor: string | undefined, hasMore: boolean) => {
            if (addressees.length === 0 || !hasMore) return;
            setIsLoading(true);
            try {
                const addressee_uuids: string[] = [];
                for (let addressee of addressees)
                    addressee_uuids.push(addressee.uuid);

                const response = await api_listPetitions(
                    undefined,
                    addressee_uuids,
                    undefined,
                    [PetitionStatus.ONGOING],
                    undefined,
                    cursor
                );

                if (cursor === undefined) setPetitions(response.results);
                else
                    setPetitions((prev: Petition[]) => [
                        ...prev,
                        ...response.results,
                    ]);
                if (response.next) {
                    setCursor(parseCursor(response.next));
                    setHasMore(true);
                } else {
                    setCursor(undefined);
                    setHasMore(false);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        },
        [addressees]
    );

    useEffect(() => {
        setPetitions([]);
        setCursor(undefined);
        setHasMore(true);
        getPetitions(undefined, true);
    }, [getPetitions]);

    const createPetitionHandler = (petition: Petition) => {
        localStorage.setItem("firstTimeVisit", petition.uuid);
        navigate(`/petitions/${petition.uuid}`);
    };

    return (
        <div id="6ad9a0fe-b9ae-4045-8451-f9ecfd5425b2">
            <h6 id="38bca8fc-9f75-4c48-b0df-8afa073ed939" className="mb-3">
                {t("navbar.startPetition")}
            </h6>
            <hr id="bcf13aa4-db5e-4e7d-af71-6dd90795ea23" />
            <Elements stripe={stripe}>
                <StartPetitionForm
                    id="05134cd0-cdd9-452c-b0cf-0658bc2e8f08"
                    onCreatePetition={createPetitionHandler}
                    onSelectAddressee={setAddressees}
                />
            </Elements>
            {petitions.length > 0 && (
                <>
                    <hr id="d2e8d4d6-411e-49a3-b6b7-c8c40ce4ab97" />
                    <PetitionsList
                        id="43115477-7fa5-4ba6-86d4-45d564f48f06"
                        petitions={petitions}
                        getPetitions={() => getPetitions(cursor, hasMore)}
                        isLoading={isLoading}
                    />
                </>
            )}
            {isLoading && (
                <Spinner
                    id="b7458dba-a19e-4702-8460-a2c4503430d1"
                    className="mt-3"
                />
            )}
        </div>
    );
};

export default StartPetitionPage;
