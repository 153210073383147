import axios from "../axiosConfig";
import Petition from "../../models/Petition";
import { PetitionAddresseeStatus } from "../../models/PetitionAddressee";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        status?: string[];
        rejection_reason?: string[];
        non_field_errors?: string[];
        error?: string | string[];
    };
};

export const updatePetitionAddressee = async (
    petition_uuid: string,
    addressee_uuid: string,
    status: PetitionAddresseeStatus,
    rejection_reason?: string
): Promise<Petition> => {
    const token = localStorage.getItem("token");
    try {
        const response = await axios.put(
            `/petitions/${petition_uuid}/addressees/${addressee_uuid}`,
            {
                status,
                rejection_reason,
            },
            {
                headers: {
                    Authorization: token ? `Token ${token}` : "",
                    "Accept-Language":
                        localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
                },
            }
        );
        return new Petition(response.data);
    } catch (error: any) {
        throw error.response.data;
    }
};
