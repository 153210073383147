import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useState,
} from "react";
import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import Button from "../../../UI/Button/Button";
import ModalContext from "../../../../contexts/modal-context";
import { useTranslation } from "react-i18next";
import Textarea from "../../../UI/Textarea/Textarea";
import {
    ApiErrorCode,
    MAX_PETITION_DESCRIPTION_LENGTH,
    MAX_PETITION_TITLE_LENGTH,
} from "../../../../constants";
import {
    createPetitionGroup as api_createPetitionGroup,
    FailureResponse,
} from "../../../../api/petitionGroups/createPetitionGroup";
import PetitionGroup from "../../../../models/PetitionGroup";
import EmailNotVerifiedErrorOverlay from "../EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AlertOverlay from "../AlertOverlay/AlertOverlay";

interface Props extends HTMLAttributes<any> {
    initialPetitionUUID: string;
    onSelectExistingPetitionGroupButtonClick: () => void;
    onCreatePetitionGroup: (petitionGroup: PetitionGroup) => void;
}

const CreatePetitionGroupOverlay = ({
    id,
    initialPetitionUUID,
    onSelectExistingPetitionGroupButtonClick:
        selectExistingPetitionGroupClickHandler,
    onCreatePetitionGroup,
}: Props) => {
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);

    const [title, setTitle] = useState<string>("");
    const [titleTextareaErrorMsg, setTitleTextareaErrorMsg] = useState("");
    const [description, setDescription] = useState<string>("");
    const [descriptionTextareaErrorMsg, setDescriptionTextareaErrorMsg] =
        useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const titleChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.currentTarget.value);
        setTitleTextareaErrorMsg("");
    };

    const descriptionChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescription(event.currentTarget.value);
        setDescriptionTextareaErrorMsg("");
    };

    const isTitleValid = () => {
        if (!title) {
            setTitleTextareaErrorMsg(t("startPetitionPage.pleaseEnterTitle"));
            return false;
        }
        return true;
    };

    const isDescriptionValid = () => {
        if (!description) {
            setDescriptionTextareaErrorMsg(
                t("startPetitionPage.pleaseEnterDescription")
            );
            return false;
        }
        return true;
    };

    const submitHandler = async () => {
        if (isTitleValid() && isDescriptionValid()) {
            setIsLoading(true);
            try {
                const response = await api_createPetitionGroup(
                    title,
                    description,
                    initialPetitionUUID
                );
                onCreatePetitionGroup(response);
                closeModal();
            } catch (error) {
                const { errors, status_code, code } = error as FailureResponse;

                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    id="e3f28787-3604-4a5b-b817-92a05e2eeded"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    id="d65fc017-0727-4fb7-bc29-413663b11a85"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else {
                        if (errors.title)
                            setTitleTextareaErrorMsg(errors.title[0]);
                        if (errors.description)
                            setDescriptionTextareaErrorMsg(
                                errors.description[0]
                            );
                        if (errors.non_field_errors)
                            setErrorMsg(errors.non_field_errors[0]);
                        if (errors.error) console.log(errors.error);
                    }
                }
            }
            setIsLoading(false);
        }
    };

    return (
        <ModalOverlay id={id}>
            <h6 id="b5bc10f5-c266-4d6e-8a36-d3f62e34abd5">
                {t("modal.overlays.createPetitionGroup.createPetitionGroup")}
            </h6>
            <hr id="fa6eed1f-0464-4f7d-97af-82890d3b1613" />
            <div
                id="f463bd05-e82b-4218-8d6e-d6ea386e9e37"
                className="d-flex flex-column gap-3"
            >
                <div id="e18990c5-ca3a-4f76-93a5-e08b2bab50bd">
                    <Textarea
                        id="cf5517ee-6a72-4ea6-8a1f-a920b97313ba"
                        className="form-control-sm"
                        rows={2}
                        errorMsg={titleTextareaErrorMsg}
                        placeholder={t("petitionGroupForm.title")}
                        onChange={titleChangeHandler}
                        value={title}
                        maxLength={MAX_PETITION_TITLE_LENGTH}
                    />
                    <div
                        id="c8221f31-bee6-4b37-bf95-a30db099f438"
                        className="form-text d-flex justify-content-end"
                    >
                        {MAX_PETITION_TITLE_LENGTH - title.length}
                    </div>
                </div>
                <div id="a08f5468-1ed1-42a5-a09d-ce8a647e83ac">
                    <Textarea
                        id="b9e521ad-f99a-4a6f-824e-9467ad3b1a8c"
                        className="form-control-sm"
                        rows={10}
                        errorMsg={descriptionTextareaErrorMsg}
                        placeholder={t("petitionGroupForm.description")}
                        onChange={descriptionChangeHandler}
                        value={description}
                        maxLength={MAX_PETITION_DESCRIPTION_LENGTH}
                    />
                    <div
                        id="f40a6c84-08f8-43ca-831b-a51cc81b1f2e"
                        className="form-text d-flex justify-content-end"
                    >
                        {MAX_PETITION_DESCRIPTION_LENGTH - description.length}
                    </div>
                </div>
            </div>
            {errorMsg && (
                <div
                    id="b89c6835-88d2-4bce-a018-6ec8368aab3f"
                    className="form-text text-danger mt-0"
                >
                    {errorMsg}
                </div>
            )}
            <hr id="e9bcb9b7-5571-4078-9063-7bc39ff2bf12" />
            <div
                id="a3bd4cd5-78f5-4a38-9eee-2b31a29a173f"
                className="d-flex justify-content-end gap-1"
            >
                <Button
                    id="eeaf5c70-49e1-4ec1-b172-fac477da4c2a"
                    className="btn btn-sm btn-dark"
                    onClick={closeModal}
                >
                    {t("modal.close")}
                </Button>
                <Button
                    id="a7d3999e-2415-473b-9be4-6e841bd7ef29"
                    className="btn btn-sm btn-warning"
                    onClick={selectExistingPetitionGroupClickHandler}
                >
                    {t("modal.overlays.createPetitionGroup.selectExisting")}
                </Button>
                <Button
                    id="dd8f00f7-2244-4b75-8113-bbcdc94bdc1b"
                    className="btn btn-sm btn-primary"
                    isLoading={isLoading}
                    onClick={submitHandler}
                >
                    {t("modal.overlays.createPetitionGroup.create")}
                </Button>
            </div>
        </ModalOverlay>
    );
};

export default CreatePetitionGroupOverlay;
