import React, {
    ForwardedRef,
    HTMLAttributes,
    useContext,
    useState,
} from "react";
import PetitionGroup, {
    PetitionGroupStatus,
} from "../../../models/PetitionGroup";
import Button from "../../UI/Button/Button";
import { useTranslation } from "react-i18next";
import { TbHeartHandshake } from "react-icons/tb";
import AlertOverlay from "../../Modal/overlays/AlertOverlay/AlertOverlay";
import { getRemainingTimeFormatted } from "../../../utils/helpers";
import RejectPetitionOverlay from "../../Modal/overlays/RejectPetitionOverlay/RejectPetitionOverlay";
import { BsQuestionCircle } from "react-icons/bs";
import Countdown from "../../UI/Countdown/Countdown";
import PetitionGroupActionButtons from "./PetitionGroupActionButtons/PetitionGroupActionButtons";
import ModalContext from "../../../contexts/modal-context";
import {
    FailureResponse as UpdatePetitionGroupFailureResponse,
    updatePetitionGroup as api_updatePetitionGroup,
} from "../../../api/petitionGroups/updatePetitionGroup";
import { Link, useMatch } from "react-router-dom";
import { SlPencil } from "react-icons/sl";
import { FaRegEye } from "react-icons/fa";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import PetitionsListOverlay from "../../Modal/overlays/PetitionsListOverlay/PetitionsListOverlay";
import AddPetitionEndResultOverlay from "../../Modal/overlays/AddPetitionEndResultOverlay/AddPetitionEndResultOverlay";
import Petition from "../../../models/Petition";
import { ApiErrorCode } from "../../../constants";
import EmailNotVerifiedErrorOverlay from "../../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { MdStarBorder } from "react-icons/md";
import PetitionSupportersListOverlay from "../../Modal/overlays/PetitionSupportersListOverlay/PetitionSupportersListOverlay";
import PetitionEndResultRatersListOverlay from "../../Modal/overlays/PetitionEndResultRatersListOverlay/PetitionEndResultRatersListOverlay";

interface Props extends HTMLAttributes<any> {
    petitionGroup: PetitionGroup;
    onEditPetitionGroup?: () => void;
    isSelected?: boolean;
    onSelectPetitionGroup?: () => void;
    onAddPetitionToGroup?: (petition_uuid: string) => void;
    onPetitionGroupUpdate?: () => void;
}

const PetitionGroupsListItem = React.forwardRef(
    (
        {
            id,
            petitionGroup: initialPetitionGroup,
            onEditPetitionGroup,
            onSelectPetitionGroup,
            isSelected = false,
            onPetitionGroupUpdate,
        }: Props,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const match = useMatch("/petitions/groups/:uuid");
        const petition_group_uuid = match?.params.uuid!;
        const isPetitionGroupPage =
            petition_group_uuid === initialPetitionGroup.uuid;
        const { t } = useTranslation();
        const { showModal, closeModal } = useContext(ModalContext);
        const [petitionGroup, setPetitionGroup] =
            useState<PetitionGroup>(initialPetitionGroup);
        const [acceptLoading, setAcceptLoading] = useState(false);
        const [rejectLoading, setRejectLoading] = useState(false);
        const [markAsFulfilledLoading, setMarkAsFulfilledLoading] =
            useState(false);

        const checkboxChangeHandler = () => {
            onSelectPetitionGroup!();
        };

        const showPetitionsHandler = () => {
            showModal(
                <PetitionsListOverlay
                    id="b26b2a32-c9d6-4c54-a7b0-dee61088f474"
                    petitionGroup={petitionGroup}
                />
            );
        };

        const showSupportersHandler = () => {
            showModal(
                <PetitionSupportersListOverlay
                    id="ac00399f-f3ab-4f2d-995b-ad9f472b7841"
                    petitionOrPetitionGroup={petitionGroup}
                />
            );
        };

        const showPetitionEndResultRatersHandler = () => {
            showModal(
                <PetitionEndResultRatersListOverlay
                    id="b1746065-35eb-4dd6-aabd-1c8d5ec1a26a"
                    petitionOrPetitionGroup={petitionGroup}
                />
            );
        };

        const updatePetitionGroupStatus = async (
            status: PetitionGroupStatus,
            rejection_reason?: string
        ) => {
            if (status === PetitionGroupStatus.ACCEPTED) setAcceptLoading(true);
            else if (status === PetitionGroupStatus.REJECTED)
                setRejectLoading(true);
            else setMarkAsFulfilledLoading(true);

            try {
                const response = await api_updatePetitionGroup(
                    petitionGroup.uuid,
                    undefined,
                    undefined,
                    status,
                    rejection_reason
                );
                setPetitionGroup(response);
                if (onPetitionGroupUpdate) onPetitionGroupUpdate();
            } catch (error) {
                const { errors, status_code, code } =
                    error as UpdatePetitionGroupFailureResponse;
                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    id="b44e305b-cae4-4448-9295-28c622e0786c"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else if (code === ApiErrorCode.NO_PAYOUT_METHOD) {
                            showModal(
                                <AlertOverlay
                                    id="ada29c9b-2638-43ed-8507-507fc848b264"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                    link={
                                        <Link
                                            id="a44babba-b437-4f3f-a0d6-f029a660ba15"
                                            to="/settings/payout"
                                            className="btn btn-sm btn-primary"
                                        >
                                            {t(
                                                "settingsPage.payoutSettings.payoutMethodSettings.addPayoutMethod"
                                            )}
                                        </Link>
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    id="f31408ab-438e-474c-920a-ce6faa2396c3"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else {
                        if (errors.status)
                            showModal(
                                <AlertOverlay
                                    id="c5fa8370-b309-4ea2-afa6-28df38119467"
                                    status="error"
                                    message={errors.status[0]}
                                />
                            );
                        else if (errors.rejection_reason)
                            showModal(
                                <AlertOverlay
                                    id="b2513532-34bc-4ebb-8d56-403836ddd069"
                                    status="error"
                                    message={errors.rejection_reason[0]}
                                />
                            );
                        else if (errors.non_field_errors)
                            showModal(
                                <AlertOverlay
                                    id="a865e8da-f9ef-4531-b5d4-b70cd15985b2"
                                    status="error"
                                    message={errors.non_field_errors[0]}
                                />
                            );
                        else if (errors.error)
                            showModal(
                                <AlertOverlay
                                    id="e77fdc76-44c1-4ba3-ab52-60b299485538"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    }
                }
            }

            setAcceptLoading(false);
            setRejectLoading(false);
            setMarkAsFulfilledLoading(false);
        };

        const statusName: string = t(
            `petitionGroup.statuses.${petitionGroup.status}`
        );
        let statusClass = "";
        switch (petitionGroup.status) {
            case PetitionGroupStatus.PENDING:
                statusClass = "light";
                break;
            case PetitionGroupStatus.ONGOING:
                statusClass = "warning";
                break;
            case PetitionGroupStatus.CANCELED:
                statusClass = "light";
                break;
            case PetitionGroupStatus.REJECTED:
                statusClass = "danger";
                break;
            case PetitionGroupStatus.EXPIRED:
                statusClass = "secondary";
                break;
            case PetitionGroupStatus.ACCEPTED:
                statusClass = "info";
                break;
            case PetitionGroupStatus.NOT_FULFILLED:
                statusClass = "dark";
                break;
            case PetitionGroupStatus.FULFILLED:
                statusClass = "success";
                break;
        }

        let usd_pledged_amount = 0;
        for (let item of petitionGroup.total_pledged_amount_by_currency) {
            if (item.currency.toUpperCase() === "USD")
                usd_pledged_amount = item.total_amount;
        }

        return (
            <div id={id} key={petitionGroup.uuid} ref={ref}>
                <div
                    id="d65514e3-5db4-48ec-80ef-5a14d3d3c89c"
                    className={`d-flex flex-fill justify-content-between align-items-center form-text mt-0 rounded-top border-start border-end border-top p-1 px-3 bg-body-secondary ${
                        isSelected ? "border-primary-subtle" : ""
                    }`}
                >
                    <span id="b51422fb-4f00-424d-95c4-e229cc718231">
                        {t("petitionGroup.group")}
                    </span>
                    <div
                        id="c849920a-a7b0-4938-99db-67f286ecd7a8"
                        className="d-flex gap-2"
                    >
                        {!onSelectPetitionGroup && (
                            <>
                                {onEditPetitionGroup && (
                                    <Button
                                        id="d06fac16-82a7-4a5a-a36e-025670675734"
                                        className="btn btn-sm btn-dark"
                                        onClick={() => onEditPetitionGroup()}
                                        title={t("petitionGroup.edit")}
                                    >
                                        <SlPencil id="d06fac16-82a7-4a5a-a36e-025670675734-icon" />
                                    </Button>
                                )}
                                {!isPetitionGroupPage && (
                                    <Link
                                        id="e0253d11-a95f-4c14-954d-4cdabd2f452a"
                                        className="btn btn-sm btn-dark"
                                        to={`/petitions/groups/${petitionGroup.uuid}`}
                                        title={t(
                                            "petitionGroup.viewPetitionGroup"
                                        )}
                                    >
                                        <FaRegEye id="e0253d11-a95f-4c14-954d-4cdabd2f452a-icon" />
                                    </Link>
                                )}
                            </>
                        )}
                        {onSelectPetitionGroup && (
                            <div
                                id="d8042f31-d3fe-4d2e-9ed5-6e35a73d23b3"
                                className="d-flex align-items-center gap-2"
                                style={{ height: "1.9rem" }}
                            >
                                <label
                                    id="e9b576e8-96e6-48b4-bc1d-0d984482e5ac"
                                    className="form-check-label p-0"
                                >
                                    {t("petitionGroup.select")}
                                </label>
                                <input
                                    id="c5a37eb2-806b-4365-9deb-ff8b3afd001d"
                                    className="form-check-input m-0"
                                    type="radio"
                                    value={petitionGroup.uuid}
                                    onChange={checkboxChangeHandler}
                                    checked={isSelected}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    id="a00b56c4-0063-4b8c-a366-48d957c8062e"
                    className={`d-flex flex-column border-start border-end gap-3 p-3 ${
                        isSelected ? "border-primary-subtle" : ""
                    }`}
                >
                    <div
                        id="f2bbb712-52e4-43a6-b00a-f8d303c93e82"
                        className="d-flex flex-column border-bottom gap-2 pb-3"
                    >
                        <h6
                            id="d50a2796-efa5-40ea-8fce-ca370835466a"
                            className="card-title"
                        >
                            {petitionGroup.title}
                        </h6>
                        <p
                            id="d5115e84-5d98-4ad4-a82c-0e2d112a0b32"
                            className="card-text form-text"
                            style={{ whiteSpace: "pre-line" }}
                        >
                            {petitionGroup.description}
                        </p>
                    </div>
                    <div
                        id="f9cf8ae6-1ea8-4a6e-a84a-b96a799b58a6"
                        className="d-flex align-items-center justify-content-end"
                    >
                        <div
                            id="a150dbaf-f7bc-479e-a05a-b084ae196550"
                            className="d-flex gap-1 align-items-center"
                        >
                            {petitionGroup.average_rate && (
                                <Button
                                    id="c426f2f9-f2a4-4ae6-91d4-32582d2c4ca9"
                                    className="btn btn-sm btn-link text-decoration-none text-dark d-flex gap-1 align-items-center"
                                    onClick={showPetitionEndResultRatersHandler}
                                    title={t("petition.buttons.raters")}
                                >
                                    <MdStarBorder id="c426f2f9-f2a4-4ae6-91d4-32582d2c4ca9-icon" />
                                    {petitionGroup.average_rate}
                                </Button>
                            )}
                            <Button
                                id="c25ca71f-5e89-49e6-ab38-abd51a88c233"
                                className="btn btn-sm btn-link text-decoration-none text-dark d-flex gap-1 align-items-center"
                                title={t("petitionGroup.petitions")}
                                onClick={showPetitionsHandler}
                            >
                                <HiOutlineSquare3Stack3D id="c25ca71f-5e89-49e6-ab38-abd51a88c233-icon" />
                                {petitionGroup.petitions_count}
                            </Button>
                            <Button
                                id="d6fc1353-ad82-4ca0-8075-1b6b015fdb5a"
                                className="btn btn-sm btn-link text-decoration-none text-dark d-flex gap-1 align-items-center"
                                onClick={showSupportersHandler}
                                title={t("petition.buttons.supporters")}
                            >
                                <TbHeartHandshake id="d6fc1353-ad82-4ca0-8075-1b6b015fdb5a-icon" />
                                {petitionGroup.total_supporters_count}
                            </Button>
                            <span
                                id="b21e9239-5761-4946-80ee-ef7f20d90910"
                                className="form-text text-dark m-0"
                            >
                                USD {(usd_pledged_amount / 100).toFixed(2)}
                            </span>
                        </div>
                    </div>
                    <div
                        id="e21c378d-515f-4269-833c-b0906b899b15"
                        className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3"
                    >
                        <PetitionGroupActionButtons
                            id="ae947284-4ad9-4592-9622-82dc68fec3d4"
                            petitionGroup={petitionGroup}
                            onAcceptButtonClick={() =>
                                showModal(
                                    <AlertOverlay
                                        id="eb89185e-3832-4399-9f3c-d9ce2c7b5efd"
                                        status="warning"
                                        message={t(
                                            "petitionGroup.sureWantToAccept",
                                            {
                                                remaining_time:
                                                    getRemainingTimeFormatted(
                                                        new Date(
                                                            petitionGroup.dt_next_status_update
                                                        )
                                                    ),
                                            }
                                        )}
                                        onConfirm={() =>
                                            updatePetitionGroupStatus(
                                                PetitionGroupStatus.ACCEPTED
                                            )
                                        }
                                    />
                                )
                            }
                            onRejectButtonClick={() =>
                                showModal(
                                    <RejectPetitionOverlay
                                        id="a373f903-3e38-44e7-b1eb-dd355f3ef70c"
                                        rejectedItemType="petition_group"
                                        onConfirm={(
                                            rejection_reason: string | undefined
                                        ) =>
                                            updatePetitionGroupStatus(
                                                PetitionGroupStatus.REJECTED,
                                                rejection_reason
                                            )
                                        }
                                    />
                                )
                            }
                            onMarkAsFulfilledButtonClick={() =>
                                showModal(
                                    <AddPetitionEndResultOverlay
                                        id="cbbeaaec-9146-4200-98ff-e637bc428d8d"
                                        petitionOrPetitionGroup={petitionGroup}
                                        onPetitionOrPetitionGroupUpdate={(
                                            petitionOrPetitionGroup:
                                                | Petition
                                                | PetitionGroup
                                        ) => {
                                            setPetitionGroup(
                                                petitionOrPetitionGroup as PetitionGroup
                                            );
                                            if (onPetitionGroupUpdate)
                                                onPetitionGroupUpdate();
                                        }}
                                    />
                                )
                            }
                            acceptLoading={acceptLoading}
                            rejectLoading={rejectLoading}
                            markAsFulfilledLoading={markAsFulfilledLoading}
                        />
                    </div>
                </div>
                <div
                    id="f228975c-a548-480e-8ec7-390b987fea60"
                    className={`rounded-bottom bg-${statusClass}-subtle form-text mt-0 py-1 border-start border-end border-bottom ${
                        isSelected ? "border-primary-subtle" : ""
                    }`}
                >
                    <div
                        id="e257cf55-8b2a-4ac0-a497-800cf2d8daa0"
                        className={`d-flex justify-content-center gap-2 text-${statusClass}-emphasis `}
                    >
                        {statusName.toUpperCase()}
                    </div>
                    {petitionGroup.dt_next_status_update && (
                        <div
                            id="ddb85548-83b4-4767-9224-54b452abbabd"
                            className={`d-flex justify-content-center form-text mt-0 text-${statusClass}-emphasis `}
                        >
                            <Countdown
                                id="c4d40daa-45f2-4b51-8aa2-0c9bfb893768"
                                className="d-flex justify-content-center"
                                style={{ width: "130px" }}
                                deadline={
                                    new Date(
                                        petitionGroup.dt_next_status_update
                                    )
                                }
                            />
                            <div
                                id="f12ce601-8905-4f48-b1d8-85de5840f054"
                                className="dropdown-center"
                            >
                                <Button
                                    id="aaec9fa4-b824-4110-be97-a36a7b5205f7"
                                    className="btn nav-link"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <BsQuestionCircle
                                        id="aaec9fa4-b824-4110-be97-a36a7b5205f7-icon"
                                        style={{ marginBottom: "1px" }}
                                    />
                                </Button>
                                <ul
                                    id="fabe8130-e9ef-42d0-bf39-3b9d3a6be141"
                                    className="dropdown-menu"
                                >
                                    <span
                                        id="edd728ec-2570-42c6-99c6-d81f79827a6b"
                                        className="form-text dropdown-item-text"
                                    >
                                        {petitionGroup.status ===
                                        PetitionGroupStatus.ONGOING
                                            ? t(
                                                  "petitionGroup.remainingTimeToAcceptAndFulfill"
                                              )
                                            : t(
                                                  "petitionGroup.remainingTimeToFulfill"
                                              )}
                                    </span>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default PetitionGroupsListItem;
