import React, { ForwardedRef, HTMLAttributes } from "react";
import PetitionEndResultRater from "../../../../../../models/PetitionEndResultRater";
import UserBasicInfo from "../../../../../UserBasicInfo/UserBasicInfo";
import { Link } from "react-router-dom";
import classes from "./PetitionEndResultRatersListItem.module.scss";
import { MdStarBorder } from "react-icons/md";

interface Props extends HTMLAttributes<any> {
    rater: PetitionEndResultRater;
}

const PetitionEndResultRatersListItem = React.forwardRef(
    ({ id, rater }: Props, ref: ForwardedRef<HTMLAnchorElement>) => {
        return (
            <Link
                // id={`petition-end-result-raters-list-item-to-users-${rater.user.username}`}
                id={id}
                key={rater.user.uuid}
                to={`/${rater.user.username}`}
                className={`${classes.user} d-block text-decoration-none text-dark rounded`}
                ref={ref}
            >
                <div
                    id="9423981f-86bc-4787-9bfa-614bc3b452c4"
                    className="d-flex flex-wrap align-items-center justify-content-between gap-4 px-2 py-3 border rounded"
                >
                    <UserBasicInfo
                        id="401c685f-b6ba-4793-97a7-744f456ec6ee"
                        user={rater.user}
                    />
                    {!!rater.comment && (
                        <span
                            id="53c706b5-ff54-4799-b0af-27b693dfc6cb"
                            className="form-text"
                        >
                            {rater.comment}
                        </span>
                    )}
                    <div
                        id="c89eed82-a0b1-47f2-9767-0b878d589a58"
                        className="flex-fill"
                    >
                        <span
                            id="d31c6ce3-1bea-4944-b385-6e318205a7ff"
                            className="btn btn-warning disabled float-end"
                        >
                            <MdStarBorder id="bf0e6cda-db20-40a9-bc13-4eda1407be33" />{" "}
                            {rater.rate}
                        </span>
                    </div>
                </div>
            </Link>
        );
    }
);

export default PetitionEndResultRatersListItem;
