import React, {
    ChangeEvent,
    HTMLAttributes,
    useContext,
    useState,
} from "react";
import PetitionGroup, { PetitionGroupStatus } from "../../models/PetitionGroup";
import Button from "../UI/Button/Button";
import Textarea from "../UI/Textarea/Textarea";
import {
    ApiErrorCode,
    MAX_PETITION_DESCRIPTION_LENGTH,
    MAX_PETITION_TITLE_LENGTH,
} from "../../constants";
import { useTranslation } from "react-i18next";
import {
    FailureResponse as UpdatePetitionGroupFailureResponse,
    updatePetitionGroup as api_updatePetitionGroup,
} from "../../api/petitionGroups/updatePetitionGroup";
import AlertOverlay from "../Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../contexts/modal-context";
import EmailNotVerifiedErrorOverlay from "../Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";

interface Props extends HTMLAttributes<any> {
    petitionGroup: PetitionGroup;
    onUpdatePetitionGroup: (petitionGroup: PetitionGroup) => void;
    onCancelEdit?: () => void;
}

const PetitionGroupForm = ({
    petitionGroup,
    onUpdatePetitionGroup,
    onCancelEdit,
}: Props) => {
    const { t } = useTranslation();
    const { showModal } = useContext(ModalContext);
    const [title, setTitle] = useState<string>(
        petitionGroup ? petitionGroup.title : ""
    );
    const [titleTextareaErrorMsg, setTitleTextareaErrorMsg] = useState("");
    const [description, setDescription] = useState<string>(
        petitionGroup ? petitionGroup.description : ""
    );
    const [descriptionTextareaErrorMsg, setDescriptionTextareaErrorMsg] =
        useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const titleChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.currentTarget.value);
        setTitleTextareaErrorMsg("");
    };

    const descriptionChangeHandler = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescription(event.currentTarget.value);
        setDescriptionTextareaErrorMsg("");
    };

    const isTitleValid = () => {
        if (!title) {
            setTitleTextareaErrorMsg(t("startPetitionPage.pleaseEnterTitle"));
            return false;
        }
        return true;
    };

    const isDescriptionValid = () => {
        if (!description) {
            setDescriptionTextareaErrorMsg(
                t("startPetitionPage.pleaseEnterDescription")
            );
            return false;
        }
        return true;
    };

    const submitHandler = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrorMsg("");
        setTitleTextareaErrorMsg("");
        setDescriptionTextareaErrorMsg("");
        if (isTitleValid() && isDescriptionValid()) {
            setIsLoading(true);
            try {
                const response = await api_updatePetitionGroup(
                    petitionGroup.uuid,
                    title !== petitionGroup.title ? title : undefined,
                    description !== petitionGroup.description
                        ? description
                        : undefined,
                    undefined
                );
                onUpdatePetitionGroup(response);
            } catch (error) {
                const { errors, status_code, code } =
                    error as UpdatePetitionGroupFailureResponse;
                if (errors) {
                    if (status_code === 403) {
                        if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                            showModal(
                                <EmailNotVerifiedErrorOverlay
                                    id="ddcb578e-af24-4484-bc6a-8fabf59bed26"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                        } else
                            showModal(
                                <AlertOverlay
                                    id="e3e24c9a-0a6e-41c6-a1af-8bb3cdaa6c93"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    } else {
                        if (errors.title)
                            setTitleTextareaErrorMsg(errors.title[0]);
                        if (errors.description)
                            setDescriptionTextareaErrorMsg(
                                errors.description[0]
                            );
                        if (errors.non_field_errors)
                            setErrorMsg(errors.non_field_errors[0]);
                        if (errors.error)
                            showModal(
                                <AlertOverlay
                                    id="deb5d7db-7be2-48c8-b0fd-f2f8ea6ee1c8"
                                    status="error"
                                    message={
                                        errors.error instanceof Array
                                            ? errors.error[0]
                                            : errors.error!
                                    }
                                />
                            );
                    }
                }
            }
            setIsLoading(false);
        }
    };

    return (
        <form
            id="bb3bfc6a-a20e-4d75-87ae-d6dfa7cc083b"
            className="d-flex flex-column"
            onSubmit={submitHandler}
        >
            <div id="c8e4fbfe-3093-4fae-80b7-619e2b79f72b" className="mb-3">
                <Textarea
                    id="bc726144-f041-42e7-bb60-8f4acebf6a42"
                    className="form-control-sm"
                    rows={2}
                    errorMsg={titleTextareaErrorMsg}
                    placeholder={t("petitionGroupForm.title")}
                    onChange={titleChangeHandler}
                    value={title}
                    maxLength={MAX_PETITION_TITLE_LENGTH}
                />
                <div
                    id="c6c46aa9-9a12-455a-822c-caf98c01e80d"
                    className="form-text d-flex justify-content-end"
                >
                    {MAX_PETITION_TITLE_LENGTH - title.length}
                </div>
            </div>
            <div id="d6780172-2300-4b2f-b1a4-6c5f693887d4" className="mb-3">
                <Textarea
                    id="ce251ad0-bc3d-44d6-a5f9-730e619f67c3"
                    className="form-control-sm"
                    rows={10}
                    errorMsg={descriptionTextareaErrorMsg}
                    placeholder={t("petitionGroupForm.description")}
                    onChange={descriptionChangeHandler}
                    value={description}
                    maxLength={MAX_PETITION_DESCRIPTION_LENGTH}
                />
                <div
                    id="e37cfe98-1210-4127-8200-6985b29eb213"
                    className="form-text d-flex justify-content-end"
                >
                    {MAX_PETITION_DESCRIPTION_LENGTH - description.length}
                </div>
            </div>
            {errorMsg && (
                <div
                    id="c2c1714b-4b71-4723-a0f9-46643494e547"
                    className="form-text text-danger mt-0"
                >
                    {errorMsg}
                </div>
            )}
            <div
                id="cc8fd7bd-7f79-4041-b6d0-df9e1e087600"
                className="d-flex justify-content-end gap-2"
            >
                {petitionGroup && (
                    <Button
                        id="e0a26bd9-d2d9-40b1-a9cc-49dc1bcd4ffb"
                        className="btn btn-sm btn-dark"
                        onClick={onCancelEdit}
                    >
                        {t("petitionGroupForm.cancel")}
                    </Button>
                )}
                <Button
                    id="ef84dbdc-9994-4382-90ee-a53c0ba2cf7b"
                    className="btn btn-sm btn-primary"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                >
                    {t("petitionGroupForm.save")}
                </Button>
            </div>
        </form>
    );
};

export default PetitionGroupForm;
